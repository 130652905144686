import React, { useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./ForgotPasswordPage.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { forgotPassword } from "../../services/users.service";
import { useSnackbar } from "notistack";
import PreLoader from "../../components/common/PreLoader";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [Loading, setLoading] = useState(false);
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email address")
      .email("Enter a valid email address")
      .required("Email address is required"),
  });

  const handleForgotPassword = async (values) => {
    setLoading(true);
    const response = await forgotPassword(values.email);

    if (response.success) {
      setLoading(false);
      history.push({ pathname: "/confirm-forgot-password", state: values });
    } else {
      setLoading(false);
      let _message =
        "Unable to submit your request. Please contact an administrator.";
      if (response.type === "UserNotFoundException") {
        _message = `Invalid details provided.`;
      }
      enqueueSnackbar(_message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });

  return (
    <>
      <AppHeader isGuestMode={true} />
      <div className={styles.pageBox}>
        <div className={styles.pageTitleBox}>
          <h1>Forgot password</h1>
        </div>
        <div className={styles.pageBoxBody}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <label>Email Address</label>
              <TextField
                margin="normal"
                fullWidth
                id="email"
                name="email"
                placeholder="Email Address"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
                disabled={!formik.dirty}
              >
                Continue
              </Button>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Link href="/login" variant="body2">
                  Already have an account? Please log in.
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

export default ForgotPasswordPage;
