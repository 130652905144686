import React from "react";
import styles from "./ConsentPersonObtainingView.module.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import SignatureImageView from "../../../../components/SignatureImageView";

const ConsentPersonObtainingView = ({ personObtainingObj }) => {
  const [t] = useTranslation("common");
  return (
    <>
      <div className={styles.pageViewBox}>
        <h3 className={styles.intro}>{t("person_obtaining_consent_title")}</h3>
        <p>{t("person_obtaining_consent_p1")}</p>

        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("person_obtaining_consent_first_name")}</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.personObtainingFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("person_obtaining_consent_last_name")}</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.personObtainingLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("person_obtaining_consent_signature")}</label>
                <SignatureImageView
                  signature={personObtainingObj.personObtainingSignature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {personObtainingObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

ConsentPersonObtainingView.propTypes = {
  personObtainingObj: PropTypes.object,
};

export default ConsentPersonObtainingView;
