import React, { useEffect, useState } from "react";
import styles from "./ConsentMinorParticipantIntro.module.css";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import RenderHtmlText from "../../../../components/common/RenderHtmlText";
import PropTypes from "prop-types";
import DisplayIntroSection from "../../../../components/common/DisplayIntroSection";
import { useSelector } from "react-redux";

const ConsentMinorParticipantIntro = ({ printMode = false }) => {
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [t] = useTranslation("common");
  const { currentCountry } = useSelector((state) => state.settings);

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      setUserRole(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
      setUserFullName(`${firstName} ${lastName}`);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <h2 className={styles.intro}>{t("minor_consent_title")}</h2>
      <p>
        {t("minor_intro_p1")
          .replace("#userFullName#", userFullName)
          .replace("#userRole#", userRole)}
      </p>
      <p>{t("minor_intro_p2")}</p>
      <br />
      <h2 className={styles.intro}>{t("step_introduction")}</h2>
      <p>{t("minor_intro_p3")}</p>
      <p>{t("minor_intro_p4")}</p>
      <div>
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_is_the_purpose_of_this_study_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_what_is_the_purpose_of_this_study_p1")}
              <br />
              <br />
              {t("minor_what_is_the_purpose_of_this_study_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_how_many_people_in_the_study_title")}
            </h2>
          }
          contentElements={<>{t("minor_how_many_people_in_the_study_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_how_are_study_participants_selected_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_how_are_study_participants_selected_p1")}
              <ul>
                <li>{t("minor_how_are_study_participants_selected_b1")}</li>
                <li>{t("minor_how_are_study_participants_selected_b2")}</li>
                <li>{t("minor_how_are_study_participants_selected_b3")}</li>
                <li>{t("minor_how_are_study_participants_selected_b4")}</li>
                <li>{t("minor_how_are_study_participants_selected_b5")}</li>
                <li>{t("minor_how_are_study_participants_selected_b6")}</li>
                <li>{t("minor_how_are_study_participants_selected_b7")}</li>
              </ul>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_how_long_will_the_study_last_title")}
            </h2>
          }
          contentElements={<>{t("minor_how_long_will_the_study_last_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_are_the_study_procedures_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_what_are_the_study_procedures_p1")}
              <br />
              <br />
              {t("minor_what_are_the_study_procedures_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_fingerprint_scan_title")}
            </h2>
          }
          contentElements={
            <>
              <RenderHtmlText htmlText={`${t("minor_fingerprint_scan_p1")}`} />
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_study_screening_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_study_screening_p1")}
              <ul>
                <li>{t("minor_study_screening_p1_b1")}</li>
                <li>{t("minor_study_screening_p1_b2")}</li>
                <li>{t("minor_study_screening_p1_b3")}</li>
                <li>{t("minor_study_screening_p1_b4")}</li>
              </ul>
              {t("minor_study_screening_p2")}
              <ul>
                <li>{t("minor_study_screening_p2_b1")}</li>
                <li>{t("minor_study_screening_p2_b2")}</li>
                <li>{t("minor_study_screening_p2_b3")}</li>
              </ul>
              {t("minor_study_screening_p3")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_enrolment_visit_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_enrolment_visit_p1")}
              <ul>
                <li>{t("minor_enrolment_visit_p1_b1")}</li>
                <li>{t("minor_enrolment_visit_p1_b2")}</li>
                <li>{t("minor_enrolment_visit_p1_b3")}</li>
                <li>{t("minor_enrolment_visit_p1_b4")}</li>
                <li>{t("minor_enrolment_visit_p1_b5")}</li>
                <li>{t("minor_enrolment_visit_p1_b6")}</li>
                <li>{t("minor_enrolment_visit_p1_b7")}</li>
                <li>{t("minor_enrolment_visit_p1_b8")}</li>
                <li>{t("minor_enrolment_visit_p1_b9")}</li>
              </ul>
              {currentCountry === "ZA" && (
                <>
                  <strong>{t("minor_enrolment_visit_p2_title")}</strong>
                  <br /> <br />
                </>
              )}
              {t("minor_enrolment_visit_p2")}
              <br /> <br />
              <strong>{t("minor_enrolment_visit_p3_title")}</strong>
              <br /> <br />
              {t("minor_enrolment_visit_p3")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_follow_up_visits_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_follow_up_visits_p1")}
              <ul>
                <li>{t("minor_follow_up_visits_p1_b1")}</li>
                <li>{t("minor_follow_up_visits_p1_b2")}</li>
                <li>{t("minor_follow_up_visits_p1_b3")}</li>
                <li>{t("minor_follow_up_visits_p1_b4")}</li>
                <li>{t("minor_follow_up_visits_p1_b5")}</li>
                <li>{t("minor_follow_up_visits_p1_b6")}</li>
                <li>{t("minor_follow_up_visits_p1_b7")}</li>
              </ul>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_your_responsibilities_title")}
            </h2>
          }
          contentElements={
            <>
              <ul>
                <li>{t("minor_your_responsibilities_b1")}</li>
                <li>{t("minor_your_responsibilities_b2")}</li>
                <li>{t("minor_your_responsibilities_b3")}</li>
                <li>{t("minor_your_responsibilities_b4")}</li>
                <li>{t("minor_your_responsibilities_b5")}</li>
                <li>{t("minor_your_responsibilities_b6")}</li>
              </ul>
              {t("minor_your_responsibilities_p2_title")}
              <ul>
                <li>{t("minor_your_responsibilities_p2_b1")}</li>
              </ul>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_risks_and_discomforts_title")}
            </h2>
          }
          contentElements={<>{t("minor_risks_and_discomforts_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_benefits_title")}
            </h2>
          }
          contentElements={<>{t("minor_benefits_p1")}</>}
        />
        {currentCountry === "ZM" && (
          <DisplayIntroSection
            printMode={printMode}
            titleElements={
              <h2 className={styles.accordionTitle}>
                {t("minor_cost_of_research_related_injuries_title")}
              </h2>
            }
            contentElements={
              <>
                {t("minor_cost_of_research_related_injuries_p1")}
                <br />
                <br />
                {t("minor_cost_of_research_related_injuries_p2")}
                <br />
                <br />
                {t("minor_cost_of_research_related_injuries_p3")}
              </>
            }
          />
        )}
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_new_information_title")}
            </h2>
          }
          contentElements={<>{t("minor_new_information_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_if_i_infected_title")}
            </h2>
          }
          contentElements={<>{t("minor_what_if_i_infected_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_info_about_privacy_confidentiality_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_info_about_privacy_confidentiality_p1")}
              {currentCountry === "ZA" && (
                <>
                  <br />
                  <br />
                  {t("minor_info_about_privacy_confidentiality_p2")}
                </>
              )}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_who_is_responsible_for_processing_my_data_title")}
            </h2>
          }
          contentElements={
            <>{t("minor_who_is_responsible_for_processing_my_data_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_purpose_and_legal_basis_processing_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_what_purpose_and_legal_basis_processing_p1")}
              <br />
              <br />
              {t("minor_what_purpose_and_legal_basis_processing_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_who_do_you_share_my_data_with_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_who_do_you_share_my_data_with_p1")}
              <br />
              <br />
              {t("minor_who_do_you_share_my_data_with_p2")}
              <br />
              <br />
              {t("minor_who_do_you_share_my_data_with_p3")}
              <br />
              <br />
              {t("minor_who_do_you_share_my_data_with_p4")}
              {currentCountry === "ZA" && (
                <>
                  <br />
                  <br />
                  {t("minor_who_do_you_share_my_data_with_p5")}
                  <ul>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b1")}</li>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b2")}</li>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b3")}</li>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b4")}</li>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b5")}</li>
                    <li>{t("minor_who_do_you_share_my_data_with_p6_b6")}</li>
                  </ul>
                </>
              )}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_happens_to_the_data_and_samples_title")}
            </h2>
          }
          contentElements={
            <>
              {t("minor_what_happens_to_the_data_and_samples_p1")}
              <br />
              <br />
              {t("minor_what_happens_to_the_data_and_samples_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_how_is_my_data_kept_secure_title")}
            </h2>
          }
          contentElements={<>{t("minor_how_is_my_data_kept_secure_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_are_my_rights_under_protections_act_title")}
            </h2>
          }
          contentElements={
            <>{t("minor_what_are_my_rights_under_protections_act_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_what_if_not_happy_how_my_data_handled_title")}
            </h2>
          }
          contentElements={
            <>
              <RenderHtmlText
                htmlText={`${t(
                  "minor_what_if_not_happy_how_my_data_handled_p1"
                )}`}
              />
              {currentCountry === "ZA" && (
                <>
                  <br />
                  <br />
                  <RenderHtmlText
                    htmlText={`${t(
                      "minor_what_if_not_happy_how_my_data_handled_p2"
                    )}`}
                  />
                  <br />
                  <br />
                  <RenderHtmlText
                    htmlText={`${t(
                      "minor_what_if_not_happy_how_my_data_handled_p3"
                    )}`}
                  />
                </>
              )}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_alternatives_to_participation_title")}
            </h2>
          }
          contentElements={<>{t("minor_alternatives_to_participation_p1")}</>}
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_reimbursement_title")}
            </h2>
          }
          contentElements={<>{t("minor_reimbursement_p1")}</>}
        />

        {currentCountry === "ZA" && (
          <>
            <DisplayIntroSection
              printMode={printMode}
              titleElements={
                <h2 className={styles.accordionTitle}>
                  {t("minor_cost_of_research_related_injuries_title")}
                </h2>
              }
              contentElements={
                <>
                  {t("minor_cost_of_research_related_injuries_p1")}
                  <br />
                  <br />
                  {t("minor_cost_of_research_related_injuries_p2")}
                  <br />
                  <br />
                  {t("minor_cost_of_research_related_injuries_p3")}
                </>
              }
            />

            <DisplayIntroSection
              printMode={printMode}
              titleElements={
                <h2 className={styles.accordionTitle}>
                  {t("minor_abpi_statement_on_compensation_title")}
                </h2>
              }
              contentElements={
                <>
                  {t("minor_abpi_statement_on_compensation_p1")}
                  <ul>
                    <li>{t("minor_abpi_statement_on_compensation_p1_b1")}</li>
                    <li>{t("minor_abpi_statement_on_compensation_p1_b2")}</li>
                  </ul>
                  {t("minor_abpi_statement_on_compensation_p2")}
                  <ul>
                    <li>{t("minor_abpi_statement_on_compensation_p2_b1")}</li>
                    <li>{t("minor_abpi_statement_on_compensation_p2_b2")}</li>
                  </ul>
                  {t("minor_abpi_statement_on_compensation_p3")}
                  <ul>
                    <li>{t("minor_abpi_statement_on_compensation_p3_b1")}</li>
                    <li>{t("minor_abpi_statement_on_compensation_p3_b2")}</li>
                    <li>{t("minor_abpi_statement_on_compensation_p3_b3")}</li>
                    <li>{t("minor_abpi_statement_on_compensation_p3_b4")}</li>
                  </ul>
                  {t("minor_abpi_statement_on_compensation_p4")}
                  <br />
                  <br />
                  {t("minor_abpi_statement_on_compensation_p5")}
                </>
              }
            />

            <DisplayIntroSection
              printMode={printMode}
              titleElements={
                <h2 className={styles.accordionTitle}>
                  {t("minor_emergency_care_and_hospitalisation_title")}
                </h2>
              }
              contentElements={
                <>{t("minor_emergency_care_and_hospitalisation_p1")}</>
              }
            />

            <DisplayIntroSection
              printMode={printMode}
              titleElements={
                <h2 className={styles.accordionTitle}>
                  {t("minor_ethical_approval_title")}
                </h2>
              }
              contentElements={
                <>
                  <ul>
                    <li>{t("minor_ethical_approval_b1")}</li>
                    <li>{t("minor_ethical_approval_b2")}</li>
                    <li>{t("minor_ethical_approval_b3")}</li>
                    <li>{t("minor_ethical_approval_b4")}</li>
                    <li>{t("minor_ethical_approval_b5")}</li>
                  </ul>
                </>
              }
            />
          </>
        )}
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_consent_for_long_term_storage_samples_title")}
            </h2>
          }
          contentElements={
            <>{t("minor_consent_for_long_term_storage_samples_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_other_research_related_to_health_title")}
            </h2>
          }
          contentElements={
            <>{t("minor_other_research_related_to_health_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("minor_contact_numbers_title")}
            </h2>
          }
          contentElements={
            <>
              <RenderHtmlText htmlText={`${t("minor_contact_numbers_p1")}`} />
              <br />
              <br />
              <RenderHtmlText htmlText={`${t("minor_contact_numbers_p2")}`} />
            </>
          }
        />
      </div>
    </>
  );
};

ConsentMinorParticipantIntro.propTypes = {
  printMode: PropTypes.bool,
};

export default ConsentMinorParticipantIntro;
