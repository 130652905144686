import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "./aws-config";
import LoginPage from "./pages/Login";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ForgotPasswordConfirm from "./pages/ForgotPasswordConfirm";
import HomePage from "./pages/HomePage";
import { setIsDeviceOnline } from "./store/slices/settingsSlice";
import useNavigatorOnLine from "./hooks/get_online_status";
import { useDispatch } from "react-redux";
import ViewAllCosentData from "./pages/ViewAllCosentData";
import MainConsents from "./pages/MainConsents";
import StepFinalReview from "./pages/NewEnrolment/StepFinalReview";
import Dashboard from "./pages/HomePage/Dashboard";
import Participants from "./pages/Participants";
import ManageUsers from "./pages/Users/ManageUsers";
import AddUser from "./pages/Users/AddUser";
import EditUser from "./pages/Users/EditUser";
import FGDA1Consents from "./pages/FGDA1/FGDA1Consents";
import FGDA1FinalReview from "./pages/FGDA1/FGDA1FinalReview";
import ConsentedParticipants from "./pages/Reports/ConsentedParticipants";
import FGDA3Consents from "./pages/FGDA3/FGDA3Consents";
import FGDA3FinalReview from "./pages/FGDA3/FGDA3FinalReview";
import IDIA1Consents from "./pages/IDIA1/IDIA1Consents";
import IDIA3Consents from "./pages/IDIA3/IDIA3Consents";
import IDIA3FinalReview from "./pages/IDIA3/IDIA3FinalReview";
import IDIA1FinalReview from "./pages/IDIA1/IDIA1FinalReview";
import KIIA1Consents from "./pages/KIIA1/KIIA1Consents";
import KIIA1FinalReview from "./pages/KIIA1/KIIA1FinalReview";
import KIIA3Consents from "./pages/KIIA3/KIIA3Consents";
import KIIA3FinalReview from "./pages/KIIA3/KIIA3FinalReview";
import ICConsents from "./pages/IC/ICConsents";
import ICFinalReview from "./pages/IC/ICFinalReview";
import ILConsents from "./pages/IL/ILConsents";
import ILFinalReview from "./pages/IL/ILFinalReview";

Amplify.configure(awsconfig);

function App() {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const isOnline = useNavigatorOnLine();
  const dispatch = useDispatch();

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        setIsUserAuthenticated(true);
        console.log("isUserAuthenticated");
      })
      .catch(() => {
        if (isOnline) {
          setIsUserAuthenticated(false);
        } else {
          setIsUserAuthenticated(true);
        }
      });
  }, []);

  useEffect(() => {
    dispatch(setIsDeviceOnline(isOnline));
  }, [isOnline]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return isUserAuthenticated === true ? (
              <Redirect to="/home" />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/confirm-forgot-password">
          <ForgotPasswordConfirm />
        </Route>
        <Route path="/home">
          <HomePage />
        </Route>
        <Route path="/view-all-consent">
          <ViewAllCosentData />
        </Route>
        <Route path="/primary-study">
          <MainConsents />
        </Route>
        <Route path="/focus-group-discussions-aim-1">
          <FGDA1Consents />
        </Route>
        <Route path="/focus-group-discussions-aim-3">
          <FGDA3Consents />
        </Route>
        <Route path="/in-depth-interview-aim-1">
          <IDIA1Consents />
        </Route>
        <Route path="/in-depth-interview-aim-3">
          <IDIA3Consents />
        </Route>
        <Route path="/participants">
          <Participants />
        </Route>
        <Route path="/manage-users">
          <ManageUsers />
        </Route>
        <Route path="/add-user">
          <AddUser />
        </Route>
        <Route path="/edit-user">
          <EditUser />
        </Route>
        <Route path="/consent-print-preview">
          <StepFinalReview />
        </Route>
        <Route path="/fgda1-print-preview">
          <FGDA1FinalReview />
        </Route>
        <Route path="/fgda3-print-preview">
          <FGDA3FinalReview />
        </Route>
        <Route path="/idia1-print-preview">
          <IDIA1FinalReview />
        </Route>
        <Route path="/idia3-print-preview">
          <IDIA3FinalReview />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/consented-participants-report">
          <ConsentedParticipants />
        </Route>
        <Route path="/key-informant-interview-aim-1">
          <KIIA1Consents />
        </Route>
        <Route path="/kiia1-print-preview">
          <KIIA1FinalReview />
        </Route>
        <Route path="/key-informant-interview-aim-3">
          <KIIA3Consents />
        </Route>
        <Route path="/kiia3-print-preview">
          <KIIA3FinalReview />
        </Route>
        <Route path="/immune-cell-sub-study">
          <ICConsents />
        </Route>
        <Route path="/ic-print-preview">
          <ICFinalReview />
        </Route>
        <Route path="/intensive-longitudinal-sub-study">
          <ILConsents />
        </Route>
        <Route path="/il-print-preview">
          <ILFinalReview />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
