import React, { useEffect, useState } from "react";
import AppHeader from "../../../components/AppHeader/AppHeader";
import styles from "./ManageUsers.module.css";
import Box from "@mui/material/Box";
import PreLoader from "../../../components/common/PreLoader";
import { Container } from "@mui/material";
import { setAppTitle } from "../../../store/slices/settingsSlice";
import { useDispatch } from "react-redux";
import { apiGetUsers } from "../../../services/api/userApi.service";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  apiDeleteUser,
  apiResetUser,
} from "../../../services/api/userApi.service";
import { useSnackbar } from "notistack";
import { setSelectedUserData } from "../../../store/slices/settingsSlice";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

const ManageUsers = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [openDeleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState("");
  const [openResetConfirm, setResetConfirm] = useState(false);
  const [resetUserEmail, setResetUserEmail] = useState("");

  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "first_name",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
    },
    {
      field: "email_verified",
      headerName: "Email Verified",
      width: 200,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "phone_number_verified",
      headerName: "Phone Number Verified",
      width: 200,
    },
    {
      field: "locale",
      headerName: "Country (Language)",
      width: 160,
    },
    {
      field: "site_id",
      headerName: "Site ID",
      width: 160,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      getActions: (params) => [
        <GridActionsCellItem
          key={`col_edit_app`}
          icon={
            <Tooltip title="Edit User">
              <EditIcon />
            </Tooltip>
          }
          label="Edit User"
          onClick={() => {
            goEditUser(params.id, params.row);
          }}
        />,
        <GridActionsCellItem
          key={`col_view_app`}
          icon={
            <Tooltip title="Delete User">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete user"
          onClick={() => {
            setDeleteUserEmail(params.row.email);
            setDeleteConfirm(true);
          }}
          showInMenu
        />,
        <GridActionsCellItem
          key={`col_view_app`}
          icon={
            <Tooltip title="Reser User">
              <LockResetOutlinedIcon />
            </Tooltip>
          }
          label="Reser user"
          onClick={() => {
            setResetUserEmail(params.row.email);
            setResetConfirm(true);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const deleteUser = async () => {
    const _userObj = {
      email: deleteUserEmail,
    };

    setLoading(true);
    const results = await apiDeleteUser(_userObj);

    if (results.success) {
      setDeleteConfirm(false);
      enqueueSnackbar("Successfully deleted.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
      getUsers();
    } else {
      enqueueSnackbar(results.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
    }
  };

  const resetUser = async () => {
    const _userObj = {
      email: resetUserEmail,
    };

    setLoading(true);
    const results = await apiResetUser(_userObj);

    if (results.success) {
      setResetConfirm(false);
      enqueueSnackbar("Successfully reset.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setTimeout(()=>{
        getUsers();
      }, 1000);
    } else {
      enqueueSnackbar(results.message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      setLoading(false);
    }
  };

  const closeDeleteConfirm = () => {
    setDeleteConfirm(false);
  };

  const closeResetConfirm = () => {
    setResetConfirm(false);
  };

  const getUsers = async () => {
    setLoading(true);
    const results = await apiGetUsers();

    if (results.success) {
      let data = results.body;

      console.log(data);
      setUsers(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const localeMap = {
    en_ZM: "Zambia (English)",
    bem_ZM: "Zambia (Bemba)",
    ny_ZM: "Zambia (Nyanja)",
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      let _rows = [];

      users.forEach((data, index) => {
        _rows.push({
          id: index + 1,
          email: data.email,
          email_verified: data.email_verified ? "Yes" : "No",
          first_name: data.first_name,
          last_name: data.last_name,
          locale: localeMap[data.locale],
          phone_number: data.phone_number,
          phone_number_verified: data.phone_number_verified ? "Yes" : "No",
          preferred_username: data.preferred_username,
          site_id: data.site_id,
          sub: data.sub,
          user_cache: data.user_cache,
        });
      });
      setRows(_rows);
    }
  }, [users]);

  const handleRowSelection = (e) => {
    const _PK = e[0];
    const _currentRecord = rows.filter((item) => item.id === _PK)[0];
    console.log(_PK, _currentRecord);
  };

  const goAddUser = () => {
    history.push("/add-user");
  };

  const goEditUser = (id, row) => {
    dispatch(setSelectedUserData(row));
    setTimeout(() => {
      history.push("/edit-user");
    }, 100);
  };

  useEffect(() => {
    dispatch(setAppTitle("MAGY MONITORING"));
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={`ManageUsers`} />
      <Container maxWidth="xlg">
        <div className={styles.pageBoxBody}>
          <div className={styles.addUserBtnBar}>
            <div>
              <h1>Manage Users</h1>
            </div>
            <div>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={goAddUser}
              >
                Add User
              </Button>
            </div>
          </div>
          <Box sx={{ height: "80vh", width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection={false}
              onSelectionModelChange={handleRowSelection}
            />
          </Box>
        </div>
      </Container>

      <ConfirmDialog
        title={`Delete User`}
        message={`Are you sure you want to delete this user (${deleteUserEmail})?`}
        show={openDeleteConfirm}
        onClose={closeDeleteConfirm}
        confirmYes={deleteUser}
        confirmNo={closeDeleteConfirm}
      />

      <ConfirmDialog
        title={`Reset User`}
        message={`Are you sure you want to reset this user (${resetUserEmail})?`}
        show={openResetConfirm}
        onClose={closeResetConfirm}
        confirmYes={resetUser}
        confirmNo={closeResetConfirm}
      />

      <PreLoader show={Loading} />
    </>
  );
};

export default ManageUsers;
