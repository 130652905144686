import React from "react";
import styles from "./LoginForm.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const LoginForm = ({ handleLogin }) => {
  const { t } = useTranslation("common");

  const validationSchema = yup.object({
    email: yup
      .string(t("label_enter_email"))
      .email(t("label_enter_valid_email"))
      .required(t("label_email_required")),
    password: yup
      .string(t("label_enter_password"))
      .min(14, t("label_password_length"))
      .required(t("label_password_required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  return (
    <>
      <div className={styles.loginBox}>
        <div className={styles.pageTitleBox}>
          <h1>{t("login")}</h1>
        </div>
        <div className={styles.loginBody}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <div className="form-field">
                <label>{t("form.email_address")}</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  autoFocus
                  placeholder={t("form.email_address")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="form-field">
                <label>{t("form.password")}</label>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  placeholder={t("form.password")}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
                disabled={!formik.dirty}
              >
                {t("form.continue")}
              </Button>
              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Link href="/forgot-password" variant="body2">
                  {t("form.forgot_password")}?
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

LoginForm.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default LoginForm;
