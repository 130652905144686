import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import settingsSlice from "./slices/settingsSlice";
import impartialWitnessFormSlice from "./slices/impartialWitnessFormSlice";
import personObtainingFormSlice from "./slices/personObtainingFormSlice";
import enrolmentSlice from "./slices/enrolmentSlice";
import aouSlice from "./slices/aouSlice";
import offlineDataSlice from "./slices/offlineDataSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  settings: settingsSlice,
  impartialWitnessForm: impartialWitnessFormSlice,
  personObtainingForm: personObtainingFormSlice,
  enrolment: enrolmentSlice,
  aou: aouSlice,
  offlineData: offlineDataSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
