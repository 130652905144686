/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import styles from "./ForgotPasswordConfirm.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { confirmForgotPassword } from "../../services/users.service";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import PreLoader from "../../components/common/PreLoader";

const ForgotPasswordConfirm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showSuccess, setShowSuccess] = useState(false);
  const location = useLocation();
  const [Loading, setLoading] = useState(false);

  const validationSchema = yup.object({
    verificationCode: yup
      .number("Enter your verification code")
      .integer("Please enter a valid verification code")
      .typeError("Please enter a valid verification code")
      .positive("Please enter a valid verification code")
      .min(4, "Enter a valid verification code")
      .required("Verification code is required"),
    password: yup
      .string("Enter your password")
      .min(14, "Password should be of minimum 14 characters length")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must contain a minimum of 14 characters including 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character (^ $ * . [ ] { } ( ) ? -  ! @ # % & /  , > < ' : ; | _ ~ ` + =)"
      ),
    confirmPassword: yup
      .string("Enter your confirm password")
      .min(14, "Confirm password should be of minimum 14 characters length")
      .required("Confirm password is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const handleForgotPasswordConfirm = async (values) => {
    setLoading(true);
    const response = await confirmForgotPassword(
      location.state.email,
      values.verificationCode.toString(),
      values.password
    );

    if (response.success) {
      setLoading(false);
      setShowSuccess(true);
    } else {
      setLoading(false);
      let _message =
        "Unable to submit your request. Please contact an administrator.";
      if (response.type === "CodeMismatchException") {
        _message = `Invalid verification code provided, please try again.`;
      }
      enqueueSnackbar(_message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      verificationCode: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleForgotPasswordConfirm(values);
    },
  });

  useEffect(() => {
    console.log(location.state);
  }, []);

  return (
    <>
      <AppHeader isGuestMode={true} />
      {!showSuccess && (
        <div className={styles.pageBox}>
          <div className={styles.pageTitleBox}>
            <h1>Verification Code</h1>
          </div>
          <div style={{ textAlign: "center" }}>
            <div className={styles.pageBoxBody}>
              <p>
                We have sent a verification code to your email.
                <br />
                If you haven&apos;t received it, please check your junk mail.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ mt: 1 }}>
                  <div className="form-field">
                    <label>Verification Code</label>
                    <TextField
                      type="text"
                      margin="normal"
                      fullWidth
                      id="verificationCode"
                      name="verificationCode"
                      autoFocus
                      placeholder="Verification Code"
                      value={formik.values.verificationCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.verificationCode &&
                        Boolean(formik.errors.verificationCode)
                      }
                      helperText={
                        formik.touched.verificationCode &&
                        formik.errors.verificationCode
                      }
                    />
                  </div>
                  <div className="form-field">
                    <label>Password</label>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="password"
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </div>
                  <div className="form-field">
                    <label>Confirm Password</label>
                    <TextField
                      margin="normal"
                      fullWidth
                      name="confirmPassword"
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.confirmPassword &&
                        Boolean(formik.errors.confirmPassword)
                      }
                      helperText={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    fullWidth
                    disabled={!formik.dirty}
                  >
                    Confirm
                  </Button>

                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <Link href="/login" variant="body2">
                      Already have an account? Please log in.
                    </Link>
                  </div>
                </Box>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* success page */}
      {showSuccess && (
        <div className={styles.pageBody}>
          <div className={styles.pageTitleBox}>
            <h1>Password Reset</h1>
          </div>
          <div style={{ textAlign: "center" }}>
            <p>Your password has been successfully updated.</p>
            <br />
            <Link href="/login" variant="body2">
              Login here
            </Link>
          </div>
        </div>
      )}
      <PreLoader show={Loading} />
    </>
  );
};

export default ForgotPasswordConfirm;
