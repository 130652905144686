import React, { useState } from "react";
import styles from "./BarChart.module.css";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const BarChart = ({
  chartTitle,
  chartHeight,
  series,
  columnWidth,
  colors,
  xaxisCategories,
  yaxisText,
}) => {
  const data = {
    series: [...series],
    options: {
      chart: {
        type: "bar",
        height: chartHeight,
      },
      
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: columnWidth,
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: colors,
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      xaxis: {
        categories: xaxisCategories,
      },
      yaxis: {
        title: {
          text: yaxisText,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <div className={styles.barChart}>
      <h3>{chartTitle}</h3>
      <Chart
        options={data.options}
        series={data.series}
        type="bar"
        height={chartHeight}
        width={"100%"}
      />
    </div>
  );
};

BarChart.propTypes = {
  chartTitle: PropTypes.string,
  chartHeight: PropTypes.string,
  series: PropTypes.array,
  columnWidth: PropTypes.string,
  colors: PropTypes.array,
  xaxisCategories: PropTypes.array,
  yaxisText: PropTypes.string,
};

export default BarChart;
