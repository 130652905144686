/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

const settingsInitialData = {
  ageCat: null,
  parentIlliterate: null,
  participantIlliterate: null,
  enrolmentForms: null,
  currentFormIndex: null,
  appTitle: "",
  participantType: null,
  isDeviceOnline: true,
  triggerEvent: null,
  recordStatus: null,
  loggedInUser: null,
  userLocale: null,
  showSideMenu: true,
  selectedUserData: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: { ...settingsInitialData },
  reducers: {
    setAgeCat: (state, action) => {
      state.ageCat = action.payload;
    },
    setParentIlliterate: (state, action) => {
      state.parentIlliterate = action.payload;
    },
    setParticipantIlliterate: (state, action) => {
      state.participantIlliterate = action.payload;
    },
    setEnrolmentForms: (state, action) => {
      state.enrolmentForms = action.payload;
    },
    setCurrentFormIndex: (state, action) => {
      state.currentFormIndex = action.payload;
    },
    setAppTitle: (state, action) => {
      state.appTitle = action.payload;
    },
    setParticipantType: (state, action) => {
      state.participantType = action.payload;
    },
    setIsDeviceOnline: (state, action) => {
      state.isDeviceOnline = action.payload;
    },
    resetSettings: (state) => {
      const newState = { ...settingsInitialData };
      state.ageCat = newState.ageCat;
      state.parentIlliterate = newState.parentIlliterate;
      state.participantIlliterate = newState.participantIlliterate;
      state.enrolmentForms = newState.enrolmentForms;
      state.currentFormIndex = newState.currentFormIndex;
      state.appTitle = newState.appTitle;
      state.recordStatus = newState.recordStatus;
      state.selectedUserData = newState.selectedUserData;
    },
    setTriggerEvent: (state, action) => {
      state.triggerEvent = action.payload;
    },
    setRecordStatus: (state, action) => {
      state.recordStatus = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setUserLocale: (state, action) => {
      state.userLocale = action.payload.userLocale;
      state.currentLanguage = action.payload.currentLanguage;
      state.currentCountry = action.payload.currentCountry;
    },
    setShowSideMenu: (state, action) => {
      state.showSideMenu = action.payload;
    },
    setSelectedUserData: (state, action) => {
      state.selectedUserData = action.payload;
    },
  },
});

export const {
  setAgeCat,
  setParentIlliterate,
  setParticipantIlliterate,
  setEnrolmentForms,
  setCurrentFormIndex,
  setAppTitle,
  resetSettings,
  setParticipantType,
  setIsDeviceOnline,
  setTriggerEvent,
  setRecordStatus,
  setLoggedInUser,
  setUserLocale,
  setShowSideMenu,
  setSelectedUserData,
} = settingsSlice.actions;

export default settingsSlice.reducer;
