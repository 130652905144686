import React from "react";
import styles from "./SetupLoginMFA.module.css";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { QRCodeCanvas } from "qrcode.react";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";

const SetupLoginMFA = ({ code, username, completedMFASetup, cognitoUser }) => {
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    otp: yup.string("Enter OTP").required("OTP is required"),
  });

  const handleOTPForm = async (values) => {
    Auth.verifyTotpToken(cognitoUser, values.otp)
      .then(() => {
        // don't forget to set TOTP as the preferred MFA method
        Auth.setPreferredMFA(cognitoUser, "TOTP");
        completedMFASetup();
        // ...
      })
      .catch((e) => {
        console.log(e);
        // Token is not verified
        enqueueSnackbar("Verification Failed. Please try again.", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOTPForm(values);
    },
  });

  return (
    <>
      <div className={styles.registerBox}>
        <div className={styles.pageTitleBox}>
          <h1>Setup Multi Factor Authentication</h1>
          <p>
            Please scan the QR code using Google Authenticator App. You can
            download it from (Play Store or Apple App Store).
          </p>
        </div>
        <div className={styles.registerBody}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <center>
                <QRCodeCanvas
                  value={`otpauth://totp/AWSCognito:${username}?secret=${code}&issuer=IAVI`}
                />
              </center>
              <br />
              <div className="form-field">
                <label>
                  Enter the generated OTP Code from the Authenticator
                </label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="otp"
                  name="otp"
                  autoFocus
                  placeholder="OTP Code"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  error={formik.touched.otp && Boolean(formik.errors.otp)}
                  helperText={formik.touched.otp && formik.errors.otp}
                />
              </div>

              <Button type="submit" variant="contained" fullWidth>
                Continue
              </Button>

              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <Link href="/login" variant="body2">
                  Back to Login.
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

SetupLoginMFA.propTypes = {
  code: PropTypes.string,
  username: PropTypes.string,
  children: PropTypes.any,
  completedMFASetup: PropTypes.func,
  cognitoUser: PropTypes.any,
};

export default SetupLoginMFA;
