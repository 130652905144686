import React, { useState, useEffect } from "react";
import styles from "./LongStorageAdultParticipantView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const LongStorageAdultParticipantView = () => {
  const [t] = useTranslation("common");
  const isParticipantIlliterate = useSelector(
    (state) => state.settings.participantIlliterate
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [participantConsentObj, setParticipantConsentObj] = useState({});

  useEffect(() => {
    setParticipantConsentObj({
      participantFirstName: enrolmentState.lng_stor_participant_first_name,
      participantLastName: enrolmentState.lng_stor_participant_last_name,
      consentDate: enrolmentState.lng_stor_participant_date,
      consentTime: enrolmentState.lng_stor_participant_time,
      signature: enrolmentState.lng_stor_participant_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>{t("consent_form_lng_store_title")}</h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            {t("participant_lstore_consent_q1")}{" "}
            <strong>({enrolmentState.lng_stor_participant_consent_1})</strong>
          </li>
          <li>
            {t("participant_lstore_consent_q2")}{" "}
            <strong>({enrolmentState.lng_stor_participant_consent_2})</strong>
          </li>
          <li>
            {t("participant_lstore_consent_q3")}{" "}
            <strong>({enrolmentState.lng_stor_participant_consent_3})</strong>
          </li>
        </ol>
        <p>{t("participant_lstore_consent_p1")}</p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_first_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_participant_last_name")} </label>
                <div className={styles.fieldText}>
                  {participantConsentObj.participantLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_participant")}{" "}
                  {isParticipantIlliterate
                    ? t("label_acknowledgement")
                    : t("label_signature")}{" "}
                </label>
                <SignatureImageView
                  signature={participantConsentObj.signature}
                />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {participantConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default LongStorageAdultParticipantView;
