import React from "react";
import styles from "./CompleteSignupForm.module.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import {
  COGNITO_PASSWORD_REGEX,
  COGNITO_PASSWORD_REGEX_ERROR_MESSAGE,
} from "../../constants/common";
import { useTranslation } from "react-i18next";

const CompleteSignupForm = ({ handleCompleteSignupForm }) => {
  const [t] = useTranslation("common");
  const validationSchema = yup.object({
    firstName: yup
      .string(t("label_enter_first_name"))
      .required(t("first_name_required")),
    lastName: yup
      .string(t("label_enter_last_name"))
      .required(t("last_name_required")),
    password: yup
      .string(t("label_enter_password"))
      .min(14, t("label_password_length"))
      .required(t("label_password_required"))
      .matches(
        COGNITO_PASSWORD_REGEX,
        COGNITO_PASSWORD_REGEX_ERROR_MESSAGE.replace(
          "label_mini_password_chars",
          t("label_mini_password_chars")
        )
      ),
    confirmPassword: yup
      .string(t("label_confirm_password"))
      .required(t("label_password_verification_required"))
      .matches(
        COGNITO_PASSWORD_REGEX,
        COGNITO_PASSWORD_REGEX_ERROR_MESSAGE.replace(
          "label_mini_password_chars",
          t("label_mini_password_chars")
        )
      )
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      siteId: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleCompleteSignupForm(values);
    },
  });

  return (
    <>
      <div className={styles.registerBox}>
        <div className={styles.pageTitleBox}>
          <h1>{t("label_update_profile")}</h1>
        </div>
        <div className={styles.registerBody}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 1 }}>
              <div className="form-field">
                <label>{t("label_first_name")}</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  autoFocus
                  placeholder={t("label_first_name")}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
              <div className="form-field">
                <label>{t("label_last_name")}</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  placeholder={t("label_last_name")}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
              <div className="form-field">
                <label>{t("label_password")}</label>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  type="password"
                  id="password"
                  placeholder={t("label_password")}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </div>
              <div className="form-field">
                <label>Confirm Password</label>
                <TextField
                  margin="normal"
                  fullWidth
                  name="confirmPassword"
                  type="password"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </div>

              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
                disabled={!formik.dirty}
              >
                {t("form.continue")}
              </Button>

              <div style={{ textAlign: "center", marginTop: "10px" }}>
                <Link href="/login" variant="body2">
                  {t("label_already_have_account")}
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

CompleteSignupForm.propTypes = {
  handleCompleteSignupForm: PropTypes.func.isRequired,
  children: PropTypes.any,
};

export default CompleteSignupForm;
