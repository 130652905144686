/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getParticipant = /* GraphQL */ `
  query GetParticipant($PK: String!, $participant_country: String!) {
    getParticipant(PK: $PK, participant_country: $participant_country) {
      PK
      SK
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
      participant_country
      participant_consent_list
      participant_id
    }
  }
`;
export const getEnrolment = /* GraphQL */ `
  query GetEnrolment($PK: String!, $SK: String!, $consent_country: String!) {
    getEnrolment(PK: $PK, SK: $SK, consent_country: $consent_country) {
      PK
      SK
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      lng_stor_participant_consent_1
      lng_stor_participant_consent_2
      lng_stor_participant_consent_3
      lng_stor_participant_first_name
      lng_stor_participant_last_name
      lng_stor_participant_signature
      lng_stor_participant_date
      lng_stor_participant_time
      lng_stor_participant_witness_first_name
      lng_stor_participant_witness_last_name
      lng_stor_participant_witness_signature
      lng_stor_participant_witness_date
      lng_stor_participant_witness_time
      lng_stor_participant_staff_first_name
      lng_stor_participant_staff_last_name
      lng_stor_participant_staff_signature
      lng_stor_participant_staff_date
      lng_stor_participant_staff_time
      participant_aou_results
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      lng_stor_guardian_consent_1
      lng_stor_guardian_consent_2
      lng_stor_guardian_consent_3
      lng_stor_guardian_child_first_name
      lng_stor_guardian_child_last_name
      lng_stor_guardian_first_name
      lng_stor_guardian_last_name
      lng_stor_guardian_signature
      lng_stor_guardian_date
      lng_stor_guardian_time
      lng_stor_guardian_witness_first_name
      lng_stor_guardian_witness_last_name
      lng_stor_guardian_witness_signature
      lng_stor_guardian_witness_date
      lng_stor_guardian_witness_time
      lng_stor_guardian_staff_first_name
      lng_stor_guardian_staff_last_name
      lng_stor_guardian_staff_signature
      lng_stor_guardian_staff_date
      lng_stor_guardian_staff_time
      guardian_aou_results
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
      gen_test_guardian_consents
      gen_test_guardian_child_first_name
      gen_test_guardian_child_last_name
      gen_test_guardian_first_name
      gen_test_guardian_last_name
      gen_test_guardian_signature
      gen_test_guardian_date
      gen_test_guardian_time
      gen_test_guardian_witness_first_name
      gen_test_guardian_witness_last_name
      gen_test_guardian_witness_signature
      gen_test_guardian_witness_date
      gen_test_guardian_witness_time
      gen_test_guardian_staff_first_name
      gen_test_guardian_staff_last_name
      gen_test_guardian_staff_date
      gen_test_guardian_staff_time
      gen_test_participant_consents
      gen_test_participant_child_first_name
      gen_test_participant_child_last_name
      gen_test_participant_first_name
      gen_test_participant_last_name
      gen_test_participant_signature
      gen_test_participant_date
      gen_test_participant_time
      gen_test_participant_witness_first_name
      gen_test_participant_witness_last_name
      gen_test_participant_witness_signature
      gen_test_participant_witness_date
      gen_test_participant_witness_time
      gen_test_participant_staff_first_name
      gen_test_participant_staff_last_name
      gen_test_participant_staff_signature
      gen_test_participant_staff_date
      gen_test_participant_staff_time
      guardian_icf_type_version_no
      guardian_icf_version_date
      guardian_icf_signed_date
      participant_icf_type_version_no
      participant_icf_version_date
      participant_icf_signed_date
      date_reviewed_monitor
      review_status
      query_status
      query_messages
    }
  }
`;
export const downloadSignature = /* GraphQL */ `
  query DownloadSignature($input: inputSignature!) {
    downloadSignature(input: $input) {
      presign_url
    }
  }
`;
export const findParticipant = /* GraphQL */ `
  query FindParticipant($input: inputFindParticipant) {
    findParticipant(input: $input) {
      participant_id
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
      participant_status
      participant_consent_list
      participant_country
    }
  }
`;
export const verifyOTP = /* GraphQL */ `
  query VerifyOTP($input: inputVerifyOTP) {
    verifyOTP(input: $input) {
      uuid_token
      otp_code
      valid
    }
  }
`;
export const getEnrolments = /* GraphQL */ `
  query GetEnrolments {
    getEnrolments {
      PK
      SK
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      lng_stor_participant_consent_1
      lng_stor_participant_consent_2
      lng_stor_participant_consent_3
      lng_stor_participant_first_name
      lng_stor_participant_last_name
      lng_stor_participant_signature
      lng_stor_participant_date
      lng_stor_participant_time
      lng_stor_participant_witness_first_name
      lng_stor_participant_witness_last_name
      lng_stor_participant_witness_signature
      lng_stor_participant_witness_date
      lng_stor_participant_witness_time
      lng_stor_participant_staff_first_name
      lng_stor_participant_staff_last_name
      lng_stor_participant_staff_signature
      lng_stor_participant_staff_date
      lng_stor_participant_staff_time
      participant_aou_results
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      lng_stor_guardian_consent_1
      lng_stor_guardian_consent_2
      lng_stor_guardian_consent_3
      lng_stor_guardian_child_first_name
      lng_stor_guardian_child_last_name
      lng_stor_guardian_first_name
      lng_stor_guardian_last_name
      lng_stor_guardian_signature
      lng_stor_guardian_date
      lng_stor_guardian_time
      lng_stor_guardian_witness_first_name
      lng_stor_guardian_witness_last_name
      lng_stor_guardian_witness_signature
      lng_stor_guardian_witness_date
      lng_stor_guardian_witness_time
      lng_stor_guardian_staff_first_name
      lng_stor_guardian_staff_last_name
      lng_stor_guardian_staff_signature
      lng_stor_guardian_staff_date
      lng_stor_guardian_staff_time
      guardian_aou_results
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
      gen_test_guardian_consents
      gen_test_guardian_child_first_name
      gen_test_guardian_child_last_name
      gen_test_guardian_first_name
      gen_test_guardian_last_name
      gen_test_guardian_signature
      gen_test_guardian_date
      gen_test_guardian_time
      gen_test_guardian_witness_first_name
      gen_test_guardian_witness_last_name
      gen_test_guardian_witness_signature
      gen_test_guardian_witness_date
      gen_test_guardian_witness_time
      gen_test_guardian_staff_first_name
      gen_test_guardian_staff_last_name
      gen_test_guardian_staff_date
      gen_test_guardian_staff_time
      gen_test_participant_consents
      gen_test_participant_child_first_name
      gen_test_participant_child_last_name
      gen_test_participant_first_name
      gen_test_participant_last_name
      gen_test_participant_signature
      gen_test_participant_date
      gen_test_participant_time
      gen_test_participant_witness_first_name
      gen_test_participant_witness_last_name
      gen_test_participant_witness_signature
      gen_test_participant_witness_date
      gen_test_participant_witness_time
      gen_test_participant_staff_first_name
      gen_test_participant_staff_last_name
      gen_test_participant_staff_signature
      gen_test_participant_staff_date
      gen_test_participant_staff_time
      guardian_icf_type_version_no
      guardian_icf_version_date
      guardian_icf_signed_date
      participant_icf_type_version_no
      participant_icf_version_date
      participant_icf_signed_date
      date_reviewed_monitor
      review_status
      query_status
      query_messages
    }
  }
`;
export const getParticipants = /* GraphQL */ `
  query GetParticipants {
    getParticipants {
      PK
      SK
      participant_first_name
      participant_last_name
      participant_signature
      participant_illiterate
      guardian_consent
      guardian_first_name
      guardian_last_name
      guardian_signature
      guardian_illiterate
      chosen_language
      age_category
      created_date
      created_time
      site_id
      participant_country
      participant_consent_list
      participant_id
    }
  }
`;
export const getAllSubStudyFGDA1 = /* GraphQL */ `
  query GetAllSubStudyFGDA1 {
    getAllSubStudyFGDA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyIDIA1 = /* GraphQL */ `
  query GetAllSubStudyIDIA1 {
    getAllSubStudyIDIA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyIDIA3 = /* GraphQL */ `
  query GetSubStudyIDIA3($PK: String!, $SK: String!) {
    getSubStudyIDIA3(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyIDIA3 = /* GraphQL */ `
  query GetAllSubStudyIDIA3 {
    getAllSubStudyIDIA3 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyKIIA1 = /* GraphQL */ `
  query GetAllSubStudyKIIA1 {
    getAllSubStudyKIIA1 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyFGDA3 = /* GraphQL */ `
  query GetAllSubStudyFGDA3 {
    getAllSubStudyFGDA3 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyKIIA3 = /* GraphQL */ `
  query GetAllSubStudyKIIA3 {
    getAllSubStudyKIIA3 {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyIL = /* GraphQL */ `
  query GetAllSubStudyIL {
    getAllSubStudyIL {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyIL = /* GraphQL */ `
  query GetSubStudyIL($PK: String!, $SK: String!) {
    getSubStudyIL(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyKIIA3 = /* GraphQL */ `
  query GetSubStudyKIIA3($PK: String!, $SK: String!) {
    getSubStudyKIIA3(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getAllSubStudyIC = /* GraphQL */ `
  query GetAllSubStudyIC {
    getAllSubStudyIC {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyIC = /* GraphQL */ `
  query GetSubStudyIC($PK: String!, $SK: String!) {
    getSubStudyIC(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyFGDA3 = /* GraphQL */ `
  query GetSubStudyFGDA3($PK: String!, $SK: String!) {
    getSubStudyFGDA3(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getSubStudyFGDA1 = /* GraphQL */ `
  query GetSubStudyFGDA1($PK: String!, $SK: String!) {
    getSubStudyFGDA1(PK: $PK, SK: $SK) {
      PK
      SK
      sub_study_name
      participant_id
      metadata
      chosen_language
      age_category
      created_date
      created_time
      site_id
      screen_times
      participant_illiterate
      consent_participant_first_name
      consent_participant_last_name
      consent_participant_signature
      consent_participant_date
      consent_participant_time
      consent_participant_witness_first_name
      consent_participant_witness_last_name
      consent_participant_witness_signature
      consent_participant_witness_date
      consent_participant_witness_time
      consent_participant_staff_first_name
      consent_participant_staff_last_name
      consent_participant_staff_signature
      consent_participant_staff_date
      consent_participant_staff_time
      audio_tape_participant_consent_1
      audio_tape_participant_consent_2
      audio_tape_participant_first_name
      audio_tape_participant_last_name
      audio_tape_participant_signature
      audio_tape_participant_date
      audio_tape_participant_time
      audio_tape_participant_witness_first_name
      audio_tape_participant_witness_last_name
      audio_tape_participant_witness_signature
      audio_tape_participant_witness_date
      audio_tape_participant_witness_time
      audio_tape_participant_staff_first_name
      audio_tape_participant_staff_last_name
      audio_tape_participant_staff_signature
      audio_tape_participant_staff_date
      audio_tape_participant_staff_time
      guardian_illiterate
      consent_guardian_first_name
      consent_guardian_last_name
      consent_guardian_signature
      consent_guardian_date
      consent_guardian_time
      consent_guardian_child_first_name
      consent_guardian_child_last_name
      consent_guardian_witness_first_name
      consent_guardian_witness_last_name
      consent_guardian_witness_signature
      consent_guardian_witness_date
      consent_guardian_witness_time
      consent_guardian_staff_first_name
      consent_guardian_staff_last_name
      consent_guardian_staff_signature
      consent_guardian_staff_date
      consent_guardian_staff_time
      audio_tape_guardian_consent_1
      audio_tape_guardian_consent_2
      audio_tape_guardian_first_name
      audio_tape_guardian_last_name
      audio_tape_guardian_signature
      audio_tape_guardian_date
      audio_tape_guardian_time
      audio_tape_guardian_witness_first_name
      audio_tape_guardian_witness_last_name
      audio_tape_guardian_witness_signature
      audio_tape_guardian_witness_date
      audio_tape_guardian_witness_time
      audio_tape_guardian_staff_first_name
      audio_tape_guardian_staff_last_name
      audio_tape_guardian_staff_signature
      audio_tape_guardian_staff_date
      audio_tape_guardian_staff_time
      record_status
      participant_status
      consent_country
      guardian_consents
      participant_consents
    }
  }
`;
export const getPrimaryStudyDashboard = /* GraphQL */ `
  query GetPrimaryStudyDashboard($year: Int, $month: Int) {
    getPrimaryStudyDashboard(year: $year, month: $month) {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
      lng_stor_total_yes
      lng_stor_total_no
      lng_stor_minor_guardian
      lng_stor_emancipated_minor
      lng_stor_adult
      participant_aou_results
      guardian_aou_results
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getPrimaryStudyDashboardCountrySpecific = /* GraphQL */ `
  query GetPrimaryStudyDashboardCountrySpecific {
    getPrimaryStudyDashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
      lng_stor_total_yes
      lng_stor_total_no
      lng_stor_minor_guardian
      lng_stor_emancipated_minor
      lng_stor_adult
      gen_test_total_yes
      gen_test_total_no
      gen_test_minor_guardian
      gen_test_emancipated_minor
      gen_test_adult
      participant_aou_results
      guardian_aou_results
    }
  }
`;
export const getSubStudyFGDA1Dashboard = /* GraphQL */ `
  query GetSubStudyFGDA1Dashboard($year: Int, $month: Int) {
    getSubStudyFGDA1Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyFGDA3Dashboard = /* GraphQL */ `
  query GetSubStudyFGDA3Dashboard($year: Int, $month: Int) {
    getSubStudyFGDA3Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyIDIA1Dashboard = /* GraphQL */ `
  query GetSubStudyIDIA1Dashboard($year: Int, $month: Int) {
    getSubStudyIDIA1Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyIDIA3Dashboard = /* GraphQL */ `
  query GetSubStudyIDIA3Dashboard($year: Int, $month: Int) {
    getSubStudyIDIA3Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyKIIA1Dashboard = /* GraphQL */ `
  query GetSubStudyKIIA1Dashboard($year: Int, $month: Int) {
    getSubStudyKIIA1Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyKIIA3Dashboard = /* GraphQL */ `
  query GetSubStudyKIIA3Dashboard($year: Int, $month: Int) {
    getSubStudyKIIA3Dashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyILDashboard = /* GraphQL */ `
  query GetSubStudyILDashboard($year: Int, $month: Int) {
    getSubStudyILDashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyICDashboard = /* GraphQL */ `
  query GetSubStudyICDashboard($year: Int, $month: Int) {
    getSubStudyICDashboard(year: $year, month: $month) {
      week_data {
        week_range
        country_counts {
          ZM
          ZA
          UG
        }
        month
      }
    }
  }
`;
export const getSubStudyFGDA1DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyFGDA1DashboardCountrySpecific {
    getSubStudyFGDA1DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyFGDA3DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyFGDA3DashboardCountrySpecific {
    getSubStudyFGDA3DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyIDIA1DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyIDIA1DashboardCountrySpecific {
    getSubStudyIDIA1DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyIDIA3DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyIDIA3DashboardCountrySpecific {
    getSubStudyIDIA3DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyKIIA1DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyKIIA1DashboardCountrySpecific {
    getSubStudyKIIA1DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyKIIA3DashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyKIIA3DashboardCountrySpecific {
    getSubStudyKIIA3DashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyILDashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyILDashboardCountrySpecific {
    getSubStudyILDashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getSubStudyICDashboardCountrySpecific = /* GraphQL */ `
  query GetSubStudyICDashboardCountrySpecific {
    getSubStudyICDashboardCountrySpecific {
      total_screened
      total_completed_consents
      total_failed_consents
      total_consents_minor
      total_consents_emancipated_minor
      total_consents_adults
      total_completed_consents_minor
      total_completed_consents_emancipated_minor
      total_completed_consents_adults
      total_failed_consents_minor
      total_failed_consents_emancipated_minor
      total_failed_consents_adults
      total_literate_participants
      total_illiterate_participants
      total_literate_guardians
      total_illiterate_guardians
    }
  }
`;
export const getAuditInformation = /* GraphQL */ `
  query GetAuditInformation(
    $participant_id: String!
    $SK: String!
    $consent_country: String!
  ) {
    getAuditInformation(
      participant_id: $participant_id
      SK: $SK
      consent_country: $consent_country
    ) {
      audit_info
    }
  }
`;
export const getQueryMessages = /* GraphQL */ `
  query GetQueryMessages(
    $participant_id: String!
    $SK: String!
    $consent_country: String!
  ) {
    getQueryMessages(
      participant_id: $participant_id
      SK: $SK
      consent_country: $consent_country
    ) {
      query_messages
    }
  }
`;
export const getEnrolmentGuardianAOUResults = /* GraphQL */ `
  query GetEnrolmentGuardianAOUResults(
    $PK: String!
    $SK: String!
    $consent_country: String!
  ) {
    getEnrolmentGuardianAOUResults(
      PK: $PK
      SK: $SK
      consent_country: $consent_country
    ) {
      guardian_aou_results
      participant_aou_results
    }
  }
`;
export const getEnrolmentParticipantAOUResults = /* GraphQL */ `
  query GetEnrolmentParticipantAOUResults(
    $PK: String!
    $SK: String!
    $consent_country: String!
  ) {
    getEnrolmentParticipantAOUResults(
      PK: $PK
      SK: $SK
      consent_country: $consent_country
    ) {
      guardian_aou_results
      participant_aou_results
    }
  }
`;
export const getConsentedParticipantReport = /* GraphQL */ `
  query GetConsentedParticipantReport {
    getConsentedParticipantReport {
      PK
      SK
      guardian_icf_type_version_no
      guardian_icf_version_date
      guardian_icf_signed_date
      participant_icf_type_version_no
      participant_icf_version_date
      participant_icf_signed_date
      date_reviewed_monitor
      review_status
      query_status
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      email
      email_verified
      first_name
      last_name
      locale
      phone_number
      phone_number_verified
      site_id
      sub
      user_cache
      preferred_username
    }
  }
`;
export const listUserGroups = /* GraphQL */ `
  query ListUserGroups($email: String!) {
    listUserGroups(email: $email) {
      email
      groups
    }
  }
`;
export const generateParticipantsReport = /* GraphQL */ `
  query GenerateParticipantsReport($consent_country: String!) {
    generateParticipantsReport(consent_country: $consent_country)
  }
`;
