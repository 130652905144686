import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useSelector, useDispatch } from "react-redux";
import { setShowSideMenu } from "../../store/slices/settingsSlice";
import MagyLogo from "../../assets/magy_logo.png";
import styles from "./SideMenu.module.css";
import DashboardOutlinedIcon from "@mui/icons-material/Dashboard";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import SideMenuItem from "./SideMenuItem";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { Auth } from "aws-amplify";

const SideMenu = () => {
  const { showSideMenu } = useSelector((state) => state.settings);
  const [userRoles, setUserRoles] = useState([]);
  const dispatch = useDispatch();

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    dispatch(setShowSideMenu(!showSideMenu));
  };

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      setUserRoles(
        user.signInUserSession.accessToken.payload["cognito:groups"]
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  const isValidGroup = (groupName) => {
    return userRoles.indexOf(groupName) > -1;
  };

  const list = () => (
    <Box sx={{ width: 350 }} role="presentation">
      <div className={styles.iaviLogoContainer}>
        <img
          className={styles.iaviLogo}
          src={MagyLogo}
          alt={"MAGY - Monitoring App"}
        />
        <div className={styles.appLogoTitle}>Monitoring</div>
      </div>
      <Divider />
      <List>
        {(isValidGroup("Sponsors") ||
          isValidGroup("SiteUsers") ||
          isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_dashboard`}
            url="/home"
            icon={<DashboardOutlinedIcon />}
            text="Dashboard"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_participants`}
            url="/participants"
            icon={<ContactPageOutlinedIcon />}
            text="Participants"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_primary_study`}
            url="/primary-study"
            icon={<NoteAltOutlinedIcon />}
            text="Primary Study"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_fgda1`}
            url="/focus-group-discussions-aim-1"
            icon={<NoteAltOutlinedIcon />}
            text="Focus Group Discussions Aim 1"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_fgda3`}
            url="/focus-group-discussions-aim-3"
            icon={<NoteAltOutlinedIcon />}
            text="Focus Group Discussions Aim 3"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_idia1`}
            url="/in-depth-interview-aim-1"
            icon={<NoteAltOutlinedIcon />}
            text="In-depth Interview Aim 1"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_idia3`}
            url="/in-depth-interview-aim-3"
            icon={<NoteAltOutlinedIcon />}
            text="In-depth Interview Aim 3"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_kiia1`}
            url="/key-informant-interview-aim-1"
            icon={<NoteAltOutlinedIcon />}
            text="Key Informant Interview Aim 1"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_kiia3`}
            url="/key-informant-interview-aim-3"
            icon={<NoteAltOutlinedIcon />}
            text="Key Informant Interview Aim 3"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_ic`}
            url="/immune-cell-sub-study"
            icon={<NoteAltOutlinedIcon />}
            text="Immune Cell Sub Study"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_il`}
            url="/intensive-longitudinal-sub-study"
            icon={<NoteAltOutlinedIcon />}
            text="Intensive Longitudinal Sub Study"
            toggleDrawer={toggleDrawer}
          />
        )}

        {isValidGroup("Admin") && (
          <SideMenuItem
            key={`menu_users`}
            url="/manage-users"
            icon={<ContactPageOutlinedIcon />}
            text="Manage Users"
            toggleDrawer={toggleDrawer}
          />
        )}

        {(isValidGroup("SiteUsers") || isValidGroup("Monitor")) && (
          <SideMenuItem
            key={`menu_consented_participants_report`}
            url="/consented-participants-report"
            icon={<TextSnippetOutlinedIcon />}
            text="Consented Participants Report"
            toggleDrawer={toggleDrawer}
          />
        )}
      </List>
    </Box>
  );

  useEffect(() => {
    toggleDrawer();
    dispatch(setShowSideMenu(showSideMenu));
  }, [showSideMenu]);

  useEffect(() => {
    dispatch(setShowSideMenu(false));
    getCurrentUser();
  }, []);

  return (
    <div>
      <SwipeableDrawer
        anchor={"left"}
        open={showSideMenu}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default SideMenu;
