import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { apiGetAuditInformation } from "../../services/api/enrolmentApi.service";
import PreLoader from "../common/PreLoader";
import { useTranslation } from "react-i18next";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

const ViewAuditTrail = ({ SK }) => {
  const [t] = useTranslation("common");
  const { participant_id, enrolment_id } = useSelector(
    (state) => state.enrolment
  );
  const [Loading, setLoading] = useState(false);
  const consentCountry = localStorage.getItem("i18nextLng").split("_")[1];
  const [auditTrailData, setAuditTrailData] = useState([]);

  const getAuditInfo = async () => {
    try {
      const auditInfoResults = await apiGetAuditInformation(
        participant_id,
        `${SK}#${enrolment_id}`,
        consentCountry
      );

      if (auditInfoResults.success) {
        let data = auditInfoResults.body.audit_info;

        if (data !== null) {
          setAuditTrailData(JSON.parse(data));
          console.log(JSON.parse(data));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (participant_id !== undefined) {
      setLoading(true);
      getAuditInfo();
    }
  }, [participant_id]);

  return (
    <>
      <Box sx={{ pt: 1, pb: 1 }}>
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.6,
            },
          }}
        >
          {auditTrailData.map((item) => (
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                {dayjs(item.dateTime).format("DD/MM/YYYY - HH:mm")}
                <br />
                {item.user}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{item.info}</TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
      <PreLoader show={Loading} />
    </>
  );
};

ViewAuditTrail.propTypes = {
  signature: PropTypes.string,
};

export default ViewAuditTrail;
