import { API, graphqlOperation } from "aws-amplify";
import { getParticipants } from "../../graphql/queries";

export const apiGetParticipants = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getParticipants));

    results.body = response.data.getParticipants;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
