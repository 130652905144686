import React from "react";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const DisplayIntroSection = ({
  printMode = false,
  titleElements,
  contentElements,
}) => {
  return (
    <>
      {printMode ? (
        <div className="introSectionPrintMode">
          {titleElements}
          <br />
          {contentElements}
        </div>
      ) : (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {titleElements}
          </AccordionSummary>
          <AccordionDetails>{contentElements}</AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

DisplayIntroSection.propTypes = {
  show: PropTypes.bool,
  titleElements: PropTypes.any,
  contentElements: PropTypes.any,
};

export default DisplayIntroSection;
