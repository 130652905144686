/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import AppHeader from "../../../components/AppHeader/AppHeader";
import styles from "./ConsentedParticipants.module.css";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import PreLoader from "../../../components/common/PreLoader";
import { Container } from "@mui/material";
import { setAppTitle } from "../../../store/slices/settingsSlice";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabAllConsents from "./TabConsentedParticipants";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { apiGenerateConsentedParticipantsReport } from "../../../services/api/enrolmentApi.service";

const ConsentedParticipants = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [enrolments, setEnrolments] = useState(null);
  const [value, setValue] = React.useState(0);
  const consentCountry = localStorage.getItem("i18nextLng").split("_")[1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const downloadReport = async () => {
  //   const results = await apiGenerateConsentedParticipantsReport(
  //     consentCountry
  //   );

  //   if (results.success) {
  //     const downloadUrl = results.body
  //       .replace("{presign_url=", "")
  //       .replace("}", "");
  //     console.log(downloadUrl);
  //     const a = document.createElement("a");
  //     a.href = downloadUrl;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //   }
  // };

  useEffect(() => {
    dispatch(setAppTitle("Consented Participants Report"));
  }, []);

  return (
    <>
      <AppHeader isGuestMode={false} heading={`MAIN CONSENTS`} />
      <Container maxWidth="xlg">
        <div className={styles.pageBoxBody}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Consented participants report"
              >
                <Tab label="Main Study" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TabAllConsents ageCat={""} />
            </TabPanel>
          </Box>
        </div>
      </Container>

      <PreLoader show={Loading} />
    </>
  );
};

export default ConsentedParticipants;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
