export const guardianColumns = [
  {
    field: "guardian_illiterate",
    headerName: "Parent Illiterate",
    width: 185,
  },
  {
    field: "consent_guardian_child_first_name",
    headerName: "Child First Name",
    width: 160,
  },
  {
    field: "consent_guardian_child_last_name",
    headerName: "Child Last Name",
    width: 160,
  },
  {
    field: "consent_guardian_first_name",
    headerName: "Parent  First Name",
    width: 160,
  },
  {
    field: "consent_guardian_last_name",
    headerName: "Parent Last Name",
    width: 160,
  },
  {
    field: "consent_guardian_date",
    headerName: "Parent Consent Date",
    width: 185,
  },
  {
    field: "consent_guardian_time",
    headerName: "Parent Consent Time",
    width: 185,
  },
  {
    field: "consent_guardian_witness_first_name",
    headerName: "Parent Witness First Name",
    width: 185,
  },
  {
    field: "consent_guardian_witness_last_name",
    headerName: "Parent Witness Last Name",
    width: 185,
  },
  {
    field: "consent_guardian_witness_date",
    headerName: "Parent Witness Date",
    width: 185,
  },
  {
    field: "consent_guardian_witness_time",
    headerName: "Parent Witness Time",
    width: 185,
  },
  {
    field: "consent_guardian_staff_first_name",
    headerName: "Parent Person Obtaining First Name",
    width: 250,
  },
  {
    field: "consent_guardian_staff_last_name",
    headerName: "Parent Person Obtaining First Name",
    width: 250,
  },
  {
    field: "consent_guardian_staff_date",
    headerName: "Parent Person Obtaining Date",
    width: 220,
  },
  {
    field: "consent_guardian_staff_time",
    headerName: "Parent Person Obtaining Time",
    width: 220,
  },
];

export const participantColumns = [
  {
    field: "participant_illiterate",
    headerName: "Participant Illiterate",
    width: 185,
  },
  {
    field: "consent_participant_first_name",
    headerName: "Participant First Name",
    width: 160,
  },
  {
    field: "consent_participant_last_name",
    headerName: "Participant Last Name",
    width: 160,
  },
  {
    field: "consent_participant_date",
    headerName: "Participant Consent Date",
    width: 185,
  },
  {
    field: "consent_participant_time",
    headerName: "Participant Consent Time",
    width: 185,
  },
  {
    field: "consent_participant_witness_first_name",
    headerName: "Participant Witness First Name",
    width: 185,
  },
  {
    field: "consent_participant_witness_last_name",
    headerName: "Participant Witness Last Name",
    width: 185,
  },
  {
    field: "consent_participant_witness_date",
    headerName: "Participant Witness Date",
    width: 185,
  },
  {
    field: "consent_participant_witness_time",
    headerName: "Participant Witness Time",
    width: 185,
  },
  {
    field: "consent_participant_staff_first_name",
    headerName: "Participant Person Obtaining First Name",
    width: 250,
  },
  {
    field: "consent_participant_staff_last_name",
    headerName: "Participant Person Obtaining First Name",
    width: 250,
  },
  {
    field: "consent_participant_staff_date",
    headerName: "Participant Person Obtaining Date",
    width: 220,
  },
  {
    field: "consent_participant_staff_time",
    headerName: "Participant Person Obtaining Time",
    width: 220,
  },
];

export const commonColumns = [
  { field: "id", headerName: "ID", width: 50, hide: true },
  {
    field: "full_enrolment_id",
    headerName: "Consent ID",
    width: 130,
  },
  {
    field: "full_participant_id",
    headerName: "Participant ID",
    width: 130,
  },
  {
    field: "site_id",
    headerName: "Site ID",
    width: 130,
  },
  {
    field: "enrolment_date",
    headerName: "Enrolment Date",
    width: 130,
  },
  {
    field: "chosen_language",
    headerName: "Chosen Language",
    width: 185,
  },
  { field: "age_cat", headerName: "Age Cat", width: 180 },

  { field: "record_status", headerName: "Status", width: 200 },
];
