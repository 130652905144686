import React, { useEffect, useState } from "react";
import styles from "./LongStorageAdultParticipantIntro.module.css";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DisplayIntroSection from "../../../../components/common/DisplayIntroSection";

const LongStorageAdultParticipantIntro = ({ printMode = false }) => {
  const [userFullName, setUserFullName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [t] = useTranslation("common");

  const getCurrentUser = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();
      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      setUserRole(
        user.signInUserSession.accessToken.payload["cognito:groups"][0]
      );
      setUserFullName(`${firstName} ${lastName}`);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <>
      <h2 className={styles.intro}>{t("participant_lstore_title")}</h2>
      <p>
        {t("participant_lstore_intro")
          .replace("#userFullName#", userFullName)
          .replace("#userRole#", userRole)}
      </p>
      <div>
        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_why_samples_important_title")}
            </h2>
          }
          contentElements={
            <> {t("participant_lstore_why_samples_important_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_purpose_of_sample_storage_title")}
            </h2>
          }
          contentElements={
            <> {t("participant_lstore_purpose_of_sample_storage_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_where_sample_store_title")}
            </h2>
          }
          contentElements={
            <>
              {t("participant_lstore_where_sample_store_p1")}
              <br />
              <br />
              <table>
                <tr>
                  <td>
                    {t("participant_lstore_where_sample_store_sa_lab_heading")}
                  </td>
                  <td>
                    {t("participant_lstore_where_sample_store_int_lab_heading")}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t("participant_lstore_where_sample_store_sa_lab_add1_l1")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add1_l2")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add1_l3")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add1_l4")}
                  </td>
                  <td>
                    {t("participant_lstore_where_sample_store_int_lab_add1_l1")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add1_l2")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add1_l3")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add1_l4")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add1_l5")}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t("participant_lstore_where_sample_store_sa_lab_add2_l1")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add2_l2")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add2_l3")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add2_l4")}
                    <br />
                    {t("participant_lstore_where_sample_store_sa_lab_add2_l5")}
                  </td>
                  <td>
                    {t("participant_lstore_where_sample_store_int_lab_add2_l1")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add2_l2")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add2_l3")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add2_l4")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add2_l5")}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {t("participant_lstore_where_sample_store_int_lab_add3_l1")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add3_l2")}
                    <br />
                    {t("participant_lstore_where_sample_store_int_lab_add3_l3")}
                  </td>
                </tr>
              </table>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_will_I_be_paid_for_use_samples_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_will_I_be_paid_for_use_samples_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_will_I_benefit_from_allowing_other_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_will_I_benefit_from_allowing_other_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_will_sell_my_sample_info_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_will_sell_my_sample_info_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t(
                "participant_lstore_what_kind_research_done_stores_sample_title"
              )}
            </h2>
          }
          contentElements={
            <>
              {t("participant_lstore_what_kind_research_done_stores_sample_p1")}
              <br /> <br />
              <strong>
                {t(
                  "participant_lstore_what_kind_research_done_stores_sample_p2"
                )}
              </strong>
              <ul>
                <li>
                  {t(
                    "participant_lstore_what_kind_research_done_stores_sample_p2_b1"
                  )}
                </li>
                <li>
                  {t(
                    "participant_lstore_what_kind_research_done_stores_sample_p2_b2"
                  )}
                </li>
                <li>
                  {t(
                    "participant_lstore_what_kind_research_done_stores_sample_p2_b3"
                  )}
                </li>
              </ul>
              <strong>
                {t(
                  "participant_lstore_what_kind_research_done_stores_sample_p3"
                )}
              </strong>
              <ul>
                <li>
                  {t(
                    "participant_lstore_what_kind_research_done_stores_sample_p3_b1"
                  )}
                </li>
              </ul>
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_what_risks_genetic_testing_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_what_risks_genetic_testing_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_what_benefits_genetic_testing_title")}
            </h2>
          }
          contentElements={
            <>
              {t("participant_lstore_what_benefits_genetic_testing_p1")}
              <br />
              <br />
              {t("participant_lstore_what_benefits_genetic_testing_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_how_samples_stored_kept_title")}
            </h2>
          }
          contentElements={
            <>
              {t("participant_lstore_how_samples_stored_kept_p1")}
              <br />
              <br />
              {t("participant_lstore_how_samples_stored_kept_p2")}
            </>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_how_long_my_samples_stores_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_how_long_my_samples_stores_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_what_if_i_change_mind_title")}
            </h2>
          }
          contentElements={
            <>{t("participant_lstore_what_if_i_change_mind_p1")}</>
          }
        />

        <DisplayIntroSection
          printMode={printMode}
          titleElements={
            <h2 className={styles.accordionTitle}>
              {t("participant_lstore_contact_title")}
            </h2>
          }
          contentElements={
            <>
              {t("participant_lstore_contact_p1")}
              <br />
              <br />
              {t("participant_lstore_contact_p2")}
            </>
          }
        />
      </div>
    </>
  );
};

LongStorageAdultParticipantIntro.propTypes = {
  printMode: PropTypes.bool,
};

export default LongStorageAdultParticipantIntro;
