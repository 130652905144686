import React, { useState, useEffect } from "react";
import styles from "./ConsentMinorParentView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const ConsentMinorParentView = () => {
  const [t] = useTranslation("common");
  const { parentIlliterate, currentCountry } = useSelector(
    (state) => state.settings
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [parentConsentObj, setParentConsentObj] = useState({});
  const selectedCountry = localStorage.getItem("i18nextLng").split("_")[1];

  useEffect(() => {
    setParentConsentObj({
      childFirstName: enrolmentState.consent_guardian_child_first_name,
      childLastName: enrolmentState.consent_guardian_child_last_name,
      parentFirstName: enrolmentState.consent_guardian_first_name,
      parentLastName: enrolmentState.consent_guardian_last_name,
      consentDate: enrolmentState.consent_guardian_date,
      consentTime: enrolmentState.consent_guardian_time,
      signature: enrolmentState.consent_guardian_signature,
    });
  }, [enrolmentState]);

  const getSouthAfricaContent = () => (
    <>
      <h2 className={styles.intro}>
        {t("IL_parent_consent_title")}
      </h2>

    </>
  );

  const getZambiaContent = () => (
    <>
      <h2 className={styles.intro}>
        {t("IL_parent_consent_title")}
      </h2>

      <div className={styles.consentText}>
        <p>
          <strong>{t("IL_minor_parent_giving_consent")}</strong>
        </p>

        <ol>
          <li>
            {t("IL_minor_parent_giving_consent_1")}{" "}
            <strong>({enrolmentState.guardian_consents.consent1})</strong>
          </li>
        </ol>

        <p>{t("IL_minor_parent_giving_consent_p1")}</p>
      </div>
    </>
  );

  return (
    <div className={styles.pageViewBox}>
      {selectedCountry === "ZA" && getSouthAfricaContent()}
      {selectedCountry === "ZM" && getZambiaContent()}

      <div className={styles.consentForm}>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_child_first_name")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_child_last_name")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_parent_legal_guardian_first_name")} </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_parent_legal_guardian_last_name")} </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_parent_legal_guardian_signature")}{" "}
                  {parentIlliterate ? "acknowledgement" : "Signature"}{" "}
                </label>
                <SignatureImageView signature={parentConsentObj.signature} />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ConsentMinorParentView;
