import React from "react";
import styles from "./PreLoader.module.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

const PreLoader = ({ show = false }) => {
  return (
    <>
      {show && (
        <div className={styles.loader}>
          <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
        </div>
      )}
    </>
  );
};

PreLoader.propTypes = {
  show: PropTypes.bool,
};

export default PreLoader;
