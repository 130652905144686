import { API, graphqlOperation } from "aws-amplify";
import {
  updateSubStudyFGDA1Participant,
  updateSubStudyFGDA1Guardian,
  updateQueryMessages,
} from "../../graphql/mutations";
import { getSubStudyFGDA1, getAllSubStudyFGDA1, getQueryMessages } from "../../graphql/queries";

export const apiUpdateSubStudyFGDA1Participant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA1Participant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyFGDA1Participant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateSubStudyFGDA1Guardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyFGDA1Guardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyFGDA1Guardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateQuery = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateQueryMessages, {
        QueryMessages: data,
      })
    );

    results.body = response.data.updateQueryMessages;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetQueries = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getQueryMessages, data)
    );

    results.body = response.data.getQueryMessages;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetFGDA1 = async (partcipantId, fgda1Id) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA1, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `FGDA1#${fgda1Id}`,
      })
    );

    results.body = response.data.getSubStudyFGDA1;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetAllFGDA1 = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getAllSubStudyFGDA1));

    results.body = response.data.getAllSubStudyFGDA1;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
