import React, { useState, useEffect } from "react";
import styles from "./KIIA3Dashboard.module.css";
import PreLoader from "../../../components/common/PreLoader";
import AppFooter from "../../../components/AppFooter/AppFooter";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CounterBox from "../../../components/CounterBox";
import BarChart from "../../../components/BarChart";
import LineChart from "../../../components/LineChart";
import {
  apiGetKIIA3Dashboard,
  apiGetKIIA3DashboardCountry,
} from "../../../services/api/dashboardApi.service";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const KIIA3Dashboard = () => {
  const [countryCode] = useState(localStorage.getItem("i18nextLng"));

  const [Loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    total_completed_consents: 0,
    total_completed_consents_adults: 0,
    total_completed_consents_emancipated_minor: 0,
    total_completed_consents_minor: 0,
    total_consents_adults: 0,
    total_consents_emancipated_minor: 0,
    total_consents_minor: 0,
    total_failed_consents: 0,
    total_failed_consents_adults: 0,
    total_failed_consents_emancipated_minor: 0,
    total_failed_consents_minor: 0,
    total_illiterate_guardians: 0,
    total_illiterate_participants: 0,
    total_literate_guardians: 0,
    total_literate_participants: 0,
    total_screened: 0,
    week_data: [],
  });

  const [saTotalParticipants, setSaTotalParticipants] = useState(0);
  const [ugTotalParticipants, setUgTotalParticipants] = useState(0);
  const [zmTotalParticipants, setZmTotalParticipants] = useState(0);
  const [weekDataCats, setWeekDataCats] = useState([]);
  const [weekDataDate, setWeekDataDate] = useState(new Date());
  const [xaxisTotalScreened, setXaxisTotalScreened] = useState([]);
  const [totalScreenedData, setTotalScreenedData] = useState([]);
  const [xaxisTotalConsents, setXaxisTotalConsents] = useState([]);
  const [totalConsentsData, setTotalConsentsData] = useState([]);

  const getPrimaryStudyDashboardCountryData = async () => {
    setLoading(true);
    const results = await apiGetKIIA3DashboardCountry();

    let data = results.body;

    if (results.success) {
      console.log(data);
      setDashboardData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return data;
  };

  const getDashboardDataCountrySpecific = async () => {
    setLoading(true);
    const _dashboardData = await getPrimaryStudyDashboardCountryData();

    if (countryCode.indexOf("ZM") > -1) {
      setTimeout(() => {
        setXaxisTotalScreened([
          "Total Screened",
          "Key Informants",
        ]);

        setTotalScreenedData([
          parseInt(_dashboardData.total_screened),
          parseInt(_dashboardData.total_consents_minor),
          parseInt(_dashboardData.total_consents_adults),
        ]);

        setXaxisTotalConsents([
          "Total Consents",
          "Key Informants",
        ]);

        setTotalConsentsData([
          {
            name: "Total Consents",
            data: [
              parseInt(_dashboardData.total_completed_consents),
              parseInt(_dashboardData.total_completed_consents_minor),

              parseInt(_dashboardData.total_completed_consents_adults),
            ],
          },
          {
            name: "Total Screen outs",
            data: [
              parseInt(_dashboardData.total_failed_consents),
              parseInt(_dashboardData.total_failed_consents_minor),
              parseInt(_dashboardData.total_failed_consents_adults),
            ],
          },
        ]);
      }, 500);
    } else {
      setTimeout(() => {
        setXaxisTotalScreened([
          "Total Screened",
          "Key Informants",
        ]);

        setTotalScreenedData([
          parseInt(_dashboardData.total_screened),
          parseInt(_dashboardData.total_consents_minor),
          parseInt(_dashboardData.total_consents_emancipated_minor),
          parseInt(_dashboardData.total_consents_adults),
        ]);

        setXaxisTotalConsents([
          "Total Consents",
          "Key Informants",
        ]);

        setTotalConsentsData([
          {
            name: "Total Consents",
            data: [
              parseInt(_dashboardData.total_completed_consents),
              parseInt(_dashboardData.total_completed_consents_minor),
              parseInt(
                _dashboardData.total_completed_consents_emancipated_minor
              ),
              parseInt(_dashboardData.total_completed_consents_adults),
            ],
          },
          {
            name: "Total Screen outs",
            data: [
              parseInt(_dashboardData.total_failed_consents),
              parseInt(_dashboardData.total_failed_consents_minor),
              parseInt(_dashboardData.total_failed_consents_emancipated_minor),
              parseInt(_dashboardData.total_failed_consents_adults),
            ],
          },
        ]);
      }, 500);
    }
  };

  const getDashboardWeekData = async (date) => {
    const _date = new Date(date);
    const _month = _date.getMonth() + 1;
    const _year = _date.getFullYear();
    setLoading(true);
    const _dashboardWeekData = await apiGetKIIA3Dashboard(_year, _month);

    setLoading(false);
    if (_dashboardWeekData.body.week_data.length > 0) {
      let _saCounts = [];
      let _ugCounts = [];
      let _zmCounts = [];
      let _weekDataCats = [];

      for (let i = 0; i < _dashboardWeekData.body.week_data.length; i++) {
        _saCounts.push(_dashboardWeekData.body.week_data[i].country_counts.ZA);
        _ugCounts.push(_dashboardWeekData.body.week_data[i].country_counts.UG);
        _zmCounts.push(_dashboardWeekData.body.week_data[i].country_counts.ZM);

        let weekDate = _dashboardWeekData.body.week_data[i].week_range
          .substring(
            _dashboardWeekData.body.week_data[i].week_range.length - 10
          )
          .slice(5, 10)
          .split("-");
        _weekDataCats.push(`Week ${weekDate[1]}/${weekDate[0]}`);
      }

      setSaTotalParticipants(_saCounts);
      setUgTotalParticipants(_ugCounts);
      setZmTotalParticipants(_zmCounts);
      setWeekDataCats(_weekDataCats);
    }
  };

  useEffect(() => {
    getDashboardDataCountrySpecific();
  }, []);

  useEffect(() => {
    getDashboardWeekData(new Date(weekDataDate));
  }, [weekDataDate]);

  return (
    <div className={styles.dashboard}>
      {parseInt(dashboardData.total_screened) > 0 && (
        <Container maxWidth="md">
          <div className={styles.mainMenu}>
            <Grid container spacing={3} mb={2}>
              <Grid item md={3} xs={3}>
                <CounterBox
                  title={"Total Screened"}
                  value={dashboardData.total_screened}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Total Consents"}
                  value={dashboardData.total_completed_consents}
                  valueColor={"#097909"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Total Screen outs"}
                  value={dashboardData.total_failed_consents}
                  valueColor={"#BC1212"}
                  mb={"20"}
                />
              </Grid>
              <Grid item md={9} xs={9}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Total Screened"}
                    chartHeight={"285"}
                    series={[
                      {
                        name: "Total Screened",
                        data: totalScreenedData,
                      },
                    ]}
                    columnWidth={"40%"}
                    colors={["#205588"]}
                    xaxisCategories={xaxisTotalScreened}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Total Consents and Total Screen outs"}
                    chartHeight={"350"}
                    series={totalConsentsData}
                    columnWidth={"45%"}
                    colors={["#097909", "#BC1212"]}
                    xaxisCategories={xaxisTotalConsents}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={3} mb={3}>
              <Grid item md={3} xs={3}>
                <CounterBox
                  title={"Literate Participants"}
                  value={dashboardData.total_literate_participants}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Illiterate Participants"}
                  value={dashboardData.total_illiterate_participants}
                  valueColor={"#4A9CEB"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Literate Parents"}
                  value={dashboardData.total_literate_guardians}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Illiterate Parents"}
                  value={dashboardData.total_illiterate_guardians}
                  valueColor={"#4A9CEB"}
                  mb={"20"}
                />
              </Grid>
              <Grid item md={9} xs={9}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={
                      "Literate and Illiterate Participants / Parents"
                    }
                    chartHeight={"412"}
                    series={[
                      {
                        name: "Literate",
                        data: [
                          parseInt(dashboardData.total_literate_participants),
                          parseInt(dashboardData.total_literate_guardians),
                        ],
                      },
                      {
                        name: "Illiterate",
                        data: [
                          parseInt(dashboardData.total_illiterate_participants),
                          parseInt(dashboardData.total_illiterate_guardians),
                        ],
                      },
                    ]}
                    columnWidth={"40%"}
                    colors={["#205588", "#4A9CEB"]}
                    xaxisCategories={["Participants", "Parents"]}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <h3>Successful enrolments over time</h3>
                  <div className={styles.datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["month", "year"]}
                        defaultValue={new Date()}
                        onChange={(newValue) => setWeekDataDate(newValue)}
                      />
                    </LocalizationProvider>
                  </div>
                  <LineChart
                    chartTitle={"Successful enrolments over time"}
                    chartHeight={"400"}
                    series={[
                      {
                        name: "South Africa",
                        data: saTotalParticipants,
                      },
                      {
                        name: "Uganda",
                        data: ugTotalParticipants,
                      },
                      {
                        name: "Zambia",
                        data: zmTotalParticipants,
                      },
                    ]}
                    colors={["#205588", "#4A9CEB", "#097909"]}
                    xaxisCategories={weekDataCats}
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
          </div>
        </Container>
      )}
      <AppFooter />
      <PreLoader show={Loading} />
    </div>
  );
};

export default KIIA3Dashboard;
