import { createSlice } from "@reduxjs/toolkit";

export const personObtainingFormSlice = createSlice({
  name: "personObtainingForm",
  initialState: {
    formData: {
      personObtainingFirstName: "",
      personObtainingLastName: "",
      personObtainingSignature: null,
      isSigned: false,
      personObtainingDate: "",
      personObtainingTime: "",
    },
  },
  reducers: {
    setPersonObtainingForm: (state, action) => {
      state.formData = action.payload;
    },
    resetPersonObtainingForm: (state) => {
      state.formData = {
        personObtainingFirstName: "",
        personObtainingLastName: "",
        personObtainingSignature: null,
        isSigned: false,
        personObtainingDate: "",
        personObtainingTime: "",
      };
    },
  },
});

export const { setPersonObtainingForm, resetPersonObtainingForm } =
  personObtainingFormSlice.actions;

export default personObtainingFormSlice.reducer;
