import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  participant_id: null,
  enrolment_id: null,
  metadata: null,
  chosen_language: null,
  age_category: null,
  created_date: null,
  created_time: null,
  site_id: null,
  screen_times: null,
  consent_participant_first_name: null,
  consent_participant_last_name: null,
  consent_participant_signature: null,
  consent_participant_date: null,
  consent_participant_time: null,
  consent_participant_witness_first_name: null,
  consent_participant_witness_last_name: null,
  consent_participant_witness_signature: null,
  consent_participant_witness_date: null,
  consent_participant_witness_time: null,
  consent_participant_staff_first_name: null,
  consent_participant_staff_last_name: null,
  consent_participant_staff_signature: null,
  consent_participant_staff_date: null,
  consent_participant_staff_time: null,
  lng_stor_participant_consent_1: null,
  lng_stor_participant_consent_2: null,
  lng_stor_participant_consent_3: null,
  lng_stor_participant_first_name: null,
  lng_stor_participant_last_name: null,
  lng_stor_participant_signature: null,
  lng_stor_participant_date: null,
  lng_stor_participant_time: null,
  lng_stor_participant_witness_first_name: null,
  lng_stor_participant_witness_last_name: null,
  lng_stor_participant_witness_signature: null,
  lng_stor_participant_witness_date: null,
  lng_stor_participant_witness_time: null,
  lng_stor_participant_staff_first_name: null,
  lng_stor_participant_staff_last_name: null,
  lng_stor_participant_staff_signature: null,
  lng_stor_participant_staff_date: null,
  lng_stor_participant_staff_time: null,
  gen_test_participant_first_name: null,
  gen_test_participant_last_name: null,
  gen_test_participant_signature: null,
  gen_test_participant_date: null,
  gen_test_participant_time: null,
  gen_test_participant_witness_first_name: null,
  gen_test_participant_witness_last_name: null,
  gen_test_participant_witness_signature: null,
  gen_test_participant_witness_date: null,
  gen_test_participant_witness_time: null,
  gen_test_participant_staff_first_name: null,
  gen_test_participant_staff_last_name: null,
  gen_test_participant_staff_signature: null,
  gen_test_participant_staff_date: null,
  gen_test_participant_staff_time: null,
  gen_test_participant_consents: null,
  participant_aou_results: null,
  guardian_illiterate: null,
  consent_guardian_child_first_name: null,
  consent_guardian_child_last_name: null,
  consent_guardian_first_name: null,
  consent_guardian_last_name: null,
  consent_guardian_signature: null,
  consent_guardian_date: null,
  consent_guardian_time: null,
  consent_guardian_witness_first_name: null,
  consent_guardian_witness_last_name: null,
  consent_guardian_witness_signature: null,
  consent_guardian_witness_date: null,
  consent_guardian_witness_time: null,
  consent_guardian_staff_first_name: null,
  consent_guardian_staff_last_name: null,
  consent_guardian_staff_signature: null,
  consent_guardian_staff_date: null,
  consent_guardian_staff_time: null,
  lng_stor_guardian_consent_1: null,
  lng_stor_guardian_consent_2: null,
  lng_stor_guardian_consent_3: null,
  lng_stor_guardian_child_first_name: null,
  lng_stor_guardian_child_last_name: null,
  lng_stor_guardian_first_name: null,
  lng_stor_guardian_last_name: null,
  lng_stor_guardian_signature: null,
  lng_stor_guardian_date: null,
  lng_stor_guardian_time: null,
  lng_stor_guardian_witness_first_name: null,
  lng_stor_guardian_witness_last_name: null,
  lng_stor_guardian_witness_signature: null,
  lng_stor_guardian_witness_date: null,
  lng_stor_guardian_witness_time: null,
  lng_stor_guardian_staff_first_name: null,
  lng_stor_guardian_staff_last_name: null,
  lng_stor_guardian_staff_signature: null,
  lng_stor_guardian_staff_date: null,
  lng_stor_guardian_staff_time: null,
  gen_test_guardian_child_first_name: null,
  gen_test_guardian_child_last_name: null,
  gen_test_guardian_first_name: null,
  gen_test_guardian_last_name: null,
  gen_test_guardian_signature: null,
  gen_test_guardian_date: null,
  gen_test_guardian_time: null,
  gen_test_guardian_witness_first_name: null,
  gen_test_guardian_witness_last_name: null,
  gen_test_guardian_witness_signature: null,
  gen_test_guardian_witness_date: null,
  gen_test_guardian_witness_time: null,
  gen_test_guardian_staff_first_name: null,
  gen_test_guardian_staff_last_name: null,
  gen_test_guardian_staff_signature: null,
  gen_test_guardian_staff_date: null,
  gen_test_guardian_staff_time: null,
  gen_test_guardian_consents: null,
  guardian_aou_results: null,
  consent_country: null,
  guardian_consents: null,
  participant_consents: null,
  date_reviewed_monitor: null,
};

export const enrolmentSlice = createSlice({
  name: "enrolment",
  initialState: { ...defaultState },
  reducers: {
    setParticipantId: (state, action) => {
      state.participant_id = action.payload;
    },
    setEnrolmentId: (state, action) => {
      state.enrolment_id = action.payload;
    },

    setGuardianConsents: (state, action) => {
      state.guardian_consents = action.payload;
    },

    setParticipantConsents: (state, action) => {
      state.participant_consents = action.payload;
    },
    setEnrolmentDateTime: (state, action) => {
      state.created_date = action.payload.created_date;
      state.created_time = action.payload.created_time;
    },
    setMinorParentConsent: (state, action) => {
      state.consent_guardian_child_first_name = action.payload.childFirstName;
      state.consent_guardian_child_last_name = action.payload.childLastName;
      state.consent_guardian_first_name = action.payload.parentFirstName;
      state.consent_guardian_last_name = action.payload.parentLastName;
      state.consent_guardian_signature = action.payload.parentSignature;
      state.consent_guardian_date = action.payload.consentDate;
      state.consent_guardian_time = action.payload.consentTime;
    },
    setMinorParticipantConsent: (state, action) => {
      state.consent_participant_first_name =
        action.payload.participantFirstName;
      state.consent_participant_last_name = action.payload.participantLastName;
      state.consent_participant_signature = action.payload.participantSignature;
      state.consent_participant_date = action.payload.consentDate;
      state.consent_participant_time = action.payload.consentTime;
    },
    setMinorParentWitness: (state, action) => {
      state.consent_guardian_witness_first_name =
        action.payload.witnessFirstName;
      state.consent_guardian_witness_last_name = action.payload.witnessLastName;
      state.consent_guardian_witness_signature =
        action.payload.witnessSignature;
      state.consent_guardian_witness_date = action.payload.consentDate;
      state.consent_guardian_witness_time = action.payload.consentTime;
    },
    setMinorParticipantWitness: (state, action) => {
      state.consent_participant_witness_first_name =
        action.payload.witnessFirstName;
      state.consent_participant_witness_last_name =
        action.payload.witnessLastName;
      state.consent_participant_witness_signature =
        action.payload.witnessSignature;
      state.consent_participant_witness_date = action.payload.consentDate;
      state.consent_participant_witness_time = action.payload.consentTime;
    },
    setMinorParentPersonObtaining: (state, action) => {
      state.consent_guardian_staff_first_name =
        action.payload.personObtainingFirstName;
      state.consent_guardian_staff_last_name =
        action.payload.personObtainingLastName;
      state.consent_guardian_staff_signature =
        action.payload.personObtainingSignature;
      state.consent_guardian_staff_date = action.payload.consentDate;
      state.consent_guardian_staff_time = action.payload.consentTime;
    },
    setMinorParticipantPersonObtaining: (state, action) => {
      state.consent_participant_staff_first_name =
        action.payload.personObtainingFirstName;
      state.consent_participant_staff_last_name =
        action.payload.personObtainingLastName;
      state.consent_participant_staff_signature =
        action.payload.personObtainingSignature;
      state.consent_participant_staff_date = action.payload.consentDate;
      state.consent_participant_staff_time = action.payload.consentTime;
    },
    setMinorParentLongStorage: (state, action) => {
      state.lng_stor_guardian_consent_1 = action.payload.consent1;
      state.lng_stor_guardian_consent_2 = action.payload.consent2;
      state.lng_stor_guardian_consent_3 = action.payload.consent3;
      state.lng_stor_guardian_child_first_name = action.payload.childFirstName;
      state.lng_stor_guardian_child_last_name = action.payload.childLastName;
      state.lng_stor_guardian_first_name = action.payload.parentFirstName;
      state.lng_stor_guardian_last_name = action.payload.parentLastName;
      state.lng_stor_guardian_signature = action.payload.parentSignature;
      state.lng_stor_guardian_date = action.payload.consentDate;
      state.lng_stor_guardian_time = action.payload.consentTime;
    },

    setMinorParticipantLongStorage: (state, action) => {
      state.lng_stor_participant_consent_1 = action.payload.consent1;
      state.lng_stor_participant_consent_2 = action.payload.consent2;
      state.lng_stor_participant_consent_3 = action.payload.consent3;
      state.lng_stor_participant_first_name =
        action.payload.participantFirstName;
      state.lng_stor_participant_last_name = action.payload.participantLastName;
      state.lng_stor_participant_signature =
        action.payload.participantSignature;
      state.lng_stor_participant_date = action.payload.consentDate;
      state.lng_stor_participant_time = action.payload.consentTime;
    },

    setMinorParentLongStorageWitness: (state, action) => {
      state.lng_stor_guardian_witness_first_name =
        action.payload.witnessFirstName;
      state.lng_stor_guardian_witness_last_name =
        action.payload.witnessLastName;
      state.lng_stor_guardian_witness_signature =
        action.payload.witnessSignature;
      state.lng_stor_guardian_witness_date = action.payload.consentDate;
      state.lng_stor_guardian_witness_time = action.payload.consentTime;
    },

    setMinorParticipantLongStorageWitness: (state, action) => {
      state.lng_stor_participant_witness_first_name =
        action.payload.witnessFirstName;
      state.lng_stor_participant_witness_last_name =
        action.payload.witnessLastName;
      state.lng_stor_participant_witness_signature =
        action.payload.witnessSignature;
      state.lng_stor_participant_witness_date = action.payload.consentDate;
      state.lng_stor_participant_witness_time = action.payload.consentTime;
    },

    setParticipantWitness: (state, action) => {
      state.lng_stor_participant_witness_first_name =
        action.payload.witnessFirstName;
      state.lng_stor_participant_witness_last_name =
        action.payload.witnessLastName;
      state.lng_stor_participant_witness_signature =
        action.payload.witnessSignature;
      state.lng_stor_participant_witness_date = action.payload.consentDate;
      state.lng_stor_participant_witness_time = action.payload.consentTime;
    },
    setParticipantLongStorageWitness: (state, action) => {
      state.lng_stor_participant_witness_first_name =
        action.payload.witnessFirstName;
      state.lng_stor_participant_witness_last_name =
        action.payload.witnessLastName;
      state.lng_stor_participant_witness_signature =
        action.payload.witnessSignature;
      state.lng_stor_participant_witness_date = action.payload.consentDate;
      state.lng_stor_participant_witness_time = action.payload.consentTime;
    },
    setMinorParentLongStoragePersonObtaining: (state, action) => {
      state.lng_stor_guardian_staff_first_name =
        action.payload.personObtainingFirstName;
      state.lng_stor_guardian_staff_last_name =
        action.payload.personObtainingLastName;
      state.lng_stor_guardian_staff_signature =
        action.payload.personObtainingSignature;
      state.lng_stor_guardian_staff_date = action.payload.consentDate;
      state.lng_stor_guardian_staff_time = action.payload.consentTime;
    },
    setMinorParticipantLongStoragePersonObtaining: (state, action) => {
      state.lng_stor_participant_staff_first_name =
        action.payload.personObtainingFirstName;
      state.lng_stor_participant_staff_last_name =
        action.payload.personObtainingLastName;
      state.lng_stor_participant_staff_signature =
        action.payload.personObtainingSignature;
      state.lng_stor_participant_staff_date = action.payload.consentDate;
      state.lng_stor_participant_staff_time = action.payload.consentTime;
    },
    setParticipantPersonObtaining: (state, action) => {
      state.consent_participant_staff_first_name =
        action.payload.personObtainingFirstName;
      state.consent_participant_staff_last_name =
        action.payload.personObtainingLastName;
      state.consent_participant_staff_signature =
        action.payload.personObtainingSignature;
      state.consent_participant_staff_date = action.payload.consentDate;
      state.consent_participant_staff_time = action.payload.consentTime;
    },
    setParticipantLongStoragePersonObtaining: (state, action) => {
      state.lng_stor_participant_staff_first_name =
        action.payload.personObtainingFirstName;
      state.lng_stor_participant_staff_last_name =
        action.payload.personObtainingLastName;
      state.lng_stor_participant_staff_signature =
        action.payload.personObtainingSignature;
      state.lng_stor_participant_staff_date = action.payload.consentDate;
      state.lng_stor_participant_staff_time = action.payload.consentTime;
    },

    setMinorParentGenTest: (state, action) => {
      state.gen_test_guardian_consents = action.payload.consents;
      state.gen_test_guardian_child_first_name = action.payload.childFirstName;
      state.gen_test_guardian_child_last_name = action.payload.childLastName;
      state.gen_test_guardian_first_name = action.payload.parentFirstName;
      state.gen_test_guardian_last_name = action.payload.parentLastName;
      state.gen_test_guardian_signature = action.payload.parentSignature;
      state.gen_test_guardian_date = action.payload.consentDate;
      state.gen_test_guardian_time = action.payload.consentTime;
    },
    setMinorParentGenTestWitness: (state, action) => {
      state.gen_test_guardian_witness_first_name =
        action.payload.witnessFirstName;
      state.gen_test_guardian_witness_last_name =
        action.payload.witnessLastName;
      state.gen_test_guardian_witness_signature =
        action.payload.witnessSignature;
      state.gen_test_guardian_witness_date = action.payload.consentDate;
      state.gen_test_guardian_witness_time = action.payload.consentTime;
    },
    setMinorParentGenTestPersonObtaining: (state, action) => {
      state.gen_test_guardian_staff_first_name =
        action.payload.personObtainingFirstName;
      state.gen_test_guardian_staff_last_name =
        action.payload.personObtainingLastName;
      state.gen_test_guardian_staff_signature =
        action.payload.personObtainingSignature;
      state.gen_test_guardian_staff_date = action.payload.consentDate;
      state.gen_test_guardian_staff_time = action.payload.consentTime;
    },

    setMinorParticipantGenTest: (state, action) => {
      state.gen_test_participant_consents = action.payload.consents;
      state.gen_test_participant_first_name =
        action.payload.participantFirstName;
      state.gen_test_participant_last_name = action.payload.participantLastName;
      state.gen_test_participant_signature =
        action.payload.participantSignature;
      state.gen_test_participant_date = action.payload.consentDate;
      state.gen_test_participant_time = action.payload.consentTime;
    },
    setMinorParticipantGenTestWitness: (state, action) => {
      state.gen_test_participant_witness_first_name =
        action.payload.witnessFirstName;
      state.gen_test_participant_witness_last_name =
        action.payload.witnessLastName;
      state.gen_test_participant_witness_signature =
        action.payload.witnessSignature;
      state.gen_test_participant_witness_date = action.payload.consentDate;
      state.gen_test_participant_witness_time = action.payload.consentTime;
    },

    setMinorParticipantGenTestPersonObtaining: (state, action) => {
      state.gen_test_participant_staff_first_name =
        action.payload.personObtainingFirstName;
      state.gen_test_participant_staff_last_name =
        action.payload.personObtainingLastName;
      state.gen_test_participant_staff_signature =
        action.payload.personObtainingSignature;
      state.gen_test_participant_staff_date = action.payload.consentDate;
      state.gen_test_participant_staff_time = action.payload.consentTime;
    },

    resetEnrolment: (state) => {
      state.participant_id = null;
      state.enrolment_id = null;
      state.metadata = null;
      state.chosen_language = null;
      state.age_category = null;
      state.created_date = null;
      state.created_time = null;
      state.site_id = null;
      state.screen_times = null;
      state.consent_participant_first_name = null;
      state.consent_participant_last_name = null;
      state.consent_participant_signature = null;
      state.consent_participant_date = null;
      state.consent_participant_time = null;
      state.consent_participant_witness_first_name = null;
      state.consent_participant_witness_last_name = null;
      state.consent_participant_witness_signature = null;
      state.consent_participant_witness_date = null;
      state.consent_participant_witness_time = null;
      state.consent_participant_staff_first_name = null;
      state.consent_participant_staff_last_name = null;
      state.consent_participant_staff_signature = null;
      state.consent_participant_staff_date = null;
      state.consent_participant_staff_time = null;
      state.lng_stor_participant_consent_1 = null;
      state.lng_stor_participant_consent_2 = null;
      state.lng_stor_participant_consent_3 = null;
      state.lng_stor_participant_first_name = null;
      state.lng_stor_participant_last_name = null;
      state.lng_stor_participant_signature = null;
      state.lng_stor_participant_date = null;
      state.lng_stor_participant_time = null;
      state.lng_stor_participant_witness_first_name = null;
      state.lng_stor_participant_witness_last_name = null;
      state.lng_stor_participant_witness_signature = null;
      state.lng_stor_participant_witness_date = null;
      state.lng_stor_participant_witness_time = null;
      state.lng_stor_participant_staff_first_name = null;
      state.lng_stor_participant_staff_last_name = null;
      state.lng_stor_participant_staff_signature = null;
      state.lng_stor_participant_staff_date = null;
      state.lng_stor_participant_staff_time = null;
      state.gen_test_participant_first_name = null;
      state.gen_test_participant_last_name = null;
      state.gen_test_participant_signature = null;
      state.gen_test_participant_date = null;
      state.gen_test_participant_time = null;
      state.gen_test_participant_witness_first_name = null;
      state.gen_test_participant_witness_last_name = null;
      state.gen_test_participant_witness_signature = null;
      state.gen_test_participant_witness_date = null;
      state.gen_test_participant_witness_time = null;
      state.gen_test_participant_staff_first_name = null;
      state.gen_test_participant_staff_last_name = null;
      state.gen_test_participant_staff_signature = null;
      state.gen_test_participant_staff_date = null;
      state.gen_test_participant_staff_time = null;
      state.gen_test_participant_consents = null;
      state.participant_aou_results = null;
      state.guardian_illiterate = null;
      state.consent_guardian_child_first_name = null;
      state.consent_guardian_child_last_name = null;
      state.consent_guardian_first_name = null;
      state.consent_guardian_last_name = null;
      state.consent_guardian_signature = null;
      state.consent_guardian_date = null;
      state.consent_guardian_time = null;
      state.consent_guardian_witness_first_name = null;
      state.consent_guardian_witness_last_name = null;
      state.consent_guardian_witness_signature = null;
      state.consent_guardian_witness_date = null;
      state.consent_guardian_witness_time = null;
      state.consent_guardian_staff_first_name = null;
      state.consent_guardian_staff_last_name = null;
      state.consent_guardian_staff_signature = null;
      state.consent_guardian_staff_date = null;
      state.consent_guardian_staff_time = null;
      state.lng_stor_guardian_consent_1 = null;
      state.lng_stor_guardian_consent_2 = null;
      state.lng_stor_guardian_consent_3 = null;
      state.lng_stor_guardian_child_first_name = null;
      state.lng_stor_guardian_child_last_name = null;
      state.lng_stor_guardian_first_name = null;
      state.lng_stor_guardian_last_name = null;
      state.lng_stor_guardian_signature = null;
      state.lng_stor_guardian_date = null;
      state.lng_stor_guardian_time = null;
      state.lng_stor_guardian_witness_first_name = null;
      state.lng_stor_guardian_witness_last_name = null;
      state.lng_stor_guardian_witness_signature = null;
      state.lng_stor_guardian_witness_date = null;
      state.lng_stor_guardian_witness_time = null;
      state.lng_stor_guardian_staff_first_name = null;
      state.lng_stor_guardian_staff_last_name = null;
      state.lng_stor_guardian_staff_signature = null;
      state.lng_stor_guardian_staff_date = null;
      state.lng_stor_guardian_staff_time = null;
      state.gen_test_guardian_child_first_name = null;
      state.gen_test_guardian_child_last_name = null;
      state.gen_test_guardian_first_name = null;
      state.gen_test_guardian_last_name = null;
      state.gen_test_guardian_signature = null;
      state.gen_test_guardian_date = null;
      state.gen_test_guardian_time = null;
      state.gen_test_guardian_witness_first_name = null;
      state.gen_test_guardian_witness_last_name = null;
      state.gen_test_guardian_witness_signature = null;
      state.gen_test_guardian_witness_date = null;
      state.gen_test_guardian_witness_time = null;
      state.gen_test_guardian_staff_first_name = null;
      state.gen_test_guardian_staff_last_name = null;
      state.gen_test_guardian_staff_signature = null;
      state.gen_test_guardian_staff_date = null;
      state.gen_test_guardian_staff_time = null;
      state.gen_test_guardian_consents = null;
      state.guardian_aou_results = null;
      state.consent_country = null;
      state.guardian_consents = null;
      state.participant_consents = null;
      state.date_reviewed_monitor = null;
    },

    setDateReviewedByMonitor: (state, action) => {
      state.date_reviewed_monitor = action.payload;
    },
  },
});

export const {
  setParticipantId,
  setEnrolmentId,
  setEnrolmentDateTime,
  setMinorParentConsent,
  setMinorParticipantConsent,
  setMinorParentWitness,
  setMinorParentPersonObtaining,
  setMinorParentLongStorage,
  setMinorParentLongStorageWitness,
  setMinorParentLongStoragePersonObtaining,
  setMinorParticipantWitness,
  setMinorParticipantPersonObtaining,
  setMinorParticipantLongStorage,
  setMinorParticipantLongStorageWitness,
  setMinorParticipantLongStoragePersonObtaining,
  resetEnrolment,
  setParticipantWitness,
  setParticipantLongStorageWitness,
  setParticipantPersonObtaining,
  setParticipantLongStoragePersonObtaining,
  setGuardianConsents,
  setParticipantConsents,
  setMinorParentGenTest,
  setMinorParentGenTestWitness,
  setMinorParentGenTestPersonObtaining,
  setMinorParticipantGenTest,
  setMinorParticipantGenTestWitness,
  setMinorParticipantGenTestPersonObtaining,
  setDateReviewedByMonitor,
} = enrolmentSlice.actions;

export default enrolmentSlice.reducer;
