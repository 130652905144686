import React, { useState } from "react";
import styles from "./LineChart.module.css";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";

const LineChart = ({
  chartTitle,
  chartHeight,
  series,
  colors,
  xaxisCategories,
}) => {
  const data = {
    series: [...series],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: colors,
      stroke: {
        curve: "straight",
      },
      legend: {
        position: "bottom",
      },
      xaxis: {
        categories: [...xaxisCategories],
      },
    },
  };

  return (
    <div className={styles.chart}>
      <Chart
        options={data.options}
        series={data.series}
        type="line"
        height={chartHeight}
        width={"100%"}
      />
    </div>
  );
};

LineChart.propTypes = {
  chartTitle: PropTypes.string,
  chartHeight: PropTypes.string,
  series: PropTypes.array,
  colors: PropTypes.array,
  xaxisCategories: PropTypes.array,
};

export default LineChart;
