import { API, graphqlOperation } from "aws-amplify";
import {
  sendSMSOTP,
  createUser,
  deleteUser,
  updateUser,
  addUserGroups,
  removeUserGroups,
} from "../../graphql/mutations";
import { verifyOTP, listUsers, listUserGroups } from "../../graphql/queries";
import { recreateUser } from "../../graphql/mutations";

export const apiSendOTP = async (params) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(sendSMSOTP, { input: params })
    );

    results.body = response.data.sendSMSOTP;
    console.log("apiCreateEnrolment successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiVerifyOTP = async (params) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(verifyOTP, { input: params })
    );

    results.body = response.data.verifyOTP;
    results.success = response.data.verifyOTP.valid;
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetUsers = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(listUsers));

    results.body = response.data.listUsers;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiCreateUser = async (userObj) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(createUser, {
        Users: userObj,
      })
    );

    results.body = response.data.createUser;
    console.log("apiCreateUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = err.errors[0].message;
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiDeleteUser = async (userObj) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(deleteUser, {
        Users: userObj,
      })
    );

    results.body = response.data.deleteUser;
    console.log("apiDeleteUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = err.errors[0].message;
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateUser = async (userObj) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateUser, {
        Users: userObj,
      })
    );

    results.body = response.data.updateUser;
    console.log("apiUpdateUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiResetUser = async (userObj) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(recreateUser, {
        Users: userObj,
      })
    );

    results.body = response.data.recreateUser;
    console.log("apiResetUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetUserGroups = async (userObj) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(listUserGroups, userObj)
    );

    results.body = response.data.listUserGroups;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiAddUserGroups = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(addUserGroups, {
        Groups: data,
      })
    );

    results.body = response.data.addUserGroups;
    console.log("apiResetUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiRemoveUserGroups = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(removeUserGroups, {
        Groups: data,
      })
    );

    results.body = response.data.removeUserGroups;
    console.log("apiResetUser successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};
