import { API, graphqlOperation } from "aws-amplify";
import {
  updateSubStudyICParticipant,
  updateSubStudyICGuardian,
  updateQueryMessages,
} from "../../graphql/mutations";
import { getSubStudyIC, getAllSubStudyIC, getQueryMessages } from "../../graphql/queries";

export const apiUpdateSubStudyICParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyICParticipant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyICParticipant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateSubStudyICGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyICGuardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyICGuardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateQuery = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateQueryMessages, {
        QueryMessages: data,
      })
    );

    results.body = response.data.updateQueryMessages;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetQueries = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getQueryMessages, data)
    );

    results.body = response.data.getQueryMessages;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIC = async (partcipantId, icId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIC, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `IC#${icId}`,
      })
    );

    results.body = response.data.getSubStudyIC;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetAllIC = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getAllSubStudyIC));

    results.body = response.data.getAllSubStudyIC;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};