const enrolmentFormsData = [
  {
    id: 1,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_CONSENT",
    formName: "Consent form for main study.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 2,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_LONG_STORAGE",
    formName: "Consent form for long term storage, genetic and future testing.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 3,
    type: "MINOR_PARENT",
    name: "MINOR_PARENT_AOU",
    formName: "Assessment of understanding test.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 4,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_CONSENT",
    formName: "Assent form for main study.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 5,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_LONG_STORAGE",
    formName: "Assent form for long term storage, genetic and future testing.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 6,
    type: "MINOR_PARTICIPANT",
    name: "MINOR_PARTICIPANT_AOU",
    formName: "Assessment of understanding test.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 7,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_CONSENT",
    formName: "Consent form for main study.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 8,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_LONG_STORAGE",
    formName: "Consent form for long term storage, genetic and future testing.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 9,
    type: "EMANCIPATED_MINOR",
    name: "EMANCIPATED_MINOR_AOU",
    formName: "Assessment of understanding test.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 10,
    type: "ADULT_18_PLUS",
    name: "ADULT_CONSENT",
    formName: "Consent form for main study.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 11,
    type: "ADULT_18_PLUS",
    name: "ADULT_LONG_STORAGE",
    formName: "Consent form for long term storage, genetic and future testing.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
  {
    id: 12,
    type: "ADULT_18_PLUS",
    name: "ADULT_AOU",
    formName: "Assessment of understanding test.",
    isCurrent: false,
    isStarted: false,
    isDone: false,
  },
];

export default enrolmentFormsData;
