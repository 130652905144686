import { createSlice } from "@reduxjs/toolkit";

export const impartialWitnessFormSlice = createSlice({
  name: "impartialWitnessForm",
  initialState: {
    witnessForm: {
      witnessFirstName: "",
      witnessLastName: "",
      witnessSignature: null,
      isSigned: false,
      consentDate: "",
      consentTime: "",
    },
  },
  reducers: {
    resetImpartialWitnessForm: (state) => {
      state.witnessForm = {
        witnessFirstName: "",
        witnessLastName: "",
        witnessSignature: null,
        isSigned: false,
        consentDate: "",
        consentTime: "",
      };
    },
    setImpartialWitnessForm: (state, action) => {
      state.witnessForm = action.payload;
    },
  },
});

export const { setImpartialWitnessForm, resetImpartialWitnessForm } = impartialWitnessFormSlice.actions;

export default impartialWitnessFormSlice.reducer;
