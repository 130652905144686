/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import PreLoader from "../../components/common/PreLoader";
import AppFooter from "../../components/AppFooter/AppFooter";
import { useTranslation } from "react-i18next";
import enrolmentFormsData from "../../data/consent_forms";
import {
  resetSettings,
  setEnrolmentForms,
  setTriggerEvent,
  setLoggedInUser,
  setAppTitle,
} from "../../store/slices/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetImpartialWitnessForm } from "../../store/slices/impartialWitnessFormSlice";
import { resetPersonObtainingForm } from "../../store/slices/personObtainingFormSlice";
import { resetAou } from "../../store/slices/aouSlice";
import { resetEnrolment } from "../../store/slices/enrolmentSlice";
import { Auth } from "aws-amplify";
import Dashboard from "./Dashboard";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import FGDA1Dashboard from "./FGDA1Dashboard";
import FGDA3Dashboard from "./FGDA3Dashboard";
import IDIA1Dashboard from "./IDIA1Dashboard";
import IDIA3Dashboard from "./IDIA3Dashboard";
import KIIA1Dashboard from "./KIIA1Dashboard";
import KIIA3Dashboard from "./KIIA3Dashboard";
import ICDashboard from "./ICDashboard";
import ILDashboard from "./ILDashboard";

const HomePage = () => {
  const [Loading] = useState(false);
  const [t] = useTranslation("common");
  const { isDeviceOnline, loggedInUser } = useSelector(
    (state) => state.settings
  );
  const { offlineEnrolments } = useSelector((state) => state.offlineData);
  const dispatch = useDispatch();
  const [dashboardSelect, setDashboardSelect] = useState("MAIN_STUDY");

  const setCurrentUserDetails = async () => {
    let user = null;
    try {
      user = await Auth.currentAuthenticatedUser();

      const firstName = user.attributes["custom:first_name"];
      const lastName = user.attributes["custom:last_name"];
      const group =
        user.signInUserSession.accessToken.payload["cognito:groups"];
      const siteId = user.attributes["custom:site_id"];
      const locale = user.attributes["locale"];
      const localeSplit = locale.split("_");
      const _currentUserObj = {
        firstName,
        lastName,
        group,
        siteId,
      };

      dispatch(setLoggedInUser(_currentUserObj));
      dispatch(
        setUserLocale({
          userLocale: locale,
          currentLanguage: localeSplit[0],
          currentCountry: localeSplit[1],
        })
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    dispatch(resetSettings());
    dispatch(setEnrolmentForms(enrolmentFormsData));
    dispatch(resetImpartialWitnessForm());
    dispatch(resetPersonObtainingForm());
    dispatch(resetAou());
    dispatch(resetEnrolment());
    dispatch(setTriggerEvent(""));
    dispatch(setAppTitle("MAGY Monitoring - Dashboard"));

    if (isDeviceOnline === true && loggedInUser === null) {
      setCurrentUserDetails();
    }
  }, []);

  const handleChange = (event) => {
    setDashboardSelect(event.target.value);
  };

  return (
    <>
      <AppHeader isGuestMode={false} heading="MAGY Monitoring - Dashboard" />
      <Container maxWidth="md">
        <Select
          id="select-dashboard"
          value={dashboardSelect}
          onChange={handleChange}
          fullWidth
          sx={{ background: "white" }}
        >
          <MenuItem value={"MAIN_STUDY"}>Primary Study Dashboard </MenuItem>
          <MenuItem value={"FGDA1"}>
            Focus Group Discussions - Aim 1 - Dashboard
          </MenuItem>
          <MenuItem value={"FGDA3"}>
            Focus Group Discussions - Aim 3 - Dashboard
          </MenuItem>
          <MenuItem value={"IDIA1"}>
            In-Depth Interview - Aim 1 - Dashboard
          </MenuItem>
          <MenuItem value={"IDIA3"}>
            In-Depth Interview - Aim 3 - Dashboard
          </MenuItem>
          <MenuItem value={"KIIA1"}>
            Key Informant Interview - Aim 1 - Dashboard
          </MenuItem>
          <MenuItem value={"KIIA3"}>
            Key Informant Interview - Aim 3 - Dashboard
          </MenuItem>
          <MenuItem value={"IC"}>Immune Cell Sub Study - Dashboard</MenuItem>
          <MenuItem value={"IL"}>
            Intensive Longitudinal Sub Study - Dashboard
          </MenuItem>
        </Select>
      </Container>

      {dashboardSelect === "MAIN_STUDY" && <Dashboard />}
      {dashboardSelect === "FGDA1" && <FGDA1Dashboard />}
      {dashboardSelect === "FGDA3" && <FGDA3Dashboard />}
      {dashboardSelect === "IDIA1" && <IDIA1Dashboard />}
      {dashboardSelect === "IDIA3" && <IDIA3Dashboard />}
      {dashboardSelect === "KIIA1" && <KIIA1Dashboard />}
      {dashboardSelect === "KIIA3" && <KIIA3Dashboard />}
      {dashboardSelect === "IC" && <ICDashboard />}
      {dashboardSelect === "IL" && <ILDashboard />}

      <AppFooter />
      <PreLoader show={Loading} />
    </>
  );
};

export default HomePage;
