import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  apiGetEnrolmentGuardianAOUResults,
  apiGetEnrolmentParticipantAOUResults,
} from "../../services/api/enrolmentApi.service";
import PreLoader from "../common/PreLoader";
import styles from "./ViewEnrolmentAOU.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";

const ViewEnrolmentAOU = () => {
  const [t] = useTranslation("common");
  const { participant_id, enrolment_id } = useSelector(
    (state) => state.enrolment
  );
  const [Loading, setLoading] = useState(false);
  const [guardianAOU, setGuardianAOU] = useState([]);
  const [participantAOU, setParticipantAOU] = useState([]);
  const consentCountry = localStorage.getItem("i18nextLng").split("_")[1];

  const getGuardianAOUResults = async () => {
    try {
      const guardianAOUResults = await apiGetEnrolmentGuardianAOUResults(
        participant_id,
        enrolment_id,
        consentCountry
      );

      if (guardianAOUResults.success) {
        let data = guardianAOUResults.body.guardian_aou_results;

        if (data !== null) {
          setGuardianAOU(JSON.parse(data));
          console.log(JSON.parse(data));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getParticipantAOUResults = async () => {
    try {
      const participantAOUResults = await apiGetEnrolmentParticipantAOUResults(
        participant_id,
        enrolment_id,
        consentCountry
      );

      if (participantAOUResults.success) {
        let data = participantAOUResults.body.participant_aou_results;
        if (data !== null) {
          setParticipantAOU(JSON.parse(data));
          console.log(JSON.parse(data));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (participant_id !== undefined) {
      setLoading(true);
      getParticipantAOUResults();
      getGuardianAOUResults();
    }
  }, [participant_id]);

  const getAouView = (aouData, title) => (
    <>
      {aouData.length > 0 &&
        aouData.map((aou, index) => (
          <div>
            <h2 className={styles.intro}>
              {index + 1}) {title} Results -{" "}
              {aouData[index]?.noOfCorrectAnswers >= 8 ? "PASSED" : "FAILED"} (
              {`SCORED: ${aouData[index]?.noOfCorrectAnswers}/${aouData[index]?.noOfQuestions}`}
              )
            </h2>

            <div className={styles.consentText}>
              <>
                <p>
                  <strong>Date:</strong> {aouData[index]?.assessmentDate}
                </p>
                <p>
                  <strong>Start Time:</strong> {aouData[index]?.assessmentStartTime}
                </p>
                <p>
                  <strong>End Time:</strong> {aouData[index]?.assessmentEndTime}
                </p>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <strong>Questions and Answers:</strong>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ol>
                      {aouData[index].questions?.map((aouQuestion) => (
                        <li style={{ paddingBottom: "10px" }}>
                          {t(aouQuestion.question)}{" "}
                          <strong>
                            ({aouQuestion.userAnswer ? "True" : "False"}){" "}
                          </strong>
                        </li>
                      ))}
                    </ol>
                  </AccordionDetails>
                </Accordion>

                <p></p>
              </>
            </div>
          </div>
        ))}
    </>
  );

  return (
    <>
      {getAouView(guardianAOU, "PARENT")}
      {getAouView(participantAOU, "PARTICIPANT")}
      <PreLoader show={Loading} />
    </>
  );
};

ViewEnrolmentAOU.propTypes = {
  signature: PropTypes.string,
};

export default ViewEnrolmentAOU;
