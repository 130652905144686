import React, { useState, useEffect } from "react";
import styles from "./LongStorageMinorParentView.module.css";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import SignatureImageView from "../../../../components/SignatureImageView";
import { useTranslation } from "react-i18next";

const LongStorageMinorParentView = () => {
  const [t] = useTranslation("common");
  const isParentIlliterate = useSelector(
    (state) => state.settings.parentIlliterate
  );
  const enrolmentState = useSelector((state) => state.enrolment);
  const [parentConsentObj, setParentConsentObj] = useState({});

  useEffect(() => {
    setParentConsentObj({
      childFirstName: enrolmentState.lng_stor_guardian_child_first_name,
      childLastName: enrolmentState.lng_stor_guardian_child_last_name,
      parentFirstName: enrolmentState.lng_stor_guardian_first_name,
      parentLastName: enrolmentState.lng_stor_guardian_last_name,
      consentDate: enrolmentState.lng_stor_guardian_date,
      consentTime: enrolmentState.lng_stor_guardian_time,
      signature: enrolmentState.lng_stor_guardian_signature,
    });
  }, [enrolmentState]);

  return (
    <div className={styles.pageViewBox}>
      <h2 className={styles.intro}>
        {t("minor_parent_consent_long_storage_title")}
      </h2>

      <div className={styles.consentText}>
        <ol>
          <li>
            {t("minor_parent_lstore_consent_q1")}{" "}
            <strong>({enrolmentState.lng_stor_guardian_consent_1})</strong>
          </li>
          <li>
            {t("minor_parent_lstore_consent_q2")}{" "}
            <strong>({enrolmentState.lng_stor_guardian_consent_2})</strong>
          </li>
          <li>
            {t("minor_parent_lstore_consent_q3")}{" "}
            <strong>({enrolmentState.lng_stor_guardian_consent_3})</strong>
          </li>
        </ol>
        <p>{t("minor_parent_lstore_consent_p1")}</p>
      </div>

      <div className={styles.consentForm}>
        <Box sx={{ mt: 4 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_child_first_name")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_child_last_name")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.childLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_parent_legal_guardian_first_name")} </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentFirstName}
                </div>
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_parent_legal_guardian_last_name")} </label>
                <div className={styles.fieldText}>
                  {parentConsentObj.parentLastName}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>
                  {t("label_parent_legal_guardian_signature")}{" "}
                  {isParentIlliterate ? "acknowledgement" : "Signature"}{" "}
                </label>
                <SignatureImageView signature={parentConsentObj.signature} />
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_date")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentDate}
                </div>
              </FormControl>
              <FormControl sx={{ m: 2, width: "90%" }}>
                <label>{t("label_time")}</label>
                <div className={styles.fieldText}>
                  {parentConsentObj.consentTime}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default LongStorageMinorParentView;
