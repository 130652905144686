import { API, graphqlOperation } from "aws-amplify";
import {
  updateSubStudyILParticipant,
  updateSubStudyILGuardian,
  updateQueryMessages,
} from "../../graphql/mutations";
import { getSubStudyIL, getAllSubStudyIL, getQueryMessages } from "../../graphql/queries";

export const apiUpdateSubStudyILParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyILParticipant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyILParticipant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateSubStudyILGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyILGuardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyILGuardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateQuery = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateQueryMessages, {
        QueryMessages: data,
      })
    );

    results.body = response.data.updateQueryMessages;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetQueries = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getQueryMessages, data)
    );

    results.body = response.data.getQueryMessages;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIL = async (partcipantId, ilId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIL, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `IL#${ilId}`,
      })
    );

    results.body = response.data.getSubStudyIL;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetAllIL = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getAllSubStudyIL));

    results.body = response.data.getAllSubStudyIL;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};