/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant($Participant: inputCreateParticipant) {
    createParticipant(Participant: $Participant)
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant($Participant: inputUpdateParticipant) {
    updateParticipant(Participant: $Participant)
  }
`;
export const createEnrolment = /* GraphQL */ `
  mutation CreateEnrolment($ConsentWithLongStorage: inputCreateEnrolment) {
    createEnrolment(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateEnrolmentParticipant = /* GraphQL */ `
  mutation UpdateEnrolmentParticipant(
    $ConsentWithLongStorage: inputUpdateEnrolmentParticipant
  ) {
    updateEnrolmentParticipant(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateEnrolmentGuardian = /* GraphQL */ `
  mutation UpdateEnrolmentGuardian(
    $ConsentWithLongStorage: inputUpdateEnrolmentGuardian
  ) {
    updateEnrolmentGuardian(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateSubStudyFGDA1Guardian = /* GraphQL */ `
  mutation UpdateSubStudyFGDA1Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyFGDA1Guardian
  ) {
    updateSubStudyFGDA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyFGDA1Participant = /* GraphQL */ `
  mutation UpdateSubStudyFGDA1Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyFGDA1Participant
  ) {
    updateSubStudyFGDA1Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyFGDA3Participant = /* GraphQL */ `
  mutation UpdateSubStudyFGDA3Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyFGDA3Participant
  ) {
    updateSubStudyFGDA3Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyFGDA3Guardian = /* GraphQL */ `
  mutation UpdateSubStudyFGDA3Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyFGDA3Guardian
  ) {
    updateSubStudyFGDA3Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateEnrolmentStatus = /* GraphQL */ `
  mutation UpdateEnrolmentStatus(
    $ConsentWithLongStorage: inputUpdateEnrolmentStatus
  ) {
    updateEnrolmentStatus(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateParticipantStatus = /* GraphQL */ `
  mutation UpdateParticipantStatus(
    $ConsentWithLongStorage: inputUpdateParticipantStatus
  ) {
    updateParticipantStatus(ConsentWithLongStorage: $ConsentWithLongStorage)
  }
`;
export const updateSubStudyILGuardian = /* GraphQL */ `
  mutation UpdateSubStudyILGuardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyILGuardian
  ) {
    updateSubStudyILGuardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyILParticipant = /* GraphQL */ `
  mutation UpdateSubStudyILParticipant(
    $ConsentWithAudioTaping: inputUpdateSubStudyILParticipant
  ) {
    updateSubStudyILParticipant(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyICParticipant = /* GraphQL */ `
  mutation UpdateSubStudyICParticipant(
    $ConsentWithAudioTaping: inputUpdateSubStudyICParticipant
  ) {
    updateSubStudyICParticipant(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyICGuardian = /* GraphQL */ `
  mutation UpdateSubStudyICGuardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyICGuardian
  ) {
    updateSubStudyICGuardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyIDIA1Guardian = /* GraphQL */ `
  mutation UpdateSubStudyIDIA1Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyIDIA1Guardian
  ) {
    updateSubStudyIDIA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyIDIA1Participant = /* GraphQL */ `
  mutation UpdateSubStudyIDIA1Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyIDIA1Participant
  ) {
    updateSubStudyIDIA1Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyIDIA3Participant = /* GraphQL */ `
  mutation UpdateSubStudyIDIA3Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyIDIA3Participant
  ) {
    updateSubStudyIDIA3Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyIDIA3Guardian = /* GraphQL */ `
  mutation UpdateSubStudyIDIA3Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyIDIA3Guardian
  ) {
    updateSubStudyIDIA3Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyKIIA1Guardian = /* GraphQL */ `
  mutation UpdateSubStudyKIIA1Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyKIIA1Guardian
  ) {
    updateSubStudyKIIA1Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyKIIA1Participant = /* GraphQL */ `
  mutation UpdateSubStudyKIIA1Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyKIIA1Participant
  ) {
    updateSubStudyKIIA1Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyKIIA3Participant = /* GraphQL */ `
  mutation UpdateSubStudyKIIA3Participant(
    $ConsentWithAudioTaping: inputUpdateSubStudyKIIA3Participant
  ) {
    updateSubStudyKIIA3Participant(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyKIIA3Guardian = /* GraphQL */ `
  mutation UpdateSubStudyKIIA3Guardian(
    $ConsentWithAudioTaping: inputUpdateSubStudyKIIA3Guardian
  ) {
    updateSubStudyKIIA3Guardian(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const uploadSignature = /* GraphQL */ `
  mutation UploadSignature($input: inputSignature!) {
    uploadSignature(input: $input) {
      presign_url
      presign_fields
      object_path
    }
  }
`;
export const deleteSignature = /* GraphQL */ `
  mutation DeleteSignature($input: inputDeleteSignature!) {
    deleteSignature(input: $input)
  }
`;
export const createSubStudyIL = /* GraphQL */ `
  mutation CreateSubStudyIL($ConsentWithAudioTaping: inputCreateSubStudyIL) {
    createSubStudyIL(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyFGDA1 = /* GraphQL */ `
  mutation CreateSubStudyFGDA1(
    $ConsentWithAudioTaping: inputCreateSubStudyFGDA1
  ) {
    createSubStudyFGDA1(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyFGDA3 = /* GraphQL */ `
  mutation CreateSubStudyFGDA3(
    $ConsentWithAudioTaping: inputCreateSubStudyFGDA3
  ) {
    createSubStudyFGDA3(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyIDIA1 = /* GraphQL */ `
  mutation CreateSubStudyIDIA1(
    $ConsentWithAudioTaping: inputCreateSubStudyIDIA1
  ) {
    createSubStudyIDIA1(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyIDIA3 = /* GraphQL */ `
  mutation CreateSubStudyIDIA3(
    $ConsentWithAudioTaping: inputCreateSubStudyIDIA3
  ) {
    createSubStudyIDIA3(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyKIIA1 = /* GraphQL */ `
  mutation CreateSubStudyKIIA1(
    $ConsentWithAudioTaping: inputCreateSubStudyKIIA1
  ) {
    createSubStudyKIIA1(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyKIIA3 = /* GraphQL */ `
  mutation CreateSubStudyKIIA3(
    $ConsentWithAudioTaping: inputCreateSubStudyKIIA3
  ) {
    createSubStudyKIIA3(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyICST = /* GraphQL */ `
  mutation CreateSubStudyICST(
    $ConsentWithAudioTaping: inputCreateSubStudyICST
  ) {
    createSubStudyICST(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const createSubStudyIC = /* GraphQL */ `
  mutation CreateSubStudyIC($ConsentWithAudioTaping: inputCreateSubStudyIC) {
    createSubStudyIC(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const sendSMSOTP = /* GraphQL */ `
  mutation SendSMSOTP($input: inputSendSMSOTP) {
    sendSMSOTP(input: $input)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($Users: inputCreateUser) {
    createUser(Users: $Users)
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($Users: inputDeleteUser) {
    deleteUser(Users: $Users)
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($Users: inputUpdateUser) {
    updateUser(Users: $Users)
  }
`;
export const addUserGroups = /* GraphQL */ `
  mutation AddUserGroups($Groups: inputUserGroups) {
    addUserGroups(Groups: $Groups)
  }
`;
export const recreateUser = /* GraphQL */ `
  mutation RecreateUser($Users: inputRecreateUser) {
    recreateUser(Users: $Users)
  }
`;
export const removeUserGroups = /* GraphQL */ `
  mutation RemoveUserGroups($Groups: inputUserGroups) {
    removeUserGroups(Groups: $Groups)
  }
`;
export const updateGuardianConsentNames = /* GraphQL */ `
  mutation UpdateGuardianConsentNames(
    $ConsentWithAudioTaping: inputUpdateGuardianConsentNames
  ) {
    updateGuardianConsentNames(ConsentWithAudioTaping: $ConsentWithAudioTaping)
  }
`;
export const updateSubStudyGuardianConsentNames = /* GraphQL */ `
  mutation UpdateSubStudyGuardianConsentNames(
    $ConsentWithAudioTaping: inputUpdateSubStudyGuardianConsentNames
  ) {
    updateSubStudyGuardianConsentNames(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateSubStudyParticipantConsentNames = /* GraphQL */ `
  mutation UpdateSubStudyParticipantConsentNames(
    $ConsentWithAudioTaping: inputUpdateSubStudyParticipantConsentNames
  ) {
    updateSubStudyParticipantConsentNames(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateParticipantConsentNames = /* GraphQL */ `
  mutation UpdateParticipantConsentNames(
    $ConsentWithAudioTaping: inputUpdateParticipantConsentNames
  ) {
    updateParticipantConsentNames(
      ConsentWithAudioTaping: $ConsentWithAudioTaping
    )
  }
`;
export const updateAuditInformation = /* GraphQL */ `
  mutation UpdateAuditInformation(
    $AuditInformation: inputUpdateAuditInformation
  ) {
    updateAuditInformation(AuditInformation: $AuditInformation)
  }
`;
export const updateQueryMessages = /* GraphQL */ `
  mutation UpdateQueryMessages($QueryMessages: inputUpdateQueryMessages) {
    updateQueryMessages(QueryMessages: $QueryMessages)
  }
`;
export const updateGuardianICFInformation = /* GraphQL */ `
  mutation UpdateGuardianICFInformation(
    $ConsentedParticipantReport: inputUpdateGuardianICFInformation
  ) {
    updateGuardianICFInformation(
      ConsentedParticipantReport: $ConsentedParticipantReport
    )
  }
`;
export const updateParticipantICFInformation = /* GraphQL */ `
  mutation UpdateParticipantICFInformation(
    $ConsentedParticipantReport: inputUpdateParticipantICFInformation
  ) {
    updateParticipantICFInformation(
      ConsentedParticipantReport: $ConsentedParticipantReport
    )
  }
`;
export const updateDateReviewedByMonitor = /* GraphQL */ `
  mutation UpdateDateReviewedByMonitor(
    $ConsentedParticipantReport: inputUpdateDateReviewedByMonitor
  ) {
    updateDateReviewedByMonitor(
      ConsentedParticipantReport: $ConsentedParticipantReport
    )
  }
`;
export const updateQueryStatus = /* GraphQL */ `
  mutation UpdateQueryStatus(
    $ConsentedParticipantReport: inputUpdateQueryStatus
  ) {
    updateQueryStatus(ConsentedParticipantReport: $ConsentedParticipantReport)
  }
`;
