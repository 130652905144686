import React, { useEffect, useMemo } from "react";
import styles from "./StepFinalReview.module.css";
import { useSelector, useDispatch } from "react-redux";
import AppHeader from "../../../components/AppHeader/AppHeader";
import ConsentMinorParentView from "../StepReview/ConsentMinorParentView";
import LongStorageMinorParentView from "../StepReview/LongStorageMinorParentView";
import ConsentMinorParticipantView from "../StepReview/ConsentMinorParticipantView";
import LongStorageMinorParticipantView from "../StepReview/LongStorageMinorParticipantView";
import ConsentAdultParticipantView from "../StepReview/ConsentAdultParticipantView";
import LongStorageAdultParticipantView from "../StepReview/LongStorageAdultParticipantView";
import ConsentImpartialWitnessView from "../StepReview/ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "../StepReview/ConsentPersonObtainingView";
import { Container } from "@mui/material";
import {
  setAppTitle,
  setRecordStatus,
} from "../../../store/slices/settingsSlice";
import Button from "@mui/material/Button";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import ConsentMinorParentIntro from "../StepIntroduction/ConsentMinorParentIntro";
import ConsentAdultParticipantIntro from "../StepIntroduction/ConsentAdultParticipantIntro";
import LongStorageAdultParticipantIntro from "../StepIntroduction/LongStorageAdultParticipantIntro";
import LongStorageMinorParentIntro from "../StepIntroduction/LongStorageMinorParentIntro";
import ConsentMinorParticipantIntro from "../StepIntroduction/ConsentMinorParticipantIntro";
import LongStorageMinorParticipantIntro from "../StepIntroduction/LongStorageMinorParticipantIntro";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import GenTestMinorParentIntro from "../StepIntroduction/GenTestMinorParentIntro";
import GenTestMinorParentView from "../StepReview/GenTestMinorParentView";
import GenTestMinorParticipantIntro from "../StepIntroduction/GenTestMinorParticipantIntro";
import GenTestMinorParticipantView from "../StepReview/GenTestMinorParticipantView";
import GenTestAdultParticipantIntro from "../StepIntroduction/GenTestAdultParticipantIntro";
import GenTestAdultParticipantView from "../StepReview/GenTestAdultParticipantView";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import ViewEnrolmentAOU from "../../../components/ViewEnrolmentAOU";
import EditConsentNames from "../../../components/EditConsentNames";
import ViewAuditTrail from "../../../components/ViewAuditTrail";
import EnrolmentQueries from "../../../components/EnrolmentQueries";
import { apiUpdateDateReviewedByMonitor } from "../../../services/api/enrolmentApi.service";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StepFinalReview = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { ageCat, parentIlliterate, participantIlliterate, loggedInUser } =
    useSelector((state) => state.settings);

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const {
    consent_participant_witness_first_name,
    consent_participant_witness_last_name,
    consent_participant_witness_signature,
    consent_participant_witness_date,
    consent_participant_witness_time,
    lng_stor_participant_witness_first_name,
    lng_stor_participant_witness_last_name,
    lng_stor_participant_witness_signature,
    lng_stor_participant_witness_date,
    lng_stor_participant_witness_time,
    consent_guardian_witness_first_name,
    consent_guardian_witness_last_name,
    consent_guardian_witness_signature,
    consent_guardian_witness_date,
    consent_guardian_witness_time,
    lng_stor_guardian_witness_first_name,
    lng_stor_guardian_witness_last_name,
    lng_stor_guardian_witness_signature,
    lng_stor_guardian_witness_date,
    lng_stor_guardian_witness_time,
    consent_guardian_staff_first_name,
    consent_guardian_staff_last_name,
    consent_guardian_staff_signature,
    consent_guardian_staff_date,
    consent_guardian_staff_time,
    lng_stor_guardian_staff_first_name,
    lng_stor_guardian_staff_last_name,
    lng_stor_guardian_staff_signature,
    lng_stor_guardian_staff_date,
    lng_stor_guardian_staff_time,
    consent_participant_staff_first_name,
    consent_participant_staff_last_name,
    consent_participant_staff_signature,
    consent_participant_staff_date,
    consent_participant_staff_time,
    lng_stor_participant_staff_first_name,
    lng_stor_participant_staff_last_name,
    lng_stor_participant_staff_signature,
    lng_stor_participant_staff_date,
    lng_stor_participant_staff_time,
    date_reviewed_monitor,
    participant_id,
    enrolment_id,
  } = useSelector((state) => state.enrolment);

  const minorGuardianConsentWitness = {
    witnessFirstName: consent_guardian_witness_first_name,
    witnessLastName: consent_guardian_witness_last_name,
    witnessSignature: consent_guardian_witness_signature,
    consentDate: consent_guardian_witness_date,
    consentTime: consent_guardian_witness_time,
  };

  const minorGuardianConsentStaff = {
    personObtainingFirstName: consent_guardian_staff_first_name,
    personObtainingLastName: consent_guardian_staff_last_name,
    personObtainingSignature: consent_guardian_staff_signature,
    consentDate: consent_guardian_staff_date,
    consentTime: consent_guardian_staff_time,
  };

  const minorGuardianLngStorageWitness = {
    witnessFirstName: lng_stor_guardian_witness_first_name,
    witnessLastName: lng_stor_guardian_witness_last_name,
    witnessSignature: lng_stor_guardian_witness_signature,
    consentDate: lng_stor_guardian_witness_date,
    consentTime: lng_stor_guardian_witness_time,
  };

  const minorGuardianLngStorageStaff = {
    personObtainingFirstName: lng_stor_guardian_staff_first_name,
    personObtainingLastName: lng_stor_guardian_staff_last_name,
    personObtainingSignature: lng_stor_guardian_staff_signature,
    consentDate: lng_stor_guardian_staff_date,
    consentTime: lng_stor_guardian_staff_time,
  };

  //sss
  const participantConsentWitness = {
    witnessFirstName: consent_participant_witness_first_name,
    witnessLastName: consent_participant_witness_last_name,
    witnessSignature: consent_participant_witness_signature,
    consentDate: consent_participant_witness_date,
    consentTime: consent_participant_witness_time,
  };

  const participantConsentStaff = {
    personObtainingFirstName: consent_participant_staff_first_name,
    personObtainingLastName: consent_participant_staff_last_name,
    personObtainingSignature: consent_participant_staff_signature,
    consentDate: consent_participant_staff_date,
    consentTime: consent_participant_staff_time,
  };

  const participantLngStorageWitness = {
    witnessFirstName: lng_stor_participant_witness_first_name,
    witnessLastName: lng_stor_participant_witness_last_name,
    witnessSignature: lng_stor_participant_witness_signature,
    consentDate: lng_stor_participant_witness_date,
    consentTime: lng_stor_participant_witness_time,
  };

  const participantLngStorageStaff = {
    personObtainingFirstName: lng_stor_participant_staff_first_name,
    personObtainingLastName: lng_stor_participant_staff_last_name,
    personObtainingSignature: lng_stor_participant_staff_signature,
    consentDate: lng_stor_participant_staff_date,
    consentTime: lng_stor_participant_staff_time,
  };

  const handleSubmit = async () => {
    dispatch(setRecordStatus("PASSED"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_PASSED"));
  };

  const btnSubmit = (isDisabled) => {
    return (
      <Button
        disabled={isDisabled}
        variant="contained"
        sx={{ minWidth: "300px" }}
        onClick={handleSubmit}
      >
        {t("label_submit")}
      </Button>
    );
  };

  useEffect(() => {
    dispatch(setAppTitle("PRIMARY STUDY"));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (loggedInUser?.group.indexOf("Monitor") > -1) {
      const result = apiUpdateDateReviewedByMonitor({
        participant_id: participant_id,
        SK: `ENROLMENT#${enrolment_id}`,
        consent_country: localStorage.getItem("i18nextLng").split("_")[1],
        date_reviewed_monitor: new Date().toISOString(),
        review_status: "",
      });
    }
  }, [loggedInUser]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppHeader
        isGuestMode={false}
        isPrintPreviewMode={true}
        heading={"PRIMARY STUDY"}
      />
      <Container maxWidth="md">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Consent forms" {...a11yProps(0)} />
            <Tab label="Assessment Of Understanding" {...a11yProps(1)} />
            <Tab label="Audit Trail" {...a11yProps(2)} />
            <Tab label="Queries" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EditConsentNames ageCat={ageCat} />

          {ageCat === 1 && (
            <>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <ConsentMinorParentIntro printMode={true} />{" "}
                  <div className="pagebreak"> </div>
                </div>
                <ConsentMinorParentView />
                {parentIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARENT_CONSENT"}
                    impartialWitnessObj={minorGuardianConsentWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={minorGuardianConsentStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <div className="pagebreak"> </div>
                  <LongStorageMinorParentIntro printMode={true} />{" "}
                </div>
                <div className="pagebreak"> </div>

                <LongStorageMinorParentView />
                {parentIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARENT_LONG_STORAGE"}
                    impartialWitnessObj={minorGuardianLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={minorGuardianLngStorageStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <div className="pagebreak"> </div>
                  <GenTestMinorParentIntro printMode={true} />{" "}
                </div>
                <div className="pagebreak"> </div>

                <GenTestMinorParentView />
                {parentIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARENT__GEN_TEST"}
                    impartialWitnessObj={minorGuardianLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={minorGuardianLngStorageStaff}
                />
              </div>

              <div className={styles.pageBox}>
                <div className="printOnly">
                  <div className="pagebreak"> </div>

                  <ConsentMinorParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>

                <ConsentMinorParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARTICIPANT_CONSENT"}
                    impartialWitnessObj={participantConsentWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantConsentStaff}
                />
              </div>

              <div className={styles.pageBox}>
                <div className="pagebreak"> </div>
                <div className="printOnly">
                  <LongStorageMinorParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>

                <LongStorageMinorParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARTICIPANT_LONG_STORAGE"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="pagebreak"> </div>
                <div className="printOnly">
                  <GenTestMinorParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>

                <GenTestMinorParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"MINOR_PARTICIPANT_GEN_TEST"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
            </>
          )}

          {ageCat === 2 && (
            <>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <ConsentAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <ConsentAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"EMANCIPATED_MINOR_CONSENT"}
                    impartialWitnessObj={participantConsentWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantConsentStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="pagebreak"> </div>
                <div className="printOnly">
                  <LongStorageAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <LongStorageAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"EMANCIPATED_MINOR_LONG_STORAGE"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="pagebreak"> </div>
                <div className="printOnly">
                  <GenTestAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <GenTestAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"EMANCIPATED_MINOR_GEN_TEST"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
            </>
          )}

          {ageCat === 3 && (
            <>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <ConsentAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <ConsentAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"ADULT_CONSENT"}
                    impartialWitnessObj={participantConsentWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantConsentStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="printOnly">
                  <div className="pagebreak"> </div>
                  <LongStorageAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <LongStorageAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"ADULT_LONG_STORAGE"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
              <div className={styles.pageBox}>
                <div className="pagebreak"> </div>
                <div className="printOnly">
                  <GenTestAdultParticipantIntro printMode={true} />
                </div>
                <div className="pagebreak"> </div>
                <GenTestAdultParticipantView />
                {participantIlliterate && (
                  <ConsentImpartialWitnessView
                    pageName={"EMANCIPATED_MINOR_GEN_TEST"}
                    impartialWitnessObj={participantLngStorageWitness}
                  />
                )}
                <ConsentPersonObtainingView
                  personObtainingObj={participantLngStorageStaff}
                />
              </div>
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className={styles.pageBox}>
            <ViewEnrolmentAOU />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div className={styles.pageBox}>
            <ViewAuditTrail SK="ENROLMENT" />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <div>
            <EnrolmentQueries />
          </div>
        </CustomTabPanel>
      </Container>
    </>
  );
};

export default StepFinalReview;
