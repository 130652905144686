import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const SideMenuItem = ({ toggleDrawer, icon, url, key, text }) => {
  const history = useHistory();

  const buttonClicked = (url) => {
    toggleDrawer();
    history.push(url);
  };

  return (
    <ListItem key={key} disablePadding>
      <ListItemButton
        onClick={() => {
          buttonClicked(url);
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
};

SideMenuItem.propTypes = {
  toggleDrawer: PropTypes.func,
  icon: PropTypes.any,
  url: PropTypes.string,
  key: PropTypes.string,
  text: PropTypes.string,
};

export default SideMenuItem;
