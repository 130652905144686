import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import PreLoader from "../common/PreLoader";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import EditConsentNamesForm from "./EditConsentNamesForm";
import { getUserGroups } from "../../services/users.service";

const EditConsentNames = ({ ageCat }) => {
  const [t] = useTranslation("common");
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = useState(false);
  const [showEditNames, setShowEditNames] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkUserPermissions = async () => {
    const userRoles = await getUserGroups();
    setShowEditNames(userRoles.indexOf("SiteUsers") > -1);
  };

  useEffect(() => {
    checkUserPermissions();
  }, []);

  return (
    <>
      {showEditNames && (
        <div>
          <Button
            variant="contained"
            onClick={handleClickOpen}
            size="small"
            sx={{ mr: 2, float: "right" }}
            color="primary"
          >
            <EditOutlinedIcon />
            <span style={{ marginLeft: "8px", marginRight: "8px" }}>
              Edit Names
            </span>
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            fullWidth="md"
          >
            <DialogTitle>Edit Consent Form Names</DialogTitle>
            <DialogContent>
              <EditConsentNamesForm handleClose={handleClose} />
            </DialogContent>
          </Dialog>
        </div>
      )}
      <PreLoader show={Loading} />
    </>
  );
};

EditConsentNames.propTypes = {
  ageCat: PropTypes.string,
};

export default EditConsentNames;
