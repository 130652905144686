import { createSlice } from "@reduxjs/toolkit";

export const offlineDataSlice = createSlice({
  name: "offlineData",
  initialState: { offlineEnrolments: [] },
  reducers: {
    addOfflineEnrolment: (state, action) => {
      state.ageCat = action.payload;
    },
    deleteOfflineEnrolments: (state) => {
      state.offlineEnrolments = [];
    },
    setOfflineEnrolments: (state, action) => {
      state.offlineEnrolments = action.payload;
    },
  },
});

export const {
  addOfflineEnrolment,
  deleteOfflineEnrolments,
  setOfflineEnrolments,
} = offlineDataSlice.actions;

export default offlineDataSlice.reducer;
