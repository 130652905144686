import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PreLoader from "../../common/PreLoader";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {
  apiUpdateGuardianConsentNames,
  apiUpdateParticipantConsentNames,
} from "../../../services/api/enrolmentApi.service";
import { Save } from "@mui/icons-material";

const EditConsentNamesForm = ({ handleClose }) => {
  const [t] = useTranslation("common");
  const [Loading, setLoading] = useState(false);
  const history = useHistory();
  const {
    enrolment_id,
    participant_id,
    consent_guardian_child_first_name,
    consent_guardian_child_last_name,
    consent_guardian_first_name,
    consent_guardian_last_name,
    consent_guardian_witness_first_name,
    consent_guardian_witness_last_name,
    lng_stor_guardian_witness_first_name,
    lng_stor_guardian_witness_last_name,
    gen_test_guardian_witness_first_name,
    gen_test_guardian_witness_last_name,

    consent_participant_first_name,
    consent_participant_last_name,
    consent_participant_witness_first_name,
    consent_participant_witness_last_name,
    lng_stor_participant_witness_first_name,
    lng_stor_participant_witness_last_name,
    gen_test_participant_witness_first_name,
    gen_test_participant_witness_last_name,
  } = useSelector((state) => state.enrolment);

  const { parentIlliterate, participantIlliterate, ageCat } = useSelector(
    (state) => state.settings
  );

  const validationSchema = yup.object({
    guardianWitnessFirstName: yup
      .string(t("label_enter_child_first_name"))
      .required(t("label_child_first_name_required")),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      childFirstName: consent_guardian_child_first_name,
      childLastName: consent_guardian_child_last_name,
      guardianFirstName: consent_guardian_first_name,
      guardianLastName: consent_guardian_last_name,
      guardianWitnessFirstName: parentIlliterate
        ? consent_guardian_witness_first_name
        : "null",
      guardianWitnessLastName: parentIlliterate
        ? consent_guardian_witness_last_name
        : "null",
      lngStorGuardianWitnessFirstName: parentIlliterate
        ? lng_stor_guardian_witness_first_name
        : "null",
      lngStorGuardianWitnessLastName: parentIlliterate
        ? lng_stor_guardian_witness_last_name
        : "null",
      genTestGuardianWitnessFirstName: parentIlliterate
        ? gen_test_guardian_witness_first_name
        : "null",
      genTestGuardianWitnessLastName: parentIlliterate
        ? gen_test_guardian_witness_last_name
        : "null",

      participantFirstName: consent_participant_first_name,
      participantLastName: consent_participant_last_name,
      participantWitnessFirstName: participantIlliterate
        ? consent_participant_witness_first_name
        : "null",
      participantWitnessLastName: participantIlliterate
        ? consent_participant_witness_last_name
        : "null",
      lngStorParticipantWitnessFirstName: participantIlliterate
        ? lng_stor_participant_witness_first_name
        : "null",
      lngStorParticipantWitnessLastName: participantIlliterate
        ? lng_stor_participant_witness_last_name
        : "null",
      genTestParticipantWitnessFirstName: participantIlliterate
        ? gen_test_participant_witness_first_name
        : "null",
      genTestParticipantWitnessLastName: participantIlliterate
        ? gen_test_participant_witness_last_name
        : "null",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      let errors = {};
      console.log(ageCat);
      if (ageCat === 1) {
        if (values.childFirstName === "") {
          errors.childFirstName = true;
        }

        if (values.childLastName === "") {
          errors.childLastName = true;
        }

        if (values.guardianFirstName === "") {
          errors.guardianFirstName = true;
        }

        if (values.guardianLastName === "") {
          errors.guardianLastName = true;
        }

        if (parentIlliterate && values.guardianWitnessFirstName === "") {
          errors.guardianWitnessFirstName = true;
        }

        if (parentIlliterate && values.guardianWitnessLastName === "") {
          errors.guardianWitnessLastName = true;
        }

        if (parentIlliterate && values.lngStorGuardianWitnessFirstName === "") {
          errors.lngStorGuardianWitnessFirstName = true;
        }

        if (parentIlliterate && values.lngStorGuardianWitnessLastName === "") {
          errors.lngStorGuardianWitnessLastName = true;
        }

        if (parentIlliterate && values.genTestGuardianWitnessFirstName === "") {
          errors.genTestGuardianWitnessFirstName = true;
        }

        if (parentIlliterate && values.genTestGuardianWitnessLastName === "") {
          errors.genTestGuardianWitnessLastName = true;
        }
      }

      if (values.participantFirstName === "") {
        errors.participantFirstName = true;
      }

      if (values.participantLastName === "") {
        errors.participantLastName = true;
      }

      if (participantIlliterate && values.participantWitnessFirstName === "") {
        errors.participantWitnessFirstName = true;
      }

      if (participantIlliterate && values.participantWitnessLastName === "") {
        errors.participantWitnessLastName = true;
      }

      if (
        participantIlliterate &&
        values.lngStorParticipantWitnessFirstName === ""
      ) {
        errors.lngStorParticipantWitnessFirstName = true;
      }

      if (
        participantIlliterate &&
        values.lngStorParticipantWitnessLastName === ""
      ) {
        errors.lngStorParticipantWitnessLastName = true;
      }

      if (
        participantIlliterate &&
        values.genTestParticipantWitnessFirstName === ""
      ) {
        errors.genTestParticipantWitnessFirstName = true;
      }

      if (
        participantIlliterate &&
        values.genTestParticipantWitnessLastName === ""
      ) {
        errors.genTestParticipantWitnessLastName = true;
      }

      if (JSON.stringify(errors) === "{}") {
        //handleSave(values);
        console.log("errors----", errors, formik.status);
      }

      return errors;
    },
    onSubmit: (values) => {
      console.log(values);
      handleSave(values);
    },
  });

  const handleSave = async (values) => {
    const _consentCountry = localStorage.getItem("i18nextLng").split("_")[1];
    setLoading(true);
    const guardianResults = await apiUpdateGuardianConsentNames({
      participant_id: `PARTICIPANT#${participant_id}`,
      SK: `ENROLMENT#${enrolment_id}`,
      consent_guardian_first_name: values.guardianFirstName,
      consent_guardian_last_name: values.guardianLastName,
      consent_guardian_child_first_name: values.childFirstName,
      consent_guardian_child_last_name: values.childLastName,
      consent_guardian_witness_first_name: parentIlliterate
        ? values.guardianWitnessFirstName
        : "",
      consent_guardian_witness_last_name: parentIlliterate
        ? values.guardianWitnessLastName
        : "",
      lng_stor_guardian_child_first_name: values.childFirstName,
      lng_stor_guardian_child_last_name: values.childLastName,
      lng_stor_guardian_first_name: values.guardianFirstName,
      lng_stor_guardian_last_name: values.guardianLastName,
      lng_stor_guardian_witness_first_name: parentIlliterate
        ? values.lngStorGuardianWitnessFirstName
        : "",
      lng_stor_guardian_witness_last_name: parentIlliterate
        ? values.lngStorGuardianWitnessLastName
        : "",
      gen_test_guardian_child_first_name: values.childFirstName,
      gen_test_guardian_child_last_name: values.childLastName,
      gen_test_guardian_first_name: values.guardianFirstName,
      gen_test_guardian_last_name: values.guardianLastName,
      gen_test_guardian_witness_first_name: parentIlliterate
        ? values.genTestGuardianWitnessFirstName
        : "",
      gen_test_guardian_witness_last_name: parentIlliterate
        ? values.genTestGuardianWitnessLastName
        : "",
      consent_country: _consentCountry,
    });

    const participantResults = await apiUpdateParticipantConsentNames({
      participant_id: `PARTICIPANT#${participant_id}`,
      SK: `ENROLMENT#${enrolment_id}`,
      consent_participant_first_name: values.participantFirstName,
      consent_participant_last_name: values.participantLastName,
      consent_participant_witness_first_name:
        values.participantWitnessFirstName,
      consent_participant_witness_last_name: values.participantWitnessLastName,
      lng_stor_participant_first_name: values.participantFirstName,
      lng_stor_participant_last_name: values.participantLastName,
      lng_stor_participant_witness_first_name:
        values.lngStorParticipantWitnessFirstName,
      lng_stor_participant_witness_last_name:
        values.lngStorParticipantWitnessLastName,
      gen_test_participant_first_name: values.participantFirstName,
      gen_test_participant_last_name: values.participantLastName,
      gen_test_participant_witness_first_name:
        values.genTestParticipantWitnessFirstName,
      gen_test_participant_witness_last_name:
        values.genTestParticipantWitnessLastName,
      consent_country: _consentCountry,
    });

    setLoading(false);
    if (guardianResults.success && participantResults.success) {
      history.push("/primary-study");
    }
  };

  return (
    <>
      <div>
        <form style={{ marginTop: "0px" }}>
          {ageCat === 1 && (
            <>
              <Box sx={{ mt: 0 }}>
                <h3>Parent Main Consent</h3>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_child_first_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="childFirstName"
                        name="childFirstName"
                        placeholder=""
                        value={formik.values.childFirstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.childFirstName &&
                          Boolean(formik.errors.childFirstName)
                        }
                        helperText={
                          formik.touched.childFirstName &&
                          formik.errors.childFirstName
                        }
                      />
                      {formik.errors.childFirstName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_child_last_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="childLastName"
                        name="childLastName"
                        placeholder=""
                        value={formik.values.childLastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.childLastName &&
                          Boolean(formik.errors.childLastName)
                        }
                        helperText={
                          formik.touched.childLastName &&
                          formik.errors.childLastName
                        }
                      />
                      {formik.errors.childLastName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_parent_legal_guardian_first_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="guardianFirstName"
                        name="guardianFirstName"
                        placeholder=""
                        value={formik.values.guardianFirstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.guardianFirstName &&
                          Boolean(formik.errors.guardianFirstName)
                        }
                        helperText={
                          formik.touched.guardianFirstName &&
                          formik.errors.guardianFirstName
                        }
                      />
                      {formik.errors.guardianFirstName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_parent_legal_guardian_last_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="guardianLastName"
                        name="guardianLastName"
                        placeholder=""
                        value={formik.values.guardianLastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.guardianLastName &&
                          Boolean(formik.errors.guardianLastName)
                        }
                        helperText={
                          formik.touched.guardianLastName &&
                          formik.errors.guardianLastName
                        }
                      />
                      {formik.errors.guardianLastName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  {parentIlliterate && (
                    <>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_first_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="guardianWitnessFirstName"
                            name="guardianWitnessFirstName"
                            placeholder=""
                            value={formik.values.guardianWitnessFirstName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.guardianWitnessFirstName &&
                              Boolean(formik.errors.guardianWitnessFirstName)
                            }
                            helperText={
                              formik.touched.guardianWitnessFirstName &&
                              formik.errors.guardianWitnessFirstName
                            }
                          />
                          {formik.errors.guardianWitnessFirstName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_last_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="guardianWitnessLastName"
                            name="guardianWitnessLastName"
                            placeholder=""
                            value={formik.values.guardianWitnessLastName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.guardianWitnessLastName &&
                              Boolean(formik.errors.guardianWitnessLastName)
                            }
                            helperText={
                              formik.touched.guardianWitnessLastName &&
                              formik.errors.guardianWitnessLastName
                            }
                          />
                          {formik.errors.guardianWitnessLastName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>

              {parentIlliterate && (
                <>
                  <Box sx={{ mt: 1 }}>
                    <h3>Parent Long Storage Witness</h3>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_first_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="lngStorGuardianWitnessFirstName"
                            name="lngStorGuardianWitnessFirstName"
                            placeholder=""
                            disabled={!parentIlliterate}
                            value={
                              formik.values.lngStorGuardianWitnessFirstName
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.touched.lngStorGuardianWitnessFirstName &&
                              Boolean(
                                formik.errors.lngStorGuardianWitnessFirstName
                              )
                            }
                            helperText={
                              formik.touched.lngStorGuardianWitnessFirstName &&
                              formik.errors.lngStorGuardianWitnessFirstName
                            }
                          />
                          {formik.errors.lngStorGuardianWitnessFirstName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_last_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="lngStorGuardianWitnessLastName"
                            name="lngStorGuardianWitnessLastName"
                            placeholder=""
                            value={formik.values.lngStorGuardianWitnessLastName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.lngStorGuardianWitnessLastName &&
                              Boolean(
                                formik.errors.lngStorGuardianWitnessLastName
                              )
                            }
                            helperText={
                              formik.touched.lngStorGuardianWitnessLastName &&
                              formik.errors.lngStorGuardianWitnessLastName
                            }
                          />
                          {formik.errors.lngStorGuardianWitnessLastName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ mt: 1 }}>
                    <h3>Parent Genetic Testing Witness</h3>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_first_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="genTestGuardianWitnessFirstName"
                            name="genTestGuardianWitnessFirstName"
                            placeholder=""
                            value={
                              formik.values.genTestGuardianWitnessFirstName
                            }
                            onChange={formik.handleChange}
                            error={
                              formik.touched.genTestGuardianWitnessFirstName &&
                              Boolean(
                                formik.errors.genTestGuardianWitnessFirstName
                              )
                            }
                            helperText={
                              formik.touched.genTestGuardianWitnessFirstName &&
                              formik.errors.genTestGuardianWitnessFirstName
                            }
                          />
                          {formik.errors.genTestGuardianWitnessFirstName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid xs={6}>
                        <FormControl
                          sx={{ m: 2, width: "95%" }}
                          variant="filled"
                        >
                          <label>
                            {t("label_impartial_witness_last_name")}{" "}
                            <span className="redStar">*</span>
                          </label>
                          <TextField
                            margin="normal"
                            fullWidth
                            id="genTestGuardianWitnessLastName"
                            name="genTestGuardianWitnessLastName"
                            placeholder=""
                            value={formik.values.genTestGuardianWitnessLastName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.genTestGuardianWitnessLastName &&
                              Boolean(
                                formik.errors.genTestGuardianWitnessLastName
                              )
                            }
                            helperText={
                              formik.touched.genTestGuardianWitnessLastName &&
                              formik.errors.genTestGuardianWitnessLastName
                            }
                          />
                          {formik.errors.genTestGuardianWitnessLastName && (
                            <span className="inputError">Requird</span>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </>
          )}
          {/*  */}

          <Box sx={{ mt: 0 }}>
            <h3>Participant Main Consent</h3>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                  <label>
                    {t("label_participant_first_name")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="participantFirstName"
                    name="participantFirstName"
                    placeholder=""
                    value={formik.values.participantFirstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.participantFirstName &&
                      Boolean(formik.errors.participantFirstName)
                    }
                    helperText={
                      formik.touched.participantFirstName &&
                      formik.errors.participantFirstName
                    }
                  />
                  {formik.errors.participantFirstName && (
                    <span className="inputError">Requird</span>
                  )}
                </FormControl>
              </Grid>
              <Grid xs={6}>
                <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                  <label>
                    {t("label_participant_last_name")}{" "}
                    <span className="redStar">*</span>
                  </label>
                  <TextField
                    margin="normal"
                    fullWidth
                    id="participantLastName"
                    name="participantLastName"
                    placeholder=""
                    value={formik.values.participantLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.participantLastName &&
                      Boolean(formik.errors.participantLastName)
                    }
                    helperText={
                      formik.touched.participantLastName &&
                      formik.errors.participantLastName
                    }
                  />
                  {formik.errors.participantLastName && (
                    <span className="inputError">Requird</span>
                  )}
                </FormControl>
              </Grid>
              {participantIlliterate && (
                <>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_first_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="participantWitnessFirstName"
                        name="participantWitnessFirstName"
                        placeholder=""
                        value={formik.values.participantWitnessFirstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.participantWitnessFirstName &&
                          Boolean(formik.errors.participantWitnessFirstName)
                        }
                        helperText={
                          formik.touched.participantWitnessFirstName &&
                          formik.errors.participantWitnessFirstName
                        }
                      />
                      {formik.errors.participantWitnessFirstName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_last_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="participantWitnessLastName"
                        name="participantWitnessLastName"
                        placeholder=""
                        value={formik.values.participantWitnessLastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.participantWitnessLastName &&
                          Boolean(formik.errors.participantWitnessLastName)
                        }
                        helperText={
                          formik.touched.participantWitnessLastName &&
                          formik.errors.participantWitnessLastName
                        }
                      />
                      {formik.errors.participantWitnessLastName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>

          {participantIlliterate && (
            <>
              <Box sx={{ mt: 1 }}>
                <h3>Participant Long Storage Witness</h3>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_first_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="lngStorParticipantWitnessFirstName"
                        name="lngStorParticipantWitnessFirstName"
                        placeholder=""
                        value={formik.values.lngStorParticipantWitnessFirstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.lngStorParticipantWitnessFirstName &&
                          Boolean(
                            formik.errors.lngStorParticipantWitnessFirstName
                          )
                        }
                        helperText={
                          formik.touched.lngStorParticipantWitnessFirstName &&
                          formik.errors.lngStorParticipantWitnessFirstName
                        }
                      />
                      {formik.errors.lngStorParticipantWitnessFirstName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_last_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="lngStorParticipantWitnessLastName"
                        name="lngStorParticipantWitnessLastName"
                        placeholder=""
                        value={formik.values.lngStorParticipantWitnessLastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.lngStorParticipantWitnessLastName &&
                          Boolean(
                            formik.errors.lngStorParticipantWitnessLastName
                          )
                        }
                        helperText={
                          formik.touched.lngStorParticipantWitnessLastName &&
                          formik.errors.lngStorParticipantWitnessLastName
                        }
                      />
                      {formik.errors.lngStorParticipantWitnessLastName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ mt: 1 }}>
                <h3>Participant Genetic Testing Witness</h3>

                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_first_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="genTestParticipantWitnessFirstName"
                        name="genTestParticipantWitnessFirstName"
                        placeholder=""
                        value={formik.values.genTestParticipantWitnessFirstName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.genTestParticipantWitnessFirstName &&
                          Boolean(
                            formik.errors.genTestParticipantWitnessFirstName
                          )
                        }
                        helperText={
                          formik.touched.genTestParticipantWitnessFirstName &&
                          formik.errors.genTestParticipantWitnessFirstName
                        }
                      />
                      {formik.errors.genTestParticipantWitnessFirstName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid xs={6}>
                    <FormControl sx={{ m: 2, width: "95%" }} variant="filled">
                      <label>
                        {t("label_impartial_witness_last_name")}{" "}
                        <span className="redStar">*</span>
                      </label>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="genTestParticipantWitnessLastName"
                        name="genTestParticipantWitnessLastName"
                        placeholder=""
                        value={formik.values.genTestParticipantWitnessLastName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.genTestParticipantWitnessLastName &&
                          Boolean(
                            formik.errors.genTestParticipantWitnessLastName
                          )
                        }
                        helperText={
                          formik.touched.genTestParticipantWitnessLastName &&
                          formik.errors.genTestParticipantWitnessLastName
                        }
                      />
                      {formik.errors.genTestParticipantWitnessLastName && (
                        <span className="inputError">Requird</span>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}

          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              onClick={handleClose}
              sx={{ minWidth: "100px" }}
            >
              Cancel
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="contained"
              sx={{ minWidth: "100px" }}
              data-testid="go_next"
              onClick={() => {
                if (JSON.stringify(formik.errors) === "{}") {
                  handleSave(formik.values);
                } else {
                  formik.submitForm();
                }
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

EditConsentNamesForm.propTypes = {
  ageCat: PropTypes.string,
};

export default EditConsentNamesForm;
