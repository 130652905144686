import React, { useState } from "react";
import styles from "./UserForm.module.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { Container } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const UserForm = ({
  handleFormSubmit,
  isEditMode = false,
  userObj = {},
  userGroupsList = [],
}) => {

  const validationSchema = yup.object({
    firstName: yup
      .string("Enter first name.")
      .required("First name is required."),
    lastName: yup.string("Enter last name.").required("Last name is required."),
    email: yup
      .string("Enter email address.")
      .email("Enter a valid email address.")
      .required("Email address is required."),
    mobileNo: yup
      .number("Enter mobile number.")
      .required("Mobile number is required"),

    siteId: yup.string("Enter site ID.").required("site ID is required."),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: userObj.first_name ? userObj.first_name : "",
      lastName: userObj.last_name ? userObj.last_name : "",
      email: userObj.email ? userObj.email : "",
      mobileNo: userObj.phone_number,
      siteId: userObj.site_id ? userObj.site_id : "",
      userGroups: userGroupsList,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("update user--", values);
      handleFormSubmit(values);
    },
  });

  const selectUserGroup = (value, index) => {
    let _userGroups = [...userGroupsList];
    _userGroups[index].isSelected = value;
    formik.setFieldValue("userGroups", [..._userGroups]);
  };

  return (
    <>
      <Container maxWidth="xlg">
        <div className={styles.loginBox}>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: "0px" }}>
            <Box sx={{ mt: 1 }}>
              <div className="form-field">
                <label>First Name</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  autoFocus
                  placeholder={"First Name"}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
              <div className="form-field">
                <label>Last Name</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  placeholder={"Last Name"}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
              <div className="form-field">
                <label>Email Address</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder={"Email Address"}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>

              <div className="form-field">
                <label>Mobile Number</label>
                <TextField
                  margin="normal"
                  fullWidth
                  id="mobileNo"
                  name="mobileNo"
                  placeholder={"Mobile Number"}
                  value={formik.values.mobileNo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.mobileNo && Boolean(formik.errors.mobileNo)
                  }
                  helperText={formik.touched.mobileNo && formik.errors.mobileNo}
                />
              </div>

              <div className="form-field">
                <label>Site ID</label>
                <TextField
                  margin="normal"
                  fullWidth
                  type="number"
                  id="siteId"
                  name="siteId"
                  placeholder={"Site ID"}
                  value={formik.values.siteId}
                  onChange={formik.handleChange}
                  error={formik.touched.siteId && Boolean(formik.errors.siteId)}
                  helperText={formik.touched.siteId && formik.errors.siteId}
                />
              </div>

              <h3>User Groups</h3>
              <FormGroup>
                {formik.values.userGroups.map((userGroup, i) => (
                  <FormControlLabel
                    key={`userGroup_${i}`}
                    control={<Checkbox checked={userGroup.isSelected} />}
                    label={userGroup.label}
                    onChange={(e) => {
                      selectUserGroup(e.target.checked, i);
                    }}
                  />
                ))}
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                fullWidth
              >
                {isEditMode ? "Update" : "Add"}
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </>
  );
};

export default UserForm;
