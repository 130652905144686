import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";
import PreLoader from "../../../components/common/PreLoader";
import AppFooter from "../../../components/AppFooter/AppFooter";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CounterBox from "../../../components/CounterBox";
import BarChart from "../../../components/BarChart";
import LineChart from "../../../components/LineChart";
import {
  apiGetPrimaryStudyDashboard,
  apiGetPrimaryStudyDashboardCountry,
} from "../../../services/api/dashboardApi.service";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const Dashboard = () => {
  const [countryCode] = useState(localStorage.getItem("i18nextLng"));

  const [Loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    guardian_aou_results: {
      illiterate_guardians_1st_attempt: 0,
      illiterate_guardians_2nd_attempt: 0,
      literate_guardians_1st_attempt: 0,
      literate_guardians_2nd_attempt: 0,
    },
    participant_aou_results: {
      illiterate_participants_1st_attempt: 0,
      illiterate_participants_2nd_attempt: 0,
      literate_participants_1st_attempt: 0,
      literate_participants_2nd_attempt: 0,
    },
    total_completed_consents: 0,
    total_completed_consents_adults: 0,
    total_completed_consents_emancipated_minor: 0,
    total_completed_consents_minor: 0,
    total_consents_adults: 0,
    total_consents_emancipated_minor: 0,
    total_consents_minor: 0,
    total_failed_consents: 0,
    total_failed_consents_adults: 0,
    total_failed_consents_emancipated_minor: 0,
    total_failed_consents_minor: 0,
    total_illiterate_guardians: 0,
    total_illiterate_participants: 0,
    total_literate_guardians: 0,
    total_literate_participants: 0,
    total_screened: 0,
    week_data: [],
  });

  const [saTotalParticipants, setSaTotalParticipants] = useState(0);
  const [ugTotalParticipants, setUgTotalParticipants] = useState(0);
  const [zmTotalParticipants, setZmTotalParticipants] = useState(0);
  const [weekDataCats, setWeekDataCats] = useState([]);
  const [weekDataDate, setWeekDataDate] = useState(new Date());
  const [xaxisTotalScreened, setXaxisTotalScreened] = useState([]);
  const [totalScreenedData, setTotalScreenedData] = useState([]);
  const [xaxisTotalConsents, setXaxisTotalConsents] = useState([]);
  const [totalConsentsData, setTotalConsentsData] = useState([]);

  const [xaxisLngStorConsents, setXaxisLngStorConsents] = useState([]);
  const [xaxisGenTestConsents, setXaxisGenTestConsents] = useState([]);
  const [lngStorConsentsData, setLngStorConsentsData] = useState([]);
  const [genTestConsentsData, setGenTestConsentsData] = useState([]);

  const getPrimaryStudyDashboardCountryData = async () => {
    setLoading(true);
    const results = await apiGetPrimaryStudyDashboardCountry();
    let data = results.body;
    if (results.success) {
      data.guardian_aou_results = JSON.parse(data.guardian_aou_results);
      data.participant_aou_results = JSON.parse(data.participant_aou_results);
      data.lng_stor_adult = JSON.parse(data.lng_stor_adult);
      data.lng_stor_emancipated_minor = JSON.parse(
        data.lng_stor_emancipated_minor
      );
      data.lng_stor_minor_guardian = JSON.parse(data.lng_stor_minor_guardian);

      data.gen_test_adult = JSON.parse(data.gen_test_adult);
      data.gen_test_emancipated_minor = JSON.parse(
        data.gen_test_emancipated_minor
      );
      data.gen_test_minor_guardian = JSON.parse(data.gen_test_minor_guardian);

      console.log(data);
      setDashboardData(data);
      setLoading(false);
    } else {
      setLoading(false);
    }

    return data;
  };

  const getDashboardDataCountrySpecific = async (date) => {
    const _date = new Date(date);
    const _month = _date.getMonth() + 1;
    const _year = _date.getFullYear();

    setLoading(true);
    const _dashboardData = await getPrimaryStudyDashboardCountryData();

    const totalMinorGuardianYes =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q1.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q2.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q3.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q1.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q2.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q3.yes);

    const totalMinorGuardianNo =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q1.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q2.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q3.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q1.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q2.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q3.no);

    const totalEmancipatedMinorYes =
      parseInt(_dashboardData.lng_stor_emancipated_minor.q1.yes) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q2.yes) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q3.yes);

    const totalEmancipatedMinorNo =
      parseInt(_dashboardData.lng_stor_emancipated_minor.q1.no) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q2.no) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q3.no);

    const totalAdultYes =
      parseInt(_dashboardData.lng_stor_adult.q1.yes) +
      parseInt(_dashboardData.lng_stor_adult.q2.yes) +
      parseInt(_dashboardData.lng_stor_adult.q3.yes);

    const totalAdultNo =
      parseInt(_dashboardData.lng_stor_adult.q1.no) +
      parseInt(_dashboardData.lng_stor_adult.q2.no) +
      parseInt(_dashboardData.lng_stor_adult.q3.no);

    const totalQ1Yes =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q1.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q1.yes) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q1.yes) +
      parseInt(_dashboardData.lng_stor_adult.q1.yes);

    const totalQ1No =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q1.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q1.no) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q1.no) +
      parseInt(_dashboardData.lng_stor_adult.q1.no);

    const totalQ2Yes =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q2.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q2.yes) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q2.yes) +
      parseInt(_dashboardData.lng_stor_adult.q2.yes);

    const totalQ2No =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q2.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q2.no) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q2.no) +
      parseInt(_dashboardData.lng_stor_adult.q2.no);

    const totalQ3Yes =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q3.yes) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q3.yes) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q3.yes) +
      parseInt(_dashboardData.lng_stor_adult.q3.yes);

    const totalQ3No =
      parseInt(_dashboardData.lng_stor_minor_guardian.guardians.q3.no) +
      parseInt(_dashboardData.lng_stor_minor_guardian.minor.q3.no) +
      parseInt(_dashboardData.lng_stor_emancipated_minor.q3.no) +
      parseInt(_dashboardData.lng_stor_adult.q3.no);

    //Gen Test
    const totalGenTestMinorGuardianYes =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q1.yes) +
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q2.yes) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q1.yes) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q2.yes);

    const totalGenTestMinorGuardianNo =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q1.no) +
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q2.no) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q1.no) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q2.no);

    const totalGenTestEmancipatedMinorYes =
      parseInt(_dashboardData.gen_test_emancipated_minor.q1.yes) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q2.yes);

    const totalGenTestEmancipatedMinorNo =
      parseInt(_dashboardData.gen_test_emancipated_minor.q1.no) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q2.no);

    const totalGenTestAdultYes =
      parseInt(_dashboardData.gen_test_adult.q1.yes) +
      parseInt(_dashboardData.gen_test_adult.q2.yes);

    const totalGenTestAdultNo =
      parseInt(_dashboardData.gen_test_adult.q1.no) +
      parseInt(_dashboardData.gen_test_adult.q2.no);

    const totalGenTestQ1Yes =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q1.yes) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q1.yes) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q1.yes) +
      parseInt(_dashboardData.gen_test_adult.q1.yes);

    const totalGenTestQ1No =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q1.no) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q1.no) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q1.no) +
      parseInt(_dashboardData.gen_test_adult.q1.no);

    const totalGenTestQ2Yes =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q2.yes) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q2.yes) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q2.yes) +
      parseInt(_dashboardData.gen_test_adult.q2.yes);

    const totalGenTestQ2No =
      parseInt(_dashboardData.gen_test_minor_guardian.guardians.q2.no) +
      parseInt(_dashboardData.gen_test_minor_guardian.minor.q2.no) +
      parseInt(_dashboardData.gen_test_emancipated_minor.q2.no) +
      parseInt(_dashboardData.gen_test_adult.q2.no);

    if (countryCode.indexOf("ZM") > -1) {
      setTimeout(() => {
        setXaxisTotalScreened([
          "Total Screened",
          "15 - 17 Minors",
          "Adult 18+",
        ]);

        setTotalScreenedData([
          parseInt(_dashboardData.total_screened),
          parseInt(_dashboardData.total_consents_minor),
          parseInt(_dashboardData.total_consents_adults),
        ]);

        setXaxisTotalConsents([
          "Total Consents",
          "15 - 17 Minors",
          "Adult 18+",
        ]);

        setTotalConsentsData([
          {
            name: "Total Consents",
            data: [
              parseInt(_dashboardData.total_completed_consents),
              parseInt(_dashboardData.total_completed_consents_minor),

              parseInt(_dashboardData.total_completed_consents_adults),
            ],
          },
          {
            name: "Total Screen outs",
            data: [
              parseInt(_dashboardData.total_failed_consents),
              parseInt(_dashboardData.total_failed_consents_minor),
              parseInt(_dashboardData.total_failed_consents_adults),
            ],
          },
        ]);

        setXaxisLngStorConsents([
          "Total Consents",
          "15 - 17 Minors",
          "Adult 18+",
          "Question 1",
          "Question 2",
          "Question 3",
        ]);

        setXaxisGenTestConsents([
          "Total Consents",
          "15 - 17 Minors",
          "Adult 18+",
          "Question 1",
          "Question 2",
        ]);

        setLngStorConsentsData([
          {
            name: "Yes",
            data: [
              parseInt(_dashboardData.lng_stor_total_yes),
              totalMinorGuardianYes,
              totalAdultYes,
              totalQ1Yes,
              totalQ2Yes,
              totalQ3Yes,
            ],
          },
          {
            name: "No",
            data: [
              parseInt(_dashboardData.lng_stor_total_no),
              totalMinorGuardianNo,
              totalAdultNo,
              totalQ1No,
              totalQ2No,
              totalQ3No,
            ],
          },
        ]);

        setGenTestConsentsData([
          {
            name: "Yes",
            data: [
              parseInt(_dashboardData.lng_stor_total_yes),
              totalGenTestMinorGuardianYes,
              totalAdultYes,
              totalQ1Yes,
              totalQ2Yes,
            ],
          },
          {
            name: "No",
            data: [
              parseInt(_dashboardData.lng_stor_total_no),
              totalMinorGuardianNo,
              totalAdultNo,
              totalQ1No,
              totalQ2No,
            ],
          },
        ]);
        

      }, 500);
    } else {
      setTimeout(() => {
        setXaxisTotalScreened([
          "Total Screened",
          "15 - 17 Minors",
          "Emancipated Minors",
          "Adult 18+",
        ]);

        setTotalScreenedData([
          parseInt(_dashboardData.total_screened),
          parseInt(_dashboardData.total_consents_minor),
          parseInt(_dashboardData.total_consents_emancipated_minor),
          parseInt(_dashboardData.total_consents_adults),
        ]);

        setXaxisTotalConsents([
          "Total Consents",
          "15 - 17 Minors",
          "Emancipated Minors",
          "Adult 18+",
        ]);

        setTotalConsentsData([
          {
            name: "Total Consents",
            data: [
              parseInt(_dashboardData.total_completed_consents),
              parseInt(_dashboardData.total_completed_consents_minor),
              parseInt(
                _dashboardData.total_completed_consents_emancipated_minor
              ),
              parseInt(_dashboardData.total_completed_consents_adults),
            ],
          },
          {
            name: "Total Screen outs",
            data: [
              parseInt(_dashboardData.total_failed_consents),
              parseInt(_dashboardData.total_failed_consents_minor),
              parseInt(_dashboardData.total_failed_consents_emancipated_minor),
              parseInt(_dashboardData.total_failed_consents_adults),
            ],
          },
        ]);

        setXaxisLngStorConsents([
          "Total Consents",
          "15 - 17 Minors",
          "Emancipated Minors",
          "Adult 18+",
          "Q1",
          "Q2",
          "Q3",
        ]);

        setLngStorConsentsData([
          {
            name: "Yes",
            data: [
              parseInt(_dashboardData.lng_stor_total_yes),
              totalMinorGuardianYes,
              totalEmancipatedMinorYes,
              totalAdultYes,
              totalQ1Yes,
              totalQ2Yes,
              totalQ3Yes,
            ],
          },
          {
            name: "No",
            data: [
              parseInt(_dashboardData.lng_stor_total_no),
              totalMinorGuardianNo,
              totalEmancipatedMinorNo,
              totalAdultNo,
              totalQ1No,
              totalQ2No,
              totalQ3No,
            ],
          },
        ]);

        setGenTestConsentsData([
          {
            name: "Yes",
            data: [
              parseInt(_dashboardData.gen_test_total_yes),
              totalGenTestMinorGuardianYes,
              totalGenTestEmancipatedMinorYes,
              totalGenTestAdultYes,
              totalGenTestQ1Yes,
              totalGenTestQ2Yes,
            ],
          },
          {
            name: "No",
            data: [
              parseInt(_dashboardData.gen_test_total_no),
              totalGenTestMinorGuardianNo,
              totalGenTestEmancipatedMinorNo,
              totalGenTestAdultNo,
              totalGenTestQ1No,
              totalGenTestQ2No,
            ],
          },
        ]);

      }, 500);
    }
  };

  const getDashboardWeekData = async (date) => {
    const _date = new Date(date);
    const _month = _date.getMonth() + 1;
    const _year = _date.getFullYear();
    setLoading(true);
    const _dashboardWeekData = await apiGetPrimaryStudyDashboard(_year, _month);
    setLoading(false);
    if (_dashboardWeekData.body.week_data.length > 0) {
      let _saCounts = [];
      let _ugCounts = [];
      let _zmCounts = [];
      let _weekDataCats = [];

      for (let i = 0; i < _dashboardWeekData.body.week_data.length; i++) {
        _saCounts.push(_dashboardWeekData.body.week_data[i].country_counts.ZA);
        _ugCounts.push(_dashboardWeekData.body.week_data[i].country_counts.UG);
        _zmCounts.push(_dashboardWeekData.body.week_data[i].country_counts.ZM);

        let weekDate = _dashboardWeekData.body.week_data[i].week_range
          .substring(
            _dashboardWeekData.body.week_data[i].week_range.length - 10
          )
          .slice(5, 10)
          .split("-");
        _weekDataCats.push(`Week ${weekDate[1]}/${weekDate[0]}`);
      }

      setSaTotalParticipants(_saCounts);
      setUgTotalParticipants(_ugCounts);
      setZmTotalParticipants(_zmCounts);
      setWeekDataCats(_weekDataCats);
    }
  };

  useEffect(() => {
    getDashboardDataCountrySpecific(new Date(weekDataDate));
  }, []);

  useEffect(() => {
    getDashboardWeekData(new Date(weekDataDate));
  }, [weekDataDate]);

  return (
    <div className={styles.dashboard}>
      {parseInt(dashboardData.total_screened) > 0 && (
        <Container maxWidth="md">
          <div className={styles.mainMenu}>
            <div className={styles.pageTitleBox}>
              {/* <h1>Primary Study</h1> */}
            </div>
            <Grid container spacing={3} mb={2}>
              <Grid item md={3} xs={3}>
                <CounterBox
                  title={"Total Screened"}
                  value={dashboardData.total_screened}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Total Consents"}
                  value={dashboardData.total_completed_consents}
                  valueColor={"#097909"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Total Screen outs"}
                  value={dashboardData.total_failed_consents}
                  valueColor={"#BC1212"}
                  mb={"20"}
                />
              </Grid>
              <Grid item md={9} xs={9}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Total Screened"}
                    chartHeight={"285"}
                    series={[
                      {
                        name: "Total Screened",
                        data: totalScreenedData,
                      },
                    ]}
                    columnWidth={"40%"}
                    colors={["#205588"]}
                    xaxisCategories={xaxisTotalScreened}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Total Consents and Total Screen outs"}
                    chartHeight={"350"}
                    series={totalConsentsData}
                    columnWidth={"45%"}
                    colors={["#097909", "#BC1212"]}
                    xaxisCategories={xaxisTotalConsents}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Long Storage Consents"}
                    chartHeight={"400"}
                    series={lngStorConsentsData}
                    columnWidth={"45%"}
                    colors={["#097909", "#BC1212"]}
                    xaxisCategories={xaxisLngStorConsents}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Genetic Testing Consents"}
                    chartHeight={"400"}
                    series={genTestConsentsData}
                    columnWidth={"45%"}
                    colors={["#097909", "#BC1212"]}
                    xaxisCategories={xaxisGenTestConsents}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
              <Grid item md={3} xs={3}>
                <CounterBox
                  title={"Literate Participants"}
                  value={dashboardData.total_literate_participants}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Illiterate Participants"}
                  value={dashboardData.total_illiterate_participants}
                  valueColor={"#4A9CEB"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Literate Parents"}
                  value={dashboardData.total_literate_guardians}
                  valueColor={"#205588"}
                  mb={"20"}
                />
                <CounterBox
                  title={"Illiterate Parents"}
                  value={dashboardData.total_illiterate_guardians}
                  valueColor={"#4A9CEB"}
                  mb={"20"}
                />
              </Grid>
              <Grid item md={9} xs={9}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={
                      "Literate and Illiterate Participants / Parents"
                    }
                    chartHeight={"412"}
                    series={[
                      {
                        name: "Literate",
                        data: [
                          parseInt(dashboardData.total_literate_participants),
                          parseInt(dashboardData.total_literate_guardians),
                        ],
                      },
                      {
                        name: "Illiterate",
                        data: [
                          parseInt(dashboardData.total_illiterate_participants),
                          parseInt(dashboardData.total_illiterate_guardians),
                        ],
                      },
                    ]}
                    columnWidth={"40%"}
                    colors={["#205588", "#4A9CEB"]}
                    xaxisCategories={["Participants", "Parents"]}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <BarChart
                    chartTitle={"Assessment of understanding results"}
                    chartHeight={"400"}
                    series={[
                      {
                        name: "Passed 1st Attempt",
                        data: [
                          dashboardData.participant_aou_results
                            .literate_participants_1st_attempt,
                          dashboardData.participant_aou_results
                            .illiterate_participants_1st_attempt,
                          dashboardData.guardian_aou_results
                            .literate_guardians_1st_attempt,
                          dashboardData.guardian_aou_results
                            .illiterate_guardians_1st_attempt,
                        ],
                      },
                      {
                        name: "Passed 2st Attempt",
                        data: [
                          dashboardData.participant_aou_results
                            .literate_participants_2nd_attempt,
                          dashboardData.participant_aou_results
                            .illiterate_participants_2nd_attempt,
                          dashboardData.guardian_aou_results
                            .literate_guardians_2nd_attempt,
                          dashboardData.guardian_aou_results
                            .illiterate_guardians_2nd_attempt,
                        ],
                      },
                    ]}
                    columnWidth={"45%"}
                    colors={["#205588", "#4A9CEB"]}
                    xaxisCategories={[
                      "Literate Participants",
                      "Illiterate Participants",
                      "Literate Parents",
                      "Illiterate Parents",
                    ]}
                    yaxisText={""}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container mb={4}>
              <Grid item md={12} xs={12}>
                <div className={styles.chartBox}>
                  <h3>Successful enrolments over time</h3>
                  <div className={styles.datePicker}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={["month", "year"]}
                        defaultValue={new Date()}
                        onChange={(newValue) => setWeekDataDate(newValue)}
                      />
                    </LocalizationProvider>
                  </div>
                  <LineChart
                    chartTitle={"Successful enrolments over time"}
                    chartHeight={"400"}
                    series={[
                      {
                        name: "South Africa",
                        data: saTotalParticipants,
                      },
                      {
                        name: "Uganda",
                        data: ugTotalParticipants,
                      },
                      {
                        name: "Zambia",
                        data: zmTotalParticipants,
                      },
                    ]}
                    colors={["#205588", "#4A9CEB", "#097909"]}
                    xaxisCategories={weekDataCats}
                  />
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
            <br />
          </div>
        </Container>
      )}
      <AppFooter />
      <PreLoader show={Loading} />
    </div>
  );
};

export default Dashboard;
