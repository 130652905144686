import React from "react";
import styles from "./ConfirmLoginMFAOtp.module.css";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import { apiVerifyOTP } from "../../services/api/userApi.service";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";

const ConfirmLoginMFAOtp = ({
  completedMFASetup,
  cognitoUser,
  mfaType,
  SMSVerifyToken,
  setLoading,
}) => {
  const [t] = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = yup.object({
    otp: yup.string(t("label_enter_OTP")).required(t("label_OTP_required")),
  });

  const handleOTPForm = async (values) => {
    if (process.env.REACT_APP_OWN_SMS_MFA == "true") {
      setLoading(true);
      const results = await apiVerifyOTP({
        uuid_token: SMSVerifyToken,
        otp_code: values.otp.toUpperCase(),
      });

      if (results.success) {
        const token = CryptoJS.AES.encrypt(
          JSON.stringify("MFA_SUCCESS"),
          process.env.REACT_APP_CLIENT_KEY
        ).toString();
        localStorage.setItem("MAGY.LOCAL", token);
        completedMFASetup(cognitoUser);
        setLoading(false);
      } else {
        enqueueSnackbar(t("label_invalid_OTP"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        setLoading(false);
      }
    } else {
      Auth.confirmSignIn(cognitoUser, values.otp.toString(), mfaType)
        .then(() => {
          completedMFASetup();
        })
        .catch((e) => {
          console.log("ee-", e);
          enqueueSnackbar(t("label_invalid_OTP"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleOTPForm(values);
    },
  });

  return (
    <>
      <div className={styles.registerBox}>
        <div className={styles.pageTitleBox}>
          <h1>{t("label_multi_factor_auth")}</h1>
          <p style={{ marginBottom: "0" }}>{t("label_OTP_sent_msg")}</p>
        </div>
        <div className={styles.registerBody}>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ mt: 4 }}>
              <div className="form-field">
                {mfaType === "SMS_MFA" && (
                  <>
                    <label>{t("label_enter_OTP_code")}</label>
                  </>
                )}
                {mfaType === "SOFTWARE_TOKEN_MFA" && (
                  <label>{t("label_enter_OTP_from_auth_app")}</label>
                )}
                <TextField
                  type="text"
                  margin="normal"
                  fullWidth
                  id="otp"
                  name="otp"
                  autoFocus
                  placeholder="OTP Code"
                  value={formik.values.otp}
                  onChange={formik.handleChange}
                  error={formik.touched.otp && Boolean(formik.errors.otp)}
                  helperText={formik.touched.otp && formik.errors.otp}
                />
              </div>

              <Button type="submit" variant="contained" fullWidth>
                {t("form.continue")}
              </Button>

              <div style={{ textAlign: "center", marginTop: "5px" }}>
                <Link href="/login" variant="body2">
                  {t("label_back_to_login")}
                </Link>
              </div>
            </Box>
          </form>
        </div>
      </div>
    </>
  );
};

ConfirmLoginMFAOtp.propTypes = {
  code: PropTypes.string,
  username: PropTypes.string,
  children: PropTypes.any,
  completedMFASetup: PropTypes.func,
  cognitoUser: PropTypes.any,
  SMSVerifyToken: PropTypes.string,
  setLoading: PropTypes.func,
};

export default ConfirmLoginMFAOtp;
