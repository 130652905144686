import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import PreLoader from "../../../../components/common/PreLoader";
import { useDispatch } from "react-redux";
import {
  setAgeCat,
  setParentIlliterate,
  setParticipantIlliterate,
} from "../../../../store/slices/settingsSlice";
import {
  setMinorParentConsent,
  setMinorParticipantConsent,
  setGuardianConsents,
  setParticipantConsents,
  setParticipantId,
  setEnrolmentId,
  setMinorParentWitness,
  setMinorParticipantWitness,
  setMinorParticipantPersonObtaining,
  setMinorParentPersonObtaining,
  setMinorParticipantGenTestPersonObtaining,
} from "../../../../store/slices/enrolmentSlice";
import { apiGetAllIDIA1 } from "../../../../services/api/subStudyIDIA1Api.service";
import { ageCats } from "../../../../data/age_categories_list";
import {
  guardianColumns,
  participantColumns,
  commonColumns,
} from "../../../../data/table_view_columns";

const TabIDIA1Consents = ({ ageCat = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [enrolments, setEnrolments] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);

  let columns = [...commonColumns];

  const getIDIA1Records = async () => {
    setLoading(true);

    const results = await apiGetAllIDIA1();

    if (results.success) {
      let data = results.body;

      if (ageCat !== "") {
        data = data.filter((item) => item.age_category === ageCat);
      }

      console.log(data);
      setEnrolments(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ageCat === "" || ageCat === "1") {
      setTableColumns([...columns, ...guardianColumns, ...participantColumns]);
    } else if (ageCat === "2" || ageCat === "3") {
      setTableColumns([...columns, ...participantColumns]);
    }
  }, [ageCat]);

  useEffect(() => {
    getIDIA1Records();
  }, []);

  useEffect(() => {
    if (enrolments.length > 0) {
      let _rows = [];

      enrolments.forEach((data, index) => {
        _rows.push({
          id: index + 1,
          PK: data.PK,
          SK: data.SK,
          age_cat: ageCats[data.age_category],
          full_participant_id: data.PK.replace("PARTICIPANT#", ""),
          full_enrolment_id: data.SK.replace("IDIA1#", ""),
          age_category: data.age_category,
          chosen_language: data.chosen_language,
          consent_guardian_child_first_name:
            data.consent_guardian_child_first_name,
          consent_guardian_child_last_name:
            data.consent_guardian_child_last_name,
          consent_guardian_date: data.consent_guardian_date,
          consent_guardian_first_name: data.consent_guardian_first_name,
          consent_guardian_last_name: data.consent_guardian_last_name,
          consent_guardian_signature: data.consent_guardian_signature,
          consent_guardian_staff_date: data.consent_guardian_staff_date,
          consent_guardian_staff_first_name:
            data.consent_guardian_staff_first_name,
          consent_guardian_staff_last_name:
            data.consent_guardian_staff_last_name,
          consent_guardian_staff_signature:
            data.consent_guardian_staff_signature,
          consent_guardian_staff_time: data.consent_guardian_staff_time,
          consent_guardian_time: data.consent_guardian_time,
          consent_guardian_witness_date: data.consent_guardian_witness_date,
          consent_guardian_witness_first_name:
            data.consent_guardian_witness_first_name,
          consent_guardian_witness_last_name:
            data.consent_guardian_witness_last_name,
          consent_guardian_witness_signature:
            data.consent_guardian_witness_signature,
          consent_guardian_witness_time: data.consent_guardian_witness_time,
          consent_participant_date: data.consent_participant_date,
          consent_participant_first_name: data.consent_participant_first_name,
          consent_participant_last_name: data.consent_participant_last_name,
          consent_participant_signature: data.consent_participant_signature,
          consent_participant_staff_date: data.consent_participant_staff_date,
          consent_participant_staff_first_name:
            data.consent_participant_staff_first_name,
          consent_participant_staff_last_name:
            data.consent_participant_staff_last_name,
          consent_participant_staff_signature:
            data.consent_participant_staff_signature,
          consent_participant_staff_time: data.consent_participant_staff_time,
          consent_participant_time: data.consent_participant_time,
          consent_participant_witness_date:
            data.consent_participant_witness_date,
          consent_participant_witness_first_name:
            data.consent_participant_witness_first_name,
          consent_participant_witness_last_name:
            data.consent_participant_witness_last_name,
          consent_participant_witness_signature:
            data.consent_participant_witness_signature,
          consent_participant_witness_time:
            data.consent_participant_witness_time,
          created_date: data.created_date,
          created_time: data.created_time,
          guardian_aou_results: data.guardian_aou_results,
          guardian_illiterate: data.guardian_illiterate ? "YES" : "NO",

          ///
          metadata: data.metadata,
          participant_id: data.participant_id,
          participant_illiterate: data.participant_illiterate ? "YES" : "NO",
          screen_times: data.screen_times,
          site_id: data.site_id,
          enrolment_date: data.consent_participant_date,
          record_status: data.record_status,
          consent_country: data.consent_country,
          guardian_consents:
            data.guardian_consents === null || data.guardian_consents === ""
              ? ""
              : JSON.parse(data.guardian_consents),
          participant_consents:
            data.participant_consents === null ||
            data.participant_consents === ""
              ? ""
              : JSON.parse(data.participant_consents),
          guardian_illiterate: data.guardian_illiterate,
          participant_illiterate: data.participant_illiterate,
        });
      });

      setRows(_rows);
    }
  }, [enrolments]);

  const handleRowSelection = (e) => {
    const _PK = e[0];
    const _currentRecord = rows.filter((item) => item.id === _PK)[0];
    console.log(_PK, _currentRecord);

    dispatch(setAgeCat(parseInt(_currentRecord.age_category)));
    dispatch(setParticipantId(_currentRecord.participant_id));
    dispatch(setEnrolmentId(parseInt(_currentRecord.full_enrolment_id)));

    dispatch(setParticipantConsents(_currentRecord.participant_consents));
    dispatch(setGuardianConsents(_currentRecord.guardian_consents));

    dispatch(
      setParentIlliterate(_currentRecord.guardian_illiterate === "true")
    );
    dispatch(
      setParticipantIlliterate(_currentRecord.participant_illiterate === "true")
    );

    dispatch(
      setMinorParentConsent({
        childFirstName: _currentRecord.consent_guardian_child_first_name,
        childLastName: _currentRecord.consent_guardian_child_last_name,
        parentFirstName: _currentRecord.consent_guardian_first_name,
        parentLastName: _currentRecord.consent_guardian_last_name,
        parentSignature: _currentRecord.consent_guardian_signature,
        isSigned: _currentRecord.guardian_illiterate === "NO",
        consentDate: _currentRecord.consent_guardian_date,
        consentTime: _currentRecord.consent_guardian_time,
      })
    );

    dispatch(
      setMinorParticipantConsent({
        participantFirstName: _currentRecord.consent_participant_first_name,
        participantLastName: _currentRecord.consent_participant_last_name,
        participantSignature: _currentRecord.consent_participant_signature,
        isSigned: _currentRecord.participant_illiterate === "NO",
        consentDate: _currentRecord.consent_participant_date,
        consentTime: _currentRecord.consent_participant_time,
        consentDate: _currentRecord.consent_participant_date,
      })
    );

    dispatch(
      setMinorParentWitness({
        witnessFirstName: _currentRecord.consent_guardian_witness_first_name,
        witnessLastName: _currentRecord.consent_guardian_witness_last_name,
        witnessSignature: _currentRecord.consent_guardian_witness_signature,
        consentDate: _currentRecord.consent_guardian_witness_date,
        consentTime: _currentRecord.consent_guardian_witness_time,
      })
    );

    dispatch(
      setMinorParticipantWitness({
        witnessFirstName: _currentRecord.consent_participant_witness_first_name,
        witnessLastName: _currentRecord.consent_participant_witness_last_name,
        witnessSignature: _currentRecord.consent_participant_witness_signature,
        consentDate: _currentRecord.consent_participant_witness_date,
        consentTime: _currentRecord.consent_participant_witness_time,
      })
    );

    dispatch(
      setMinorParticipantPersonObtaining({
        personObtainingFirstName:
          _currentRecord.consent_participant_staff_first_name,
        personObtainingLastName:
          _currentRecord.consent_participant_staff_last_name,
        personObtainingSignature:
          _currentRecord.consent_participant_staff_signature,
        consentDate: _currentRecord.consent_participant_staff_date,
        consentTime: _currentRecord.consent_participant_staff_time,
      })
    );

    dispatch(
      setMinorParentPersonObtaining({
        personObtainingFirstName:
          _currentRecord.consent_guardian_staff_first_name,
        personObtainingLastName:
          _currentRecord.consent_guardian_staff_last_name,
        personObtainingSignature:
          _currentRecord.consent_guardian_staff_signature,
        consentDate: _currentRecord.consent_guardian_staff_date,
        consentTime: _currentRecord.consent_guardian_staff_time,
      })
    );

    dispatch(
      setMinorParticipantGenTestPersonObtaining({
        personObtainingFirstName:
          _currentRecord.gen_test_participant_staff_first_name,
        personObtainingLastName:
          _currentRecord.gen_test_participant_staff_last_name,
        personObtainingSignature:
          _currentRecord.gen_test_participant_staff_signature,
        consentDate: _currentRecord.gen_test_participant_staff_date,
        consentTime: _currentRecord.gen_test_participant_staff_time,
      })
    );

    history.push("/idia1-print-preview");
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={tableColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        onSelectionModelChange={handleRowSelection}
      />
      <PreLoader show={Loading} />
    </Box>
  );
};

TabIDIA1Consents.propTypes = {
  ageCat: PropTypes.any,
};

export default TabIDIA1Consents;
