import React, { useEffect, useState } from "react";
import styles from "./SignatureImageView.module.css";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector } from "react-redux";
import { apiDownloadSignaturePresign } from "../../services/api/fileuploadApi.service";
import axios from "axios";
import Loader from "react-loader-spinner";

const SignatureImageView = ({ signature = "" }) => {
  const { participant_id } = useSelector((state) => state.enrolment);
  const [signatureData, setSignatureData] = useState("");

  const downloadSignatureFile = async () => {
    try {
      const presign = await apiDownloadSignaturePresign({
        input: {
          participant_id: `PARTICIPANT#${participant_id}`,
          file_name: signature,
        },
      });

      axios
        .get(presign.presign_url)
        .then(function (response) {
          console.log(response.data);

          setSignatureData(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    downloadSignatureFile();
  }, [signature]);

  return (
    <div>
      {signature === null && (
        <div className={styles.unableToSign}>
          <ClearIcon />
          <p>Unable to sign</p>
        </div>
      )}
      {signature !== null && (
        <div className={styles.signatureImageBox}>
          {signatureData === "" ? (
            <div className={styles.sigLoader}>
              <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
            </div>
          ) : (
            <img width="100%" src={signatureData} />
          )}
          {/* {signature}-{participant_id} */}
        </div>
      )}
    </div>
  );
};

SignatureImageView.propTypes = {
  signature: PropTypes.string,
};

export default SignatureImageView;
