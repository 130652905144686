import React from "react";
import styles from "./ConfirmDialog.module.css";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ConfirmDialog = ({
  title,
  message,
  show = false,
  onClose,
  confirmYes,
  confirmNo,
}) => {
  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmNo}>
            No
          </Button>
          <Button variant="contained" onClick={confirmYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  userEmail: PropTypes.string,
  show: PropTypes.bool,
  show: PropTypes.func,
  show: PropTypes.func,
  show: PropTypes.func,
};

export default ConfirmDialog;
