import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "aou",
  initialState: {
    questions: null,
    noOfAttempts: 0,
    assessmentDate: "",
    assessmentStartTime: "",
    assessmentEndTime: "",
    currentQuestionIndex: 0,
    assessmentCompleted: false,
    guardianResults: [],
    participantResults: [],
  },
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setNoOfAttempts: (state, action) => {
      state.noOfAttempts = action.payload;
    },
    setAssessmentDate: (state, action) => {
      state.assessmentDate = action.payload;
    },
    setAssessmentStartTime: (state, action) => {
      state.assessmentStartTime = action.payload;
    },
    setAssessmentEndTime: (state, action) => {
      state.assessmentEndTime = action.payload;
    },
    setCurrentQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    },
    setAssessmentCompleted: (state, action) => {
      state.assessmentCompleted = action.payload;
    },
    setGuardianResults: (state, action) => {
      state.guardianResults = action.payload;
    },
    setParticipantResults: (state, action) => {
      state.participantResults = action.payload;
    },
    resetAouSettings: (state) => {
      state.noOfAttempts = 0;
      state.assessmentDate = "";
      state.assessmentStartTime = "";
      state.assessmentEndTime = "";
      state.currentQuestionIndex = 0;
      state.assessmentCompleted = false;
    },
    resetAou: (state) => {
      state.questions = null;
      state.noOfAttempts = 0;
      state.assessmentDate = "";
      state.assessmentStartTime = "";
      state.assessmentEndTime = "";
      state.currentQuestionIndex = 0;
      state.assessmentCompleted = false;
      state.guardianResults = [];
      state.participantResults = [];
    },
  },
});

export const {
  setQuestions,
  setNoOfAttempts,
  setAssessmentDate,
  setAssessmentStartTime,
  setAssessmentEndTime,
  setCurrentQuestionIndex,
  setAssessmentCompleted,
  setGuardianResults,
  setParticipantResults,
  resetAou,
  resetAouSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
