import React from "react";
import styles from "./CounterBox.module.css";
import PropTypes from "prop-types";

const CounterBox = ({ title, value, valueColor, mb }) => {
  return (
    <div className={styles.countBox} style={{ marginBottom: `${mb}px` }}>
      <p>{title}</p>
      <h1 style={{ color: valueColor }}>{value}</h1>
    </div>
  );
};

CounterBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  valueColor: PropTypes.string,
  mb: PropTypes.string,
};

export default CounterBox;
