import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  apiGetQueries,
  apiUpdateQuery,
} from "../../services/api/subStudyIDIA1Api.service";
import PreLoader from "../common/PreLoader";
import styles from "./IDIA1Queries.module.css";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const IDIA1Queries = () => {
  const [t] = useTranslation("common");
  const { participant_id, enrolment_id } = useSelector(
    (state) => state.enrolment
  );
  const { loggedInUser } = useSelector((state) => state.settings);
  const [Loading, setLoading] = useState(false);
  const [queries, setQueries] = useState([]);
  const [queryInput, setQueryInput] = useState("");
  const consentCountry = localStorage.getItem("i18nextLng").split("_")[1];

  const submitQuery = async () => {
    setLoading(true);
    let _queries = [...queries];
    let group = [];

    group.push(loggedInUser.group.indexOf("SiteUsers") > -1 ? "Site User" : "");
    group.push(loggedInUser.group.indexOf("Monitor") > -1 ? "Monitor" : "");

    console.log(loggedInUser.group);

    _queries.push({
      user: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
      userGroup: group,
      dateTime: new Date().toISOString(),
      message: queryInput,
    });

    setQueryInput("");
    setQueries(_queries);

    console.log(_queries);

    const updateQueriesResults = await apiUpdateQuery({
      SK: `IDIA1#${enrolment_id}`,
      consent_country: consentCountry,
      participant_id: participant_id,
      query_messages: JSON.stringify(_queries),
    });

    console.log("updateQueriesResults--", updateQueriesResults);
    setLoading(false);
  };

  const getQueries = async () => {
    try {
      const queryResults = await apiGetQueries({
        SK: `IDIA1#${enrolment_id}`,
        consent_country: consentCountry,
        participant_id: participant_id,
      });

      if (queryResults.success) {
        let data = queryResults.body.query_messages;

        if (data !== null) {
          setQueries(JSON.parse(data));
          console.log(JSON.parse(data));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (participant_id !== null) {
      getQueries();
    }
  }, [participant_id]);

  return (
    <>
      <div>
        <>
          {queries.map((query) => (
            <div className={styles.pageBox}>
              <Box sx={{ pt: 1, pr: 1, pb: 1, pl: 4 }}>
                <p style={{ fontWeight: "bold" }}>
                  {dayjs(query.dateTime).format("DD/MM/YYYY - HH:mm")}
                  <br />
                  {query.user} ({query.userGroup})
                </p>
                <p>{query.message}</p>
              </Box>
            </div>
          ))}

          <div className={styles.pageBox}>
            <Box sx={{ pt: 1, pr: 2, pb: 1, pl: 2 }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Message"
                multiline
                maxRows={4}
                value={queryInput}
                fullWidth
                onChange={(e) => {
                  setQueryInput(e.target.value);
                }}
              />
              <div className={styles.reviewAndSubmitButton}>
                <Button
                  variant="contained"
                  sx={{ minWidth: "200px" }}
                  onClick={submitQuery}
                  disabled={queryInput === ""}
                >
                  Submit Query
                </Button>
              </div>
            </Box>
          </div>
        </>
      </div>
      <PreLoader show={Loading} />
    </>
  );
};

IDIA1Queries.propTypes = {
  signature: PropTypes.string,
};

export default IDIA1Queries;
