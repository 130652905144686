import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { apiGetEnrolments } from "../../../../services/api/enrolmentApi.service";
import PreLoader from "../../../../components/common/PreLoader";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { utils, writeFile } from "xlsx";
import dayjs from "dayjs";

const TabConsentedParticipants = ({ ageCat = "" }) => {
  const [rows, setRows] = useState([]);
  const [enrolments, setEnrolments] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const reportColumns = [
    {
      field: "full_participant_id",
      headerName: "Participant study ID",
      width: 185,
    },
    {
      field: "participant_icf_type_version_no",
      headerName: "ICF type and version no",
      width: 220,
    },
    {
      field: "participant_icf_version_date",
      headerName: "ICF version date",
      width: 185,
    },
    {
      field: "AOU_Passed",
      headerName: "AOU Passed",
      width: 155,
    },
    {
      field: "participant_icf_signed_date",
      headerName: "ICF Signed Date",
      width: 185,
    },
    {
      field: "Agrees_to_Long_Term_Storage",
      headerName: "Agrees to Long Term Storage",
      width: 245,
    },
    {
      field: "date_reviewed_monitor",
      headerName: "Date reviewed by monitor",
      width: 185,
    },
    {
      field: "query_status",
      headerName: "Queries Raised",
      width: 185,
    },
  ];

  const getEnrolments = async () => {
    setLoading(true);

    const results = await apiGetEnrolments();

    if (results.success) {
      let data = results.body;

      console.log(data);
      setEnrolments(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTableColumns([...reportColumns]);
  }, [ageCat]);

  useEffect(() => {
    getEnrolments();
  }, []);

  useEffect(() => {
    if (enrolments.length > 0) {
      let _rows = [];
      enrolments.forEach((data, index) => {
        if ("participant_aou_results" in data) {
          const aou_results = JSON.parse(data.participant_aou_results);
          if (Array.isArray(aou_results) && aou_results.length > 0) {
            const noOfCorrectAnswers = aou_results[0]?.noOfCorrectAnswers;
            data.AOU_Passed =
              noOfCorrectAnswers !== undefined && noOfCorrectAnswers >= 8
                ? "Yes"
                : "No";
          } else {
            data.AOU_Passed = "No";
          }
        } else {
          data.AOU_Passed = "No";
        }

        if ("participant_aou_results" in data) {
          const query_messages = JSON.parse(data.query_messages);
          if (Array.isArray(query_messages) && query_messages.length > 0) {
            data.query_status = "Yes";
          } else {
            data.query_status = "No";
          }
        } else {
          data.query_status = "No";
        }

        const agreesToLongTermStorage =
          data.lng_stor_participant_consent_1 === "Yes" &&
          data.lng_stor_participant_consent_2 === "Yes" &&
          data.lng_stor_participant_consent_3 === "Yes"
            ? "Yes"
            : "No";

        _rows.push({
          id: index + 1,
          PK: data.PK,
          SK: data.SK,
          full_participant_id: data.PK.replace("PARTICIPANT#", ""),
          consentId: data.SK.replace("ENROLMENT#", ""),
          date_reviewed_monitor:
            data.date_reviewed_monitor === null
              ? "-"
              : dayjs(data.date_reviewed_monitor).format("DD/MM/YYYY"),
          guardian_icf_signed_date:
            data.guardian_icf_signed_date === null
              ? "-"
              : data.guardian_icf_signed_date,
          guardian_icf_type_version_no:
            data.guardian_icf_type_version_no === null
              ? "-"
              : data.guardian_icf_type_version_no,
          guardian_icf_version_date:
            data.guardian_icf_version_date === null
              ? "-"
              : data.guardian_icf_version_date,
          participant_icf_signed_date:
            data.participant_icf_signed_date === null
              ? "-"
              : data.participant_icf_signed_date,
          participant_icf_type_version_no:
            data.participant_icf_type_version_no === null
              ? "-"
              : data.participant_icf_type_version_no,
          participant_icf_version_date:
            data.participant_icf_version_date === null
              ? "-"
              : data.participant_icf_version_date,
          query_status:
            data.messages === "" || data.query_status === null ? "No" : "Yes",
          Agrees_to_Long_Term_Storage: agreesToLongTermStorage,
          AOU_Passed: data.AOU_Passed,
        });
      });

      setRows(_rows);
    }
  }, [enrolments]);

  const downloadReport = async () => {
    let data = [];

    console.log(rows);

    rows.forEach((row) => {
      data.push([
        row.full_participant_id,
        row.participant_icf_type_version_no,
        row.participant_icf_version_date,
        row.AOU_Passed,
        row.participant_icf_signed_date,
        row.Agrees_to_Long_Term_Storage,
        row.date_reviewed_monitor,
        row.query_status,
      ]);
    });

    // Custom column titles
    const headers = [
      "PARTICIPANT STUDY ID",
      "ICF (INFORMED CONSENT FORM) TYPE AND VERSION NO",
      "ICF VERSION DATE",
      "AoU PASSED YES/NO",
      "ICF DATE SIGNED",
      "AGREES TO LONG TERM STORAGE YES/NO",
      "DATE REVIEWED BY MONITOR",
      "QUERIES RAISED",
    ];

    // Create a worksheet
    const ws = utils.aoa_to_sheet([headers, ...data]);

    // Create a workbook
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    writeFile(wb, "consented_participants_report.xlsx");
  };

  return (
    <>
      <div>
        <Button
          variant="contained"
          size="small"
          color="primary"
          sx={{ marginTop: "-5px !important" }}
          onClick={downloadReport}
        >
          <FileDownloadOutlinedIcon />
          <span style={{ marginLeft: "8px", marginRight: "8px" }}>
            Download Report
          </span>
        </Button>
      </div>
      <Box sx={{ height: "75vh", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={tableColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection={false}
        />
        <PreLoader show={Loading} />
      </Box>
    </>
  );
};

TabConsentedParticipants.propTypes = {
  ageCat: PropTypes.any,
};

export default TabConsentedParticipants;
