import { API, graphqlOperation } from "aws-amplify";
import {
  createEnrolment,
  updateEnrolmentParticipant,
  updateEnrolmentGuardian,
  updateEnrolmentStatus,
  updateGuardianConsentNames,
  updateParticipantConsentNames,
  updateQueryMessages,
  updateDateReviewedByMonitor,
} from "../../graphql/mutations";
import {
  getEnrolment,
  getEnrolments,
  getEnrolmentGuardianAOUResults,
  getEnrolmentParticipantAOUResults,
  getAuditInformation,
  getQueryMessages,
  getConsentedParticipantReport,
  generateParticipantsReport,
} from "../../graphql/queries";

export const apiCreateEnrolment = async (participantId) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(createEnrolment, {
        ConsentWithLongStorage: enrolmentData,
      })
    );

    results.body = response.data.createEnrolment;
    console.log("apiCreateEnrolment successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error creating new record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentStatus = async (
  participantId,
  enrolementId,
  recordStatus
) => {
  let results = { success: true, body: {}, message: "" };

  const enrolmentData = {
    PK: `PARTICIPANT#${participantId}`,
    SK: `ENROLMENT#${enrolementId}`,
    record_status: recordStatus,
  };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentStatus, {
        ConsentWithAudioTaping: enrolmentData,
      })
    );

    results.body = response.data.updateEnrolmentStatus;
    console.log("apiUpdateEnrolmentStatus successfull.");
  } catch (err) {
    results.success = false;
    results.message = "Error saving record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentParticipant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentParticipant, {
        ConsentWithLongStorage: participantData,
      })
    );

    results.body = response.data.updateEnrolmentParticipant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateEnrolmentGuardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateEnrolmentGuardian, {
        ConsentWithLongStorage: guardianData,
      })
    );

    results.body = response.data.updateEnrolmentGuardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetEnrolment = async (partcipantId, enrolmentId) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getEnrolment, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
      })
    );

    results.body = response.data.getEnrolment;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetEnrolments = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getEnrolments));

    results.body = response.data.getEnrolments;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetEnrolmentGuardianAOUResults = async (
  partcipantId,
  enrolmentId,
  consentCountry
) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getEnrolmentGuardianAOUResults, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
        consent_country: consentCountry,
      })
    );

    results.body = response.data.getEnrolmentGuardianAOUResults;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetEnrolmentParticipantAOUResults = async (
  partcipantId,
  enrolmentId,
  consentCountry
) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getEnrolmentParticipantAOUResults, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `ENROLMENT#${enrolmentId}`,
        consent_country: consentCountry,
      })
    );

    results.body = response.data.getEnrolmentParticipantAOUResults;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiUpdateGuardianConsentNames = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateGuardianConsentNames, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateGuardianConsentNames;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateParticipantConsentNames = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateParticipantConsentNames, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateParticipantConsentNames;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetAuditInformation = async (
  partcipantId,
  enrolmentId,
  consentCountry
) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getAuditInformation, {
        participant_id: `${partcipantId}`,
        SK: `${enrolmentId}`,
        consent_country: consentCountry,
      })
    );

    results.body = response.data.getAuditInformation;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiUpdateQuery = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateQueryMessages, {
        QueryMessages: data,
      })
    );

    results.body = response.data.updateQueryMessages;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetQueries = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getQueryMessages, data)
    );

    results.body = response.data.getQueryMessages;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetConsentedParticipantsReport = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getConsentedParticipantReport)
    );

    results.body = response.data.getConsentedParticipantReport;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGenerateConsentedParticipantsReport = async (
  consentCountry
) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(generateParticipantsReport, {
        consent_country: consentCountry,
      })
    );

    results.body = response.data.generateParticipantsReport;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiUpdateDateReviewedByMonitor = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateDateReviewedByMonitor, {
        ConsentedParticipantReport: participantData,
      })
    );

    results.body = response.data.updateDateReviewedByMonitor;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};
