import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { apiGetParticipants } from "../../../services/api/participantApi.service";
import PreLoader from "../../../components/common/PreLoader";
import { useSelector, useDispatch } from "react-redux";
import { setAgeCat } from "../../../store/slices/settingsSlice";
import {
  setMinorParentConsent,
  setMinorParentLongStorage,
  setMinorParticipantConsent,
  setMinorParticipantLongStorage,
} from "../../../store/slices/enrolmentSlice";

const TabAllParticipants = ({ ageCat = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [Loading, setLoading] = useState(false);
  const columns = [
    { field: "id", headerName: "ID", width: 50, hide: true },
    {
      field: "full_participant_id",
      headerName: "Participant ID",
      width: 130,
    },
    {
      field: "site_id",
      headerName: "Site ID",
      width: 130,
    },
    {
      field: "enrolment_date",
      headerName: "Enrolment Date",
      width: 130,
    },
    {
      field: "chosen_language",
      headerName: "Chosen Language",
      width: 185,
    },
    { field: "age_cat", headerName: "Age Cat", width: 250 },
    {
      field: "guardian_illiterate",
      headerName: "Parent Illiterate",
      width: 185,
    },
    {
      field: "participant_first_name",
      headerName: "Participant First Name",
      width: 160,
    },
    {
      field: "participant_last_name",
      headerName: "Participant Last Name",
      width: 160,
    },
    {
      field: "guardian_first_name",
      headerName: "Parent First Name",
      width: 160,
    },
    {
      field: "guardian_last_name",
      headerName: "Parent Last Name",
      width: 160,
    },
  ];

  const ageCats = [
    "",
    "Age 15 To 17",
    "Age 15 - 17 Emancipated Minor",
    "Age 18+",
  ];

  const getParticipants = async () => {
    setLoading(true);
    const results = await apiGetParticipants();

    if (results.success) {
      let data = results.body;

      if (ageCat !== "") {
        data = data.filter((item) => item.age_category === ageCat);
      }

      console.log(data);
      setParticipants(data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getParticipants();
  }, []);

  useEffect(() => {
    if (participants.length > 0) {
      let _rows = [];

      participants.forEach((data, index) => {
        _rows.push({
          id: index + 1,
          PK: data.PK,
          SK: data.SK,
          age_cat: ageCats[data.age_category],
          full_participant_id: data.PK.replace("PARTICIPANT#", ""),
          age_category: data.age_category,
          participant_first_name: data.participant_first_name,
          participant_last_name: data.participant_last_name,
          guardian_first_name: data.guardian_first_name,
          guardian_last_name: data.guardian_last_name,
          chosen_language: data.chosen_language,
          created_date: data.created_date,
          created_time: data.created_time,
          guardian_illiterate: data.guardian_illiterate ? "YES" : "NO",
          participant_illiterate: data.participant_illiterate ? "YES" : "NO",
          site_id: data.site_id,
          enrolment_date: data.created_date,
          participant_country: data.participant_country,
          participant_consent_list: data.participant_consent_list,
        });
      });
      setRows(_rows);
    }
  }, [participants]);

  const handleRowSelection = (e) => {
    const _PK = e[0];
    const _currentRecord = rows.filter((item) => item.id === _PK)[0];
    console.log(_PK, _currentRecord);

    // dispatch(setAgeCat(parseInt(_currentRecord.age_category)));

    // dispatch(
    //   setMinorParentConsent({
    //     childFirstName: _currentRecord.consent_guardian_child_first_name,
    //     childLastName: _currentRecord.consent_guardian_child_last_name,
    //     parentFirstName: _currentRecord.consent_guardian_first_name,
    //     parentLastName: _currentRecord.consent_guardian_last_name,
    //     parentSignature: _currentRecord.consent_guardian_signature,
    //     isSigned: _currentRecord.guardian_illiterate === "NO",
    //     consentDate: _currentRecord.consent_guardian_date,
    //     consentTime: _currentRecord.consent_guardian_time,
    //   })
    // );

    // dispatch(
    //   setMinorParentLongStorage({
    //     consent1: _currentRecord.lng_stor_guardian_consent_1,
    //     consent2: _currentRecord.lng_stor_guardian_consent_2,
    //     consent3: _currentRecord.lng_stor_guardian_consent_3,
    //     childFirstName: _currentRecord.lng_stor_guardian_child_first_name,
    //     childLastName: _currentRecord.lng_stor_guardian_child_last_name,
    //     parentFirstName: _currentRecord.lng_stor_guardian_first_name,
    //     parentLastName: _currentRecord.lng_stor_guardian_last_name,
    //     parentSignature: _currentRecord.lng_stor_guardian_signature,
    //     isSigned: _currentRecord.guardian_illiterate === "NO",
    //     consentDate: _currentRecord.lng_stor_guardian_date,
    //     consentTime: _currentRecord.lng_stor_guardian_time,
    //   })
    // );

    // dispatch(
    //   setMinorParticipantConsent({
    //     participantFirstName: _currentRecord.consent_participant_first_name,
    //     participantLastName: _currentRecord.consent_participant_last_name,
    //     participantSignature: _currentRecord.consent_participant_signature,
    //     isSigned: _currentRecord.participant_illiterate === "NO",
    //     consentDate: _currentRecord.consent_participant_date,
    //     consentTime: _currentRecord.consent_participant_time,
    //   })
    // );

    // dispatch(
    //   setMinorParticipantLongStorage({
    //     consent1: _currentRecord.lng_stor_participant_consent_1,
    //     consent2: _currentRecord.lng_stor_participant_consent_2,
    //     consent3: _currentRecord.lng_stor_participant_consent_3,
    //     participantFirstName: _currentRecord.lng_stor_participant_first_name,
    //     participantLastName: _currentRecord.lng_stor_participant_last_name,
    //     participantSignature: _currentRecord.lng_stor_participant_signature,
    //     isSigned: _currentRecord.participant_illiterate === "NO",
    //     consentDate: _currentRecord.lng_stor_participant_date,
    //     consentTime: _currentRecord.lng_stor_participant_time,
    //   })
    // );

    // dispatch(
    //   setPersonObtainingForm({
    //     personObtainingFirstName: values.personObtainingFirstName,
    //     personObtainingLastName: values.personObtainingLastName,
    //     personObtainingSignature: personObtainingSignature,
    //     isSigned: isSigned,
    //     consentDate: todayDate,
    //     consentTime: todayTime,
    //   })
    // );

    // history.push("/consent-print-preview");
  };

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection={false}
        onSelectionModelChange={handleRowSelection}
      />
      <PreLoader show={Loading} />
    </Box>
  );
};

TabAllParticipants.propTypes = {
  ageCat: PropTypes.any,
};

export default TabAllParticipants;
