import React from "react";
import PropTypes from "prop-types";

const RenderHtmlText = ({ htmlText = "" }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlText,
      }}
    />
  );
};

RenderHtmlText.propTypes = {
  htmlText: PropTypes.string,
};

export default RenderHtmlText;
