import React, { useEffect, useMemo } from "react";
import styles from "./KIIA1FinalReview.module.css";
import { useSelector, useDispatch } from "react-redux";
import AppHeader from "../../../components/AppHeader/AppHeader";
import ConsentAdultParticipantView from "../StepReview/ConsentAdultParticipantView";
import ConsentImpartialWitnessView from "../StepReview/ConsentImpartialWitnessView";
import ConsentPersonObtainingView from "../StepReview/ConsentPersonObtainingView";
import { Container } from "@mui/material";
import {
  setAppTitle,
  setRecordStatus,
} from "../../../store/slices/settingsSlice";
import { setTriggerEvent } from "../../../store/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ConsentKeyInformantIntro from "../StepIntroduction/ConsentKeyInformantIntro";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import ViewEnrolmentAOU from "../../../components/ViewEnrolmentAOU";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditConsentNames from "../../../components/EditConsentNames";
import ViewAuditTrail from "../../../components/ViewAuditTrail";
import KIIA1Queries from "../../../components/KIIA1Queries";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const KIIA1FinalReview = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const history = useHistory();
  const { ageCat, participantIlliterate, loggedInUser } = useSelector(
    (state) => state.settings
  );

  useMemo(() => {
    if (loggedInUser === null) {
      history.push({ pathname: "/login" });
    }
  }, []);

  const {
    consent_participant_witness_first_name,
    consent_participant_witness_last_name,
    consent_participant_witness_signature,
    consent_participant_witness_date,
    consent_participant_witness_time,
    consent_participant_staff_first_name,
    consent_participant_staff_last_name,
    consent_participant_staff_signature,
    consent_participant_staff_date,
    consent_participant_staff_time,
  } = useSelector((state) => state.enrolment);

  const participantConsentWitness = {
    witnessFirstName: consent_participant_witness_first_name,
    witnessLastName: consent_participant_witness_last_name,
    witnessSignature: consent_participant_witness_signature,
    consentDate: consent_participant_witness_date,
    consentTime: consent_participant_witness_time,
  };

  const participantConsentStaff = {
    personObtainingFirstName: consent_participant_staff_first_name,
    personObtainingLastName: consent_participant_staff_last_name,
    personObtainingSignature: consent_participant_staff_signature,
    consentDate: consent_participant_staff_date,
    consentTime: consent_participant_staff_time,
  };

  const handleSubmit = async () => {
    dispatch(setRecordStatus("PASSED"));
    dispatch(setTriggerEvent("SAVE_ENROLMENT_PASSED"));
  };

  useEffect(() => {
    dispatch(setAppTitle("KEY INFORMANT INTERVIEW - AIM 1"));
    window.scrollTo(0, 0);
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppHeader
        isGuestMode={false}
        isPrintPreviewMode={true}
        heading={"CONSENT REVIEW AND SUBMIT"}
      />
      <Container maxWidth="md">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
          >
            <Tab label="Consent forms" {...a11yProps(0)} />
            <Tab label="Audit Trail" {...a11yProps(1)} />
            <Tab label="Queries" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <EditConsentNames ageCat={ageCat} />
          <>
            <div className={styles.pageBox}>
              <div className="printOnly">
                <ConsentKeyInformantIntro printMode={true} />{" "}
                <div className="pagebreak"> </div>
              </div>
              <ConsentAdultParticipantView />
              {participantIlliterate && (
                <ConsentImpartialWitnessView
                  pageName={"KEY_INFORMANT_CONSENT"}
                  impartialWitnessObj={participantConsentWitness}
                />
              )}
              <ConsentPersonObtainingView
                personObtainingObj={participantConsentStaff}
              />
            </div>
          </>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className={styles.pageBox}>
            <ViewAuditTrail  SK="KIIA1" />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div>
            <KIIA1Queries />
          </div>
        </CustomTabPanel>
      </Container>
    </>
  );
};

export default KIIA1FinalReview;
