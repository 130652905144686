import { API, graphqlOperation } from "aws-amplify";
import {
  updateSubStudyKIIA3Participant,
  updateSubStudyKIIA3Guardian,
  updateQueryMessages,
} from "../../graphql/mutations";
import { getSubStudyKIIA3, getAllSubStudyKIIA3, getQueryMessages } from "../../graphql/queries";

export const apiUpdateSubStudyKIIA3Participant = async (participantData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyKIIA3Participant, {
        ConsentWithAudioTaping: participantData,
      })
    );

    results.body = response.data.updateSubStudyKIIA3Participant;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateSubStudyKIIA3Guardian = async (guardianData) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateSubStudyKIIA3Guardian, {
        ConsentWithAudioTaping: guardianData,
      })
    );

    results.body = response.data.updateSubStudyKIIA3Guardian;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiUpdateQuery = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(updateQueryMessages, {
        QueryMessages: data,
      })
    );

    results.body = response.data.updateQueryMessages;
    console.log("Request saved successfully.");
  } catch (err) {
    results.success = false;
    results.message = "Error updating record!";
    console.log(err);
    console.log("Error updating Request");
    console.log("Unable to update the request. ");
  }

  return results;
};

export const apiGetQueries = async (data) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getQueryMessages, data)
    );

    results.body = response.data.getQueryMessages;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetKIIA3 = async (partcipantId, kiia3Id) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyKIIA3, {
        PK: `PARTICIPANT#${partcipantId}`,
        SK: `KIIA3#${kiia3Id}`,
      })
    );

    results.body = response.data.getSubStudyKIIA3;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetAllKIIA3 = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(graphqlOperation(getAllSubStudyKIIA3));

    results.body = response.data.getAllSubStudyKIIA3;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};