import { API, graphqlOperation } from "aws-amplify";
import {
  getPrimaryStudyDashboard,
  getPrimaryStudyDashboardCountrySpecific,
  getSubStudyFGDA1Dashboard,
  getSubStudyFGDA1DashboardCountrySpecific,
  getSubStudyFGDA3Dashboard,
  getSubStudyFGDA3DashboardCountrySpecific,
  getSubStudyIDIA1Dashboard,
  getSubStudyIDIA1DashboardCountrySpecific,
  getSubStudyIDIA3Dashboard,
  getSubStudyIDIA3DashboardCountrySpecific,
  getSubStudyKIIA1Dashboard,
  getSubStudyKIIA1DashboardCountrySpecific,
  getSubStudyKIIA3Dashboard,
  getSubStudyKIIA3DashboardCountrySpecific,
  getSubStudyICDashboard,
  getSubStudyICDashboardCountrySpecific,
  getSubStudyILDashboard,
  getSubStudyILDashboardCountrySpecific,
} from "../../graphql/queries";

export const apiGetPrimaryStudyDashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getPrimaryStudyDashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getPrimaryStudyDashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetPrimaryStudyDashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getPrimaryStudyDashboardCountrySpecific)
    );

    results.body = response.data.getPrimaryStudyDashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetFGDA1Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA1Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyFGDA1Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetFGDA1DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA1DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyFGDA1DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetFGDA3Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA3Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyFGDA3Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetFGDA3DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyFGDA3DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyFGDA3DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIDIA1Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIDIA1Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyIDIA1Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIDIA1DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIDIA1DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyIDIA1DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIDIA3Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIDIA3Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyIDIA3Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetIDIA3DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyIDIA3DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyIDIA3DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetKIIA1Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyKIIA1Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyKIIA1Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetKIIA1DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyKIIA1DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyKIIA1DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetKIIA3Dashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyKIIA3Dashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyKIIA3Dashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetKIIA3DashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyKIIA3DashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyKIIA3DashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetICDashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyICDashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyICDashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetICDashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyICDashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyICDashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetILDashboard = async (year, month) => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyILDashboard, {
        month: month,
        year: year,
      })
    );

    results.body = response.data.getSubStudyILDashboard;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};

export const apiGetILDashboardCountry = async () => {
  let results = { success: true, body: {}, message: "" };

  try {
    const response = await API.graphql(
      graphqlOperation(getSubStudyILDashboardCountrySpecific)
    );

    results.body = response.data.getSubStudyILDashboardCountrySpecific;
  } catch (err) {
    results.success = false;
    results.message = "Error getting record!";
    console.log(err);
    console.log("Error getting Request");
  }

  return results;
};
